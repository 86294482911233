import React from "react";
import { Outlet } from "react-router-dom";
import ModalCustom from "../components/ModalCustom";
import Navbar from "../components/Navbar";
import useAuth from "../hooks/useAuth";

const Layout = () => {

  const { auth } = useAuth();

  return (
    <>
      <main className="w-screen h-screen flex flex-col text-textColorPrimary shrink-0">
        <Navbar user={auth?.user} sticky={true}/>
        <Outlet />
      </main>
      <ModalCustom />
    </>
  );
};

export default Layout;