import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { RiArrowDropDownLine } from  "react-icons/ri";
import { useQuery } from "react-query";
import axios from "../../api/axiosAuth";
import { ThreeDots } from "react-loader-spinner";

const Filters = (props) => {

  const [isCategoryFilterOpen, setIsCategoryFilterOpen] = useState(true);
  const [isAuthorFilterOpen, setIsAuthorFilterOpen] = useState(true);
  const [isDocumentTypeFilterOpen, setIsDocumentTypeFilterOpen] = useState(true);
  const [isFilleTypeFilterOpen, setIsFileTypeFilterOpen] = useState(true);
  const [isYearFilterOpen, setIsYearFilterOpen] = useState(true);

  const fetchFilterData = () => {
    return axios.get("repository/filters");
  };

  const { isLoading: isFilterLoading, isFetching: isFilterFetching, data: filterData } = useQuery(
    "repository-filter-data", 
    () => fetchFilterData(),
    {
      keepPreviousData: true,
      select: (data) => {
        return data?.data?.data;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 900000,
      staleTime: 900000,
    }
  );
  

  return (
    <>
      {
        (isFilterLoading || isFilterFetching ) ? 
          <ThreeDots 
            height="60" 
            width="60" 
            radius="3"
            color="#c0c0c0" 
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
          :
          <>
            <button 
              onClick={()=>{setIsCategoryFilterOpen(!isCategoryFilterOpen);}}
              className={"flex w-full flex-row gap-1 p-1 md:p-2 rounded-xl hover:bg-primary hover:bg-opacity-5 mb-2"}>
              <span className="md:block cursor-pointer flex-1 text-start font-semibold text-tiny">Categoría temática</span>
              <RiArrowDropDownLine size={22} className={"ml-2 transition-transform"+( isCategoryFilterOpen ? " rotate-180" : "" )}/>
            </button>
            <Collapse isOpened={isCategoryFilterOpen}>
              <div className="flex gap-2 flex-col">
                {
                  filterData?.categories?.map((item)=>{
                    return (
                      <div key={item._id} className="px-2 flex gap-2 items-start">
                        <input id={"filter_checkbox_"+item._id} type="checkbox" className="h-fit mt-1" value={item._id} onChange={(e)=>{if(props.onFilterChanged) props.onFilterChanged("category",e.target.value,e.target.checked);}}/>
                        <label htmlFor={"filter_checkbox_"+item._id} className="flex-1 text-start text-tiny">{item.name}</label>
                        <p className="text-start text-xs px-2 py-1 bg-gray rounded">{item.count}</p>
                      </div>
                    );
                  })
                }
              </div>
            </Collapse>
            <button 
              onClick={()=>{setIsAuthorFilterOpen(!isAuthorFilterOpen);}}
              className={"flex w-full flex-row gap-1 p-1 md:p-2 rounded-xl hover:bg-primary hover:bg-opacity-5 mt-2 mb-2"}>
              <span className="md:block cursor-pointer flex-1 text-start font-semibold text-tiny">Autor</span>
              <RiArrowDropDownLine size={22} className={"ml-2 transition-transform"+( isAuthorFilterOpen ? " rotate-180" : "" )}/>
            </button>
            <Collapse isOpened={isAuthorFilterOpen}>
              <div className="flex gap-2 flex-col">
                {
                  filterData?.authors?.map((item)=>{
                    return (
                      <div key={item._id} className="px-2 flex gap-2 items-start">
                        <input id={"filter_checkbox_"+item._id} type="checkbox" className="h-fit mt-1" value={item._id} onChange={(e)=>{if(props.onFilterChanged) props.onFilterChanged("author",e.target.value,e.target.checked);}}/>
                        <label htmlFor={"filter_checkbox_"+item._id} className="flex-1 text-start text-tiny">{item.name}</label>
                        <p className="text-start text-xs px-2 py-1 bg-gray rounded">{item.count}</p>
                      </div>
                    );
                  })
                }
              </div>
            </Collapse>
            <button 
              onClick={()=>{setIsDocumentTypeFilterOpen(!isDocumentTypeFilterOpen);}}
              className={"flex w-full flex-row gap-1 p-1 md:p-2 rounded-xl hover:bg-primary hover:bg-opacity-5 mt-2 mb-2"}>
              <span className="md:block cursor-pointer flex-1 text-start font-semibold text-tiny">Tipo de documento</span>
              <RiArrowDropDownLine size={22} className={"ml-2 transition-transform"+( isDocumentTypeFilterOpen ? " rotate-180" : "" )}/>
            </button>
            <Collapse isOpened={isDocumentTypeFilterOpen}>
              <div className="flex gap-2 flex-col">
                {
                  filterData?.documentTypes?.map((item)=>{
                    return (
                      <div key={item._id} className="px-2 flex gap-2 items-start">
                        <input id={"filter_checkbox_"+item._id} type="checkbox" className="h-fit mt-1" value={item._id} onChange={(e)=>{if(props.onFilterChanged) props.onFilterChanged("documentType",e.target.value,e.target.checked);}}/>
                        <label htmlFor={"filter_checkbox_"+item._id} className="flex-1 text-start text-tiny">{item.name}</label>
                        <p className="text-start text-xs px-2 py-1 bg-gray rounded">{item.count}</p>
                      </div>
                    );
                  })
                }
              </div>
            </Collapse>
            <button 
              onClick={()=>{setIsFileTypeFilterOpen(!isFilleTypeFilterOpen);}}
              className={"flex w-full flex-row gap-1 p-1 md:p-2 rounded-xl hover:bg-primary hover:bg-opacity-5 mt-2 mb-2"}>
              <span className="md:block cursor-pointer flex-1 text-start font-semibold text-tiny">Tipo de archivo</span>
              <RiArrowDropDownLine size={22} className={"ml-2 transition-transform"+( isFilleTypeFilterOpen ? " rotate-180" : "" )}/>
            </button>
            <Collapse isOpened={isFilleTypeFilterOpen}>
              <div className="flex gap-2 flex-col">
                {
                  filterData?.fileTypes?.map((item,index)=>{
                    return (
                      <div key={"repository-filter-file-type-"+index} className="px-2 flex gap-2 items-start">
                        <input id={"repository-filter-file-type-"+index} type="checkbox" className="h-fit mt-1" value={item.name} onChange={(e)=>{if(props.onFilterChanged) props.onFilterChanged("fileType",e.target.value,e.target.checked);}}/>
                        <label htmlFor={"repository-filter-file-type-"+index} className="flex-1 text-start text-tiny">{item.name}</label>
                        <p className="text-start text-xs px-2 py-1 bg-gray rounded">{item.count}</p>
                      </div>
                    );
                  })
                }
              </div>
            </Collapse>
            <button 
              onClick={()=>{setIsYearFilterOpen(!isYearFilterOpen);}}
              className={"flex w-full flex-row gap-1 p-1 md:p-2 rounded-xl hover:bg-primary hover:bg-opacity-5 mt-2 mb-2"}>
              <span className="md:block cursor-pointer flex-1 text-start font-semibold text-tiny">Año de publicación</span>
              <RiArrowDropDownLine size={22} className={"ml-2 transition-transform"+( isYearFilterOpen ? " rotate-180" : "" )}/>
            </button>
            <Collapse isOpened={isYearFilterOpen}>
              <div className="flex gap-2 flex-col">
                {
                  filterData?.years?.map((item,index)=>{
                    return (
                      <div key={"repository-filter-year-"+index} className="px-2 flex gap-2 items-start">
                        <input id={"repository-filter-year-"+index} type="checkbox" className="h-fit mt-1" value={item.name} onChange={(e)=>{if(props.onFilterChanged) props.onFilterChanged("year",e.target.value,e.target.checked);}}/>
                        <label htmlFor={"repository-filter-year-"+index} className="flex-1 text-start text-tiny">{item.name}</label>
                        <p className="text-start text-xs px-2 py-1 bg-gray rounded">{item.count}</p>
                      </div>
                    );
                  })
                }
              </div>
            </Collapse>
          </>
      }
    </>
  );
};


export default Filters;