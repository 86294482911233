import React, { useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import axios from "../../api/axiosAuth";
import {  toast } from "react-toastify";

const CsvUploadModal = (props) => {
  
  const [loading,setLoading] = useState(false);
  const [fileError,setFileError] = useState();

  const fileRef = useRef();

  const handleSubmit = async () => {
    let isError = false;
    if(fileRef.current && fileRef.current.files.length == 0){
      setFileError("Please select file");
      isError = true;
    }
    if(isError){
      return;
    }
    const fileKey = props.graphName ?  props.graphName.value : props.subCategory.value;
    setLoading(true);
    try{
      const formData = new FormData();
      formData.append("file",fileRef.current.files[0]);
      const response = await axios.post("upload/"+fileKey,formData,{
        headers: {
          "content-type": "multipart/form-data"
        }
      });
      toast.success(response.data.message);
      props.modalClose && props.modalClose();
    }
    catch(err) {
      if(!err?.response){
        toast.error("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.message){
        toast.error(err.response.data.message);
      } if(err.response.data.errors && err.response.data.errors.length > 0) {
        if(err.response.data.errors.length === 1 && typeof(err.response.data.errors[0]) === "string"){
          toast.error(err.response.data.errors[0]);
        } else {
          toast.error( "Row " + (err.response.data.errors[0].rowIndex + 1 ) + ": " + err.response.data.errors[0].message);
        }
      } else {
        toast.error("CSV upload failed, something went wrong. Please try again later.");
      }
      setLoading(false);
    }
  };
 
  return(
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Upload CSV</h3>
      </div>
      <div className="w-full mt-3">
        <input value={props.category} className="border border-gray rounded w-full text-tiny p-2 bg-lightGray" readOnly/>
      </div>
      <div className="w-full mt-3">
        <input value={props.subCategory?.label} className="border border-gray rounded w-full text-tiny p-2 bg-lightGray" readOnly/>
      </div>
      {
        props.graphName? 
          <div className="w-full mt-3">
            <input value={props.graphName?.label} className="border border-gray rounded w-full text-tiny p-2 bg-lightGray" readOnly/>
          </div>
          :
          <></>
      }
      <div className="w-full mt-3">
        <input ref={fileRef} type="file" className="border border-gray rounded w-full text-tiny p-2 bg-lightGray"/>
        {
          fileError && fileError.trim().length > 0 ? 
            <p className="text-sm text-red mt-1 font-medium">{fileError}</p>
            :
            <></>
        }
      </div>
      <div className="border-gray pt-2 flex justify-end mt-5">
        {
          loading ? 
            <Oval
              height={35}
              width={35}
              color="#005AA9"
              wrapperClass="w-fit mr-6"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#B3B9D5"
              strokeWidth={4}
              strokeWidthSecondary={4} />
            :
            <div className="flex gap-3">
              <button className="text-tiny bg-gray px-3 py-1 rounded shadow" onClick={()=>{props.modalClose && props.modalClose();}}>Close</button>
              <button className="text-tiny bg-primary text-textColorSecondary px-3 py-1 rounded shadow" onClick={()=>{handleSubmit();}}>Upload</button>
            </div>
        }
      </div>
    </div>
  );
};

export default CsvUploadModal;