import axios from "axios";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export const BASE_URL = process.env.REACT_APP_API_URL;

const axiosClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
axiosClient.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      const { setAuth } = useAuth();
      setAuth();
      Navigate({ to: "auth", replace: true });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosClient;
