import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./screens/Layout";
import NotFound from "./screens/NotFound";
import Auth from "./screens/Auth";
import Login from "./screens/Login";
import Register from "./screens/Register";
import AppLayout from "./screens/AppLayout";
import RequireAuth from "./components/RequireAuth";
import RequirePremium from "./components/RequirePremium";

import PoblacionGeneroYEdad from "./screens/indicadores_sociales/poblacion_genero_y_edad";
import InversionPublicaPerCapita from "./screens/indicadores_sociales/inversion_publica_per_capita";
import Importaciones from "./screens/indicadores_sociales/nivel_de_ingreso_promedio";
import PobrezaPobrezaExtremayGni from "./screens/indicadores_sociales/pobreza_pobreza_extremay_gni";
import PobrezaMultidimensional from "./screens/indicadores_sociales/pobreza_multidimensional";
import EvolucionClasesSociales from "./screens/indicadores_sociales/evolucion_clases_sociales";

import PibYParticipacionPibNacional from "./screens/desempeno_economico/pib_y_participacion_pib_nacional";
import CrecimientoDelPb from "./screens/desempeno_economico/crecimiento_del_pb";
import PibDistribucionSectorial from "./screens/desempeno_economico/pib_distribucion_sectorial";
import PibPerCapita from "./screens/desempeno_economico/pib_per_capita";
import Ipc from "./screens/desempeno_economico/ipc";
import TasaGlobalDeParticipacion from "./screens/desempeno_economico/tasa_global_de_participacion";
import TasaDeDesocupados from "./screens/desempeno_economico/tasa_de_desocupados";
import TasaDeOcupados from "./screens/desempeno_economico/tasa_de_ocupados";

import EgresadosAnualesPorAreaAcademica from "./screens/talento_humano/egresados_anuales_por_area_academica";
import EgresadosAnualesPorNivelDeFormacion from "./screens/talento_humano/egresados_anuales_por_nivel_de_formacion";
import OfertaAcademica from "./screens/talento_humano/oferta_academica";
import InstitucionesYProgramasDeFormacionParaElTrabajo from "./screens/talento_humano/instituciones_y_programas_de_formacion_para_el_trabajo";
import MatriculadosYCertificadosEnFormacionParaElTrabajo from "./screens/talento_humano/matriculados_y_certificados_en_formacion_para_el_trabajo";
import InstitutoDeIdiomas from "./screens/talento_humano/instituto_de_idiomas";
import DesempenoBilinguismo from "./screens/talento_humano/desempeno_bilinguismo";

import ExportacionesEImportaciones from "./screens/conectividad_y_acceso_a_mercados/exportaciones_e_importaciones";
import AccesoAInternet from "./screens/conectividad_y_acceso_a_mercados/acceso_a_internet";
import MovilizacionDePasajeros from "./screens/conectividad_y_acceso_a_mercados/movilizacion_de_pasajeros";
import CargaAerea from "./screens/conectividad_y_acceso_a_mercados/carga_aerea";
import VisitantesInternacionales from "./screens/conectividad_y_acceso_a_mercados/visitantes_internacionales";
import TraficoPortuario from "./screens/conectividad_y_acceso_a_mercados/trafico_portuario";
import RutasYFrecuenciasAereas from "./screens/conectividad_y_acceso_a_mercados/rutas_y_frecuencias_aereas";

import ServiciosPublicos from "./screens/costos_de_instalacion_y_operacion/servicios_publicos";
import SalariosPorSectoresYCargos from "./screens/costos_de_instalacion_y_operacion/salarios_por_sectores_y_cargos";
import CostosDeArriendoYVentaDeInmuebles from "./screens/costos_de_instalacion_y_operacion/costos_de_arriendo_y_venta_de_inmuebles";

import Empresas from "./screens/tejido_empresarial_y_desempeno_sectorial/empresas";

import CompetitividadCiudades from "./screens/entorno_de_negocios_y_competitividad/competitividad_ciudades";
import CompetitividadDepartamentos from "./screens/entorno_de_negocios_y_competitividad/competitividad_departamentos";

import AtraccionYRetencionDeInversiones from "./screens/gestion_de_atraccion_y_retencion_de_inversiones/atraccion_y_retencion_de_inversiones";

import EmailVerify from "./screens/EmailVerify";

import AdminLayout from "./screens/AdminLayout";
import Users from "./screens/admin/Users";
import IndicadoresYcategorias from "./screens/admin/indicadoresYcategorias";
import Administrador from "./screens/admin/Administrador";
import AdminMaps from "./screens/admin/Maps";
import MappingRoutes from "./screens/admin/MappingRoutes.json";
import MapCategories from "./screens/admin/MapCategories.json";

import RepositoryCategories from "./screens/admin/repository/Categories";
import RepositoryAuthors from "./screens/admin/repository/Author";
import RepositoryDocumentTypes from "./screens/admin/repository/DocumentTypes";
import RepositoryDocuments from "./screens/admin/repository/Documents";

import Repository from "./screens/repository/Repository";
import Maps from "./screens/maps/Maps";

import Profile from "./screens/Profile";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import PanoramaPobrezaDesigualdad from "./screens/indicadores_sociales/panorama_pobreza_desigualdad";
import DesempenoCompetencias from "./screens/talento_humano/desempeno_competencias_profesionales";

function Router() {
  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route
          index
          element={<Navigate to="charts/desempeno_economico" replace />}
        />
        <Route
          path="charts"
          element={<Navigate to="desempeno_economico" replace />}
        />

        {/* Public routes */}
        <Route path="auth" element={<Auth />}>
          <Route index element={<Navigate to="login" replace />} />
          <Route path="login" index element={<Login />}></Route>
          <Route path="register" element={<Register />}></Route>
        </Route>
        <Route path="auth/email_verify" element={<EmailVerify />}></Route>
        <Route path="forgot_password" element={<ForgotPassword />}></Route>
        <Route path="reset_password/:id" element={<ResetPassword />}></Route>

        <Route element={<RequireAuth />}>
          <Route path="user/profile" element={<Profile />}></Route>
          <Route path="maps" element={<Maps />}></Route>
          <Route path="repository" element={<Repository />}></Route>
          <Route element={<RequirePremium />}>
            <Route
              path="charts/indicadores_sociales"
              element={<AppLayout category={"indicadores_sociales"} />}
            >
              <Route
                index
                element={<Navigate to="poblacion_genero_y_edad" replace />}
              />
              <Route
                path="poblacion_genero_y_edad"
                index
                element={<PoblacionGeneroYEdad />}
              ></Route>
              <Route
                path="inversion_publica_per_capita"
                element={<InversionPublicaPerCapita />}
              ></Route>
              <Route
                path="pobreza_pobreza_extremay_gni"
                element={<PobrezaPobrezaExtremayGni />}
              ></Route>
              <Route
                path="panorama_pobreza_desigualdad"
                element={<PanoramaPobrezaDesigualdad />}
              ></Route>
              <Route
                path="pobreza_multidimensional"
                element={<PobrezaMultidimensional />}
              ></Route>
            </Route>
            <Route
              path="charts/desempeno_economico"
              element={<AppLayout category={"desempeno_economico"} />}
            >
              <Route
                index
                element={
                  <Navigate to="pib_y_participacion_pib_pib_nacional" replace />
                }
              />
              <Route
                path="pib_y_participacion_pib_pib_nacional"
                index
                element={<PibYParticipacionPibNacional />}
              ></Route>
              <Route
                path="crecimiento_del_pib"
                element={<CrecimientoDelPb />}
              ></Route>
              <Route
                path="pib_distribucion_sectorial"
                element={<PibDistribucionSectorial />}
              ></Route>
              <Route path="pib_per_capita" element={<PibPerCapita />}></Route>
              <Route path="ipc" element={<Ipc />}></Route>
              <Route
                path="tasa_global_de_participacion"
                element={<TasaGlobalDeParticipacion />}
              ></Route>
              <Route
                path="tasa_de_ocupados"
                element={<TasaDeOcupados />}
              ></Route>
              <Route
                path="tasa_de_desocupados"
                element={<TasaDeDesocupados />}
              ></Route>
            </Route>
            <Route
              path="charts/talento_humano"
              element={<AppLayout category={"talento_humano"} />}
            >
              <Route
                index
                element={
                  <Navigate to="egresados_anuales_por_area_academica" replace />
                }
              />
              <Route
                path="egresados_anuales_por_area_academica"
                index
                element={<EgresadosAnualesPorAreaAcademica />}
              ></Route>
              <Route
                path="egresados_anuales_por_nivel_de_formacion"
                element={<EgresadosAnualesPorNivelDeFormacion />}
              ></Route>
              <Route
                path="oferta_academica"
                element={<OfertaAcademica />}
              ></Route>
              <Route
                path="instituciones_y_programas_de_formacion_para_el_trabajo"
                element={<InstitucionesYProgramasDeFormacionParaElTrabajo />}
              ></Route>
              <Route
                path="matriculados_y_certificados_en_formacion_para_el_trabajo"
                element={<MatriculadosYCertificadosEnFormacionParaElTrabajo />}
              ></Route>
              <Route
                path="instituto_de_idiomas"
                element={<InstitutoDeIdiomas />}
              ></Route>
              <Route
                path="desempeno_competencias_profesionales"
                element={<DesempenoCompetencias />}
              ></Route>
            </Route>
            <Route
              path="charts/costos_de_instalacion_y_operacion"
              element={
                <AppLayout category={"costos_de_instalacion_y_operacion"} />
              }
            >
              <Route
                index
                element={<Navigate to="servicios_publicos" replace />}
              />
              <Route
                path="servicios_publicos"
                index
                element={<ServiciosPublicos />}
              ></Route>
              <Route
                path="salarios_por_sectores_y_cargos"
                element={<SalariosPorSectoresYCargos />}
              ></Route>
              <Route
                path="costos_de_arriendo_y_venta_de_inmuebles"
                element={<CostosDeArriendoYVentaDeInmuebles />}
              ></Route>
            </Route>
            <Route
              path="charts/conectividad_y_acceso_a_mercados"
              element={
                <AppLayout category={"conectividad_y_acceso_a_mercados"} />
              }
            >
              <Route
                index
                element={
                  <Navigate to="exportaciones_e_importaciones" replace />
                }
              />
              <Route
                path="exportaciones_e_importaciones"
                index
                element={<ExportacionesEImportaciones />}
              ></Route>
              <Route
                path="importaciones"
                element={<Importaciones />}
              ></Route>
              <Route
                path="acceso_a_internet"
                element={<AccesoAInternet />}
              ></Route>
              <Route
                path="transporte_terrestre_interno"
                element={<></>}
              ></Route>
              <Route
                path="movilizacion_de_pasajeros"
                element={<MovilizacionDePasajeros />}
              ></Route>
              <Route path="carga_aerea" element={<CargaAerea />}></Route>
              <Route
                path="visitantes_internacionales"
                element={<VisitantesInternacionales />}
              ></Route>
              <Route
                path="trafico_portuario"
                element={<TraficoPortuario />}
              ></Route>
              <Route
                path="rutas_y_frecuencias_aereas"
                element={<RutasYFrecuenciasAereas />}
              ></Route>
              <Route
                path="rutas_y_frecuencias_maritimas"
                element={<></>}
              ></Route>
              <Route
                path="cables_maritimos_de_fibra_optica"
                element={<></>}
              ></Route>
            </Route>
            <Route
              path="charts/tejido_empresarial_y_desempeno_sectorial"
              element={
                <AppLayout
                  category={"tejido_empresarial_y_desempeno_sectorial"}
                />
              }
            >
              <Route index element={<Navigate to="empresas" replace />} />
              <Route path="empresas" index element={<Empresas />}></Route>
            </Route>
            <Route
              path="charts/entorno_de_negocios_y_competitividad"
              element={
                <AppLayout category={"entorno_de_negocios_y_competitividad"} />
              }
            >
              <Route
                index
                element={<Navigate to="competitividad_ciudades" replace />}
              />
              <Route
                path="competitividad_ciudades"
                index
                element={<CompetitividadCiudades />}
              ></Route>
              <Route
                path="competitividad_departamentos"
                element={<CompetitividadDepartamentos />}
              ></Route>
            </Route>
            <Route
              path="charts/gestion_de_atraccion_y_retencion_de_inversiones"
              element={
                <AppLayout
                  category={"gestion_de_atraccion_y_retencion_de_inversiones"}
                />
              }
            >
              <Route
                index
                element={
                  <Navigate to="atraccion_y_retencion_de_inversiones" replace />
                }
              />
              <Route
                path="atraccion_y_retencion_de_inversiones"
                index
                element={<AtraccionYRetencionDeInversiones />}
              ></Route>
              <Route
                path="inversion_extranjera_directa"
                element={<EvolucionClasesSociales />}
              ></Route>
            </Route>
          </Route>
        </Route>

        <Route path="admin/*" element={<RequireAuth userRole="ADMIN" />}>
          <Route element={<AdminLayout />}>
            <Route index element={<Navigate to="users" replace />} />
            <Route path="users" index element={<Users />}></Route>
            <Route
              path="indicadoresYcategorias"
              index
              element={<IndicadoresYcategorias />}
            ></Route>
            {MappingRoutes.categories.map((category, index) => {
              return (
                <Route
                  key={index}
                  path={category.value}
                  element={
                    <Administrador
                      category={category.label}
                      subCategories={category.childs}
                    />
                  }
                ></Route>
              );
            })}
            <Route
              path="repository/categories"
              element={<RepositoryCategories />}
            ></Route>
            <Route
              path="repository/authors"
              element={<RepositoryAuthors />}
            ></Route>
            <Route
              path="repository/document_types"
              element={<RepositoryDocumentTypes />}
            ></Route>
            <Route path="repository" element={<RepositoryDocuments />}></Route>
            <Route
              path="maps"
              element={<AdminMaps categories={MapCategories} />}
            ></Route>
          </Route>
        </Route>

        {/* 404 Not Found Route  */}
        <Route path="*" element={<NotFound />}></Route>
      </Route>
    </Routes>
  );
}
export default Router;
