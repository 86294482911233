import React, { useEffect, useMemo, useRef } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { useMenuContext } from "../context/MenuProvider";

const SubMenu = (props) => {
  const { menus: menuCategoryStatus } = useMenuContext();
  const category = props.category;

  const subcategories = useMemo(() => {
    return (
      subCategoryMapping[category] ? subCategoryMapping[category] : []
    ).filter(
      (menu) =>
        menuCategoryStatus?.findIndex(
          (cat) => cat.keyName === menu.slug && cat.status === "active"
        ) > -1
    );
  }, [category, menuCategoryStatus]);

  const scrollMenuRef = useRef();

  useEffect(() => {
    scrollMenuRef?.current?.scrollToItem(scrollMenuRef?.current?.items.first());
  }, [category]);

  return (
    <div className="w-full border-y-2 border-gray flex items-center justify-center h-14 md:h-16">
      <ScrollMenu
        apiRef={scrollMenuRef}
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        wrapperClassName="w-fit max-w-full"
        scrollContainerClassName="no_scroll gap-2 md:gap-5 items-center"
        itemClassName="w-28 md:w-32"
      >
        {subcategories.map(({ label, slug }, index) => (
          <NavLink
            to={slug}
            relative={"/" + category}
            itemID={"subcat_" + index}
            key={"subcat_" + index}
            className={({ isActive }) =>
              "text-sm md:text-tiny font-medium text-primary text-center leading-none w-28 md:w-32 h-14 md:h-16 items-center justify-center flex border-b-2 hover:bg-primary hover:bg-opacity-5" +
              (isActive ? " border-primary" : " border-transparent")
            }
            href="#sd"
          >
            {label}
          </NavLink>
        ))}
      </ScrollMenu>
    </div>
  );
};

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div
      className={"flex items-center mr-3 cursor-pointer"}
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      onKeyPress={() => {}}
      role="button"
    >
      <AiOutlineLeft
        className={"" + (isFirstItemVisible ? " hidden" : "")}
        size={23}
      />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div
      className={"flex items-center ml-3 cursor-pointer"}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      onKeyPress={() => {}}
      role="button"
    >
      <AiOutlineRight
        className={"" + (isLastItemVisible ? " hidden" : "")}
        size={23}
      />
    </div>
  );
}

const subCategoryMapping = {
  indicadores_sociales: [
    {
      label: "Panorama demográfico",
      slug: "poblacion_genero_y_edad",
    },
    {
      label: "Inversión pública per cápita",
      slug: "inversion_publica_per_capita",
    },
    // {
    //   label: "Nivel de ingreso Promedio",
    //   slug: "nivel_de_ingreso_promedio",
    // },
    {
      label: "Panorama de Pobreza y Desigualdad",
      slug: "panorama_pobreza_desigualdad",
    },
    // {
    //   label: "Pobreza, pobreza extrema Y GINI",
    //   slug: "pobreza_pobreza_extremay_gni",
    // },
    // {
    //   label: "Pobreza multidimensional",
    //   slug: "pobreza_multidimensional",
    // },
    {
      label: "Evolución Clases Sociales",
      slug: "evolucion_clases_sociales",
    },
  ],
  desempeno_economico: [
    {
      label: "Desempeño PIB",
      slug: "pib_y_participacion_pib_pib_nacional",
    },
    // {
    //   label: "Crecimiento del PIB",
    //   slug: "crecimiento_del_pib",
    // },
    // {
    //   label: "PIB: Distribución Sectorial",
    //   slug: "pib_distribucion_sectorial",
    // },
    // {
    //   label: "Desempeño sectorial",
    //   slug: "pib_per_capita",
    // },
    {
      label: "IPC",
      slug: "ipc",
    },
    {
      label: "Mercado Laboral",
      slug: "tasa_global_de_participacion",
    },
    // {
    //   label: "Tasa de Ocupados",
    //   slug: "tasa_de_ocupados",
    // },
    // {
    //   label: "Tasa de Desocupados",
    //   slug: "tasa_de_desocupados",
    // },
  ],
  talento_humano: [
    {
      label: "Egresados de Instituciones de Educación Superior (IES)",
      slug: "egresados_anuales_por_area_academica",
    },
    // {
    //   label: "Egresados anuales por nivel de formación",
    //   slug: "egresados_anuales_por_nivel_de_formacion",
    // },
    {
      label: "Oferta académica e institucional",
      slug: "oferta_academica",
    },
    {
      label: "Formación para el trabajo",
      slug: "instituciones_y_programas_de_formacion_para_el_trabajo",
    },
    // {
    //   label: "Matriculados y certificados en formación para el trabajo",
    //   slug: "matriculados_y_certificados_en_formacion_para_el_trabajo",
    // },
    {
      label: "Oferta de idiomas",
      slug: "instituto_de_idiomas",
    },
    {
      label: "Desempeño de Competencias Profesionales",
      slug: "desempeno_competencias_profesionales",
    },
  ],
  costos_de_instalacion_y_operacion: [
    {
      label: "Servicios públicos",
      slug: "servicios_publicos",
    },
    {
      label: "Panorama salarial",
      slug: "salarios_por_sectores_y_cargos",
    },
    {
      label: "Panorama inmobiliario",
      slug: "costos_de_arriendo_y_venta_de_inmuebles",
    },
  ],
  conectividad_y_acceso_a_mercados: [
    {
      label: "Exportaciones",
      slug: "exportaciones_e_importaciones",
    },
    {
      label: "Importaciones",
      slug: "importaciones",
    },
    {
      label: "Conectividad a internet",
      slug: "acceso_a_internet",
    },
    // {
    //   label: "Transporte terrestre interno",
    //   slug: "transporte_terrestre_interno",
    // },
    // {
    //   label: "Movilización de pasajeros",
    //   slug: "movilizacion_de_pasajeros",
    // },
    // {
    //   label: "Carga aérea",
    //   slug: "carga_aerea",
    // },
    // {
    //   label: "Visitantes internacionales",
    //   slug: "visitantes_internacionales",
    // },
    {
      label: "Tráfico portuario",
      slug: "trafico_portuario",
    },
    {
      label: "Conectividad terrestre, aérea y marítima",
      slug: "rutas_y_frecuencias_aereas",
    },
    // {
    //   label: "Rutas y frecuencias maritimas",
    //   slug: "rutas_y_frecuencias_maritimas",
    // },
    // {
    //   label: "Cables marítimos de fibra óptica",
    //   slug: "cables_maritimos_de_fibra_optica",
    // },
  ],
  tejido_empresarial_y_desempeno_sectorial: [
    {
      label: "Listados de principales empresas",
      slug: "empresas",
    },
  ],
  entorno_de_negocios_y_competitividad: [
    {
      label: "Entrada de Visitantes Internacionales",
      slug: "competitividad_ciudades",
    },
    {
      label: "Panorama turístico",
      slug: "competitividad_departamentos",
    },
  ],
  gestion_de_atraccion_y_retencion_de_inversiones: [
    {
      label: "Atracción y retención de inversiones",
      slug: "atraccion_y_retencion_de_inversiones",
    },
    {
      label: "Inversión Extranjera Directa",
      slug: "inversion_extranjera_directa",
    },
  ],
};

export default SubMenu;
