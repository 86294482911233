import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { Oval } from  "react-loader-spinner"; 
import { useLocationContext } from "../context/LocationProvider";

const LOGIN_URL = "/login";

const Login = () => {

  const { setAuth } = useAuth();

  const [user,setUser] = useState("");
  const [password,setPassword] = useState("");
  const [remember,setRemember] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();

  const [loginProgress, setLoginProgress] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from  = location.state?.from.pathname || "/";

  const {location: geoLocation} = useLocationContext()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      setEmailError();
      setPasswordError();
      setLoginProgress(true);
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({ email: user, password, rememberMe: remember }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true
        }
      );
      await axios.post('/analytic', JSON.stringify({
        page: "login",
        visitPageStart: new Date().toISOString(),
        visitPageEnd: new Date().toISOString(),
        visitPageDuration: 0,
        location: geoLocation
      }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
      })
      setLoginProgress(false);
      setUser("");
      setPassword("");
      setAuth({ user: response.data.data });
      navigate(from,{ replace: true }); 
    }
    catch(err){
      if(!err?.response){
        setPasswordError("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.message) {
        if(err.response?.status === 401){
          setEmailError(err.response.data.message);
        }
        else{
          setPasswordError(err.response.data.message);
        }
      } else if (err.response?.status === 400){
        setPasswordError("Login failed, Bad request");
      } else if (err.response?.status === 401){
        setEmailError("Email or password invalid");
      } else {
        setPasswordError("Login failed, something went wrong. Please try again later.");
      }
      setLoginProgress(false);
    }
  };
    
  return (
    <form className="flex flex-col w-full pt-6 text-tiny gap-6" onSubmit={handleSubmit}>
      <div className="w-full">
        <div className="w-full border rounded bg-lightGray border-gray">
          <input 
            type="email" 
            placeholder="Correo electrónico" 
            className="w-full border rounded bg-lightGray border-gray p-4" 
            value={user}
            onChange={(e)=>setUser(e.target.value)}
            required/>
        </div>
        {
          emailError && emailError.trim().length > 0 ? 
            <p className="text-small text-red mt-1">{emailError}</p>
            :
            <></>
        }
      </div>
      <div className="w-full">
        <div className="w-full border rounded bg-lightGray border-gray relative">
          <input
            type={passwordVisible ? "text" : "password"}
            autoComplete="off" placeholder="Contraseña"
            className="w-full border rounded bg-lightGray border-gray p-4"
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            required/>
          <span className="cursor-pointer absolute right-4 top-4 text-darkGray" onClick={()=>setPasswordVisible(!passwordVisible)} onKeyPress={()=>{}} role="button" tabIndex={0}>{passwordVisible ? "Ocultar" : "Ver"}</span>
        </div>
        {
          passwordError && passwordError.trim().length > 0 ? 
            <p className="text-small text-red mt-2">{passwordError}</p>
            :
            <></>
        }
      </div>
      <div className="flex flex-row w-100">
        <div className="grow flex justify-center flex-col">
          <div className="flex items-center text-darkGray accent-primary">
            <input
              type="checkbox"
              id="remember_me"
              className="cursor-pointer w-4 h-4 text-darkGray rounded-0 border-darkGray"
              checked={remember}
              value={true}
              onChange={()=>{ setRemember(!remember);}}/>
            <label htmlFor="remember_me" className="pl-2 cursor-pointer">Recuérdame</label>
          </div>
          {
            loginProgress ? 
              <Oval
                height={35}
                width={35}
                color="#005AA9"
                wrapperClass="w-fit mt-4 ml-6"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#B3B9D5"
                strokeWidth={4}
                strokeWidthSecondary={4} />
              :
              <button className="bg-primary text-textColorSecondary w-fit mt-4 rounded pl-6 pr-6 pt-3 pb-3">Iniciar sesión</button>
          }
        </div>
        <div className="">
          <NavLink className="text-primary" to="/forgot_password">¿Olvidaste tu Contraseña?</NavLink>
        </div>
      </div>
      <p className="text-xs">Al continuar, aceptas las <a href="https://probarranquilla.org/politicas-tratamiento" className="text-primary">Condiciones de uso y el Aviso de privacidad</a> de ProBarranquilla.</p>
    </form>
  );
};

export default Login;

