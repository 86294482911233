import React, { useState } from "react";
import { Oval } from  "react-loader-spinner";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "../api/axiosAuth";
import {  toast } from "react-toastify";

const DeleteConfirmationModal = (props) => {

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try{
      const response = await axios.delete(props.deleteUrl);
      toast.success(response.data.message);
      setLoading(false);
      props.modalClose && props.modalClose(true);
    }
    catch(err) {
      if(!err?.response){
        toast.error("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.message){
        toast.error(err.response.data.message);
      } else {
        toast.error("Delete failed, something went wrong. Please try again later.");
      }
      setLoading(false);
    }
  };

  return (
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Delete Confirmation</h3>
      </div>
      <div className="w-full flex justify-center items-center pt-5 pb-3">
        <IoIosCloseCircleOutline size={60} className="text-red" />
      </div>
      <div className="w-full flex flex-col justify-center items-center px-10 gap-2">
        <h4 className="font-medium text-lg">Are you sure?</h4>
        <p className="text-tiny text-darkGray text-center">Do you really want to delete these records? This process cannot be undone.</p>
      </div>
      <div className="border-gray pt-2 flex justify-end mt-5">
        {
          loading ? 
            <Oval
              height={35}
              width={35}
              color="#005AA9"
              wrapperClass="w-fit mr-6"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#B3B9D5"
              strokeWidth={4}
              strokeWidthSecondary={4} />
            :
            <div className="flex gap-3">
              <button className="text-tiny bg-gray px-3 py-1 rounded shadow" onClick={()=>{props.modalClose && props.modalClose();}}>Cancel</button>
              <button className="text-tiny bg-red text-textColorSecondary px-3 py-1 rounded shadow" onClick={()=>{handleSubmit();}}>Delete</button>
            </div>
        }
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;