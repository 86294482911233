import React from "react";

import StyledChart from "../../components/StyledChart";
import useAuth from "../../hooks/useAuth";
import PrincipalesEmpresas from "./principales_empresas";

const currentYear = 2022;
const yearsList = [];
for (let i = 2; i <= 4; i++) {
  yearsList.push({
    label: currentYear - i,
    value: currentYear - i,
  });
}

const Empresas = () => {
  const {
    auth: { user },
  } = useAuth();

  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      {/* <iframe
        src={`/api-dash/tejidoempresarial/${user?.dashChartsToken}`}
        title="Listados de principales empresas"
        style={{ width: "100%", height: "1700px" }}
        scrolling="no"
      /> */}
      <PrincipalesEmpresas />
    </div>
  );
};

const EmpresasOld = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="dinamica-de-creacion-de-empresas"
        chartType="bar"
        dropdown1Key="tamanoEmpresa"
        dropdown1Items={[
          { value: "Micro", label: "Micro" },
          { value: "Mediana", label: "Mediana" },
          { value: "Pequeña", label: "Pequeña" },
          { value: "Grande", label: "Grande" },
        ]}
        dropdown2Key="naturalezaJuridica"
        dropdown2Items={[
          { value: "Natural", label: "Natural" },
          { value: "Sociedades", label: "Sociedades" },
        ]}
        title="Listados de principales empresas"
        hintTitle="Listados de principales empresas"
        hintDescription="Este tablero interactivo proporciona información detallada sobre el número y el tamaño de las empresas recién creadas, así como sobre las actividades comerciales en las que se centran."
        footerText="Fuente: "
        yearEnabled={true}
        yearKey="ano"
        transformData={transformData}
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        barColumnHeight={30}
        barColumnWidth={30}
        height={300}
        strokeWidth={2}
        strokeColor={"transparent"}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        horizontalBar={true}
        barDistributed={true}
        chartColors={[
          "#27388D",
          "#F1D37C",
          "#8EBAD1",
          "#F9B68A",
          "#F2B566",
          "#6F96DA",
          "#97B3E5",
          "#ACC7F6",
        ]}
      />
      <StyledChart
        serverId="proporcion-de-creacion-de-empresas-segun-tamano"
        chartType="donut"
        dropdown1Key="departamento"
        dropdown1Items={[
          { value: "Atlántico", label: "Atlántico" },
          { value: "Bogotá", label: "Bogotá" },
        ]}
        title="Proporción de creación de empresas según tamaño"
        hintTitle="Proporción de creación de empresas según tamaño"
        hintDescription="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        footerText="Fuente: "
        yearEnabled={true}
        years={yearsList}
        yearKey="ano"
        transformData={transformData2}
        dataLabelsEnabled={true}
        chartClassName="max-w-xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        strokeColor="transparent"
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        legendPosition="right"
        legendHorizontalAlign="right"
        chartColors={[
          "#27388D",
          "#F1D37C",
          "#8EBAD1",
          "#F9B68A",
          "#F2B566",
          "#6F96DA",
          "#97B3E5",
          "#ACC7F6",
        ]}
        legendEnable={true}
        height={350}
      />
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: [],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    let categories = [];
    let series = [];
    for (let i = 0; i < data.length; i++) {
      if (!categories.includes(data[i].departamento)) {
        categories.push(data[i].departamento);
        series.push(data[i].empresas);
      }
    }
    if (categories.length > 0) {
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Empresas",
        data: series,
      },
    ];
  }
  return dataObj;
};

const transformData2 = (data) => {
  const dataObj = {
    series: [],
    categories: [],
    labels: [],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    let categories = [];
    let finalData = [];
    let labels = [];
    for (let i = 0; i < data.length; i++) {
      if (!categories.includes(data[i].tamanoEmpresa)) {
        categories.push(data[i].tamanoEmpresa);
        finalData.push(data[i].empresas);
        labels.push(data[i].tamanoEmpresa);
      }
    }
    if (categories.length > 0) {
      dataObj.categories = categories;
      dataObj.labels = labels;
    }
    dataObj.series = finalData;
  }
  return dataObj;
};

export default Empresas;
