import React from "react";
import useAuth from "../../hooks/useAuth";


const DesempenoCompetencias = () => {
  const {
    auth: { user },
  } = useAuth();

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <iframe
          src={`/api-dash/resultadosicfes/${user?.dashChartsToken}`}
          title="IPC"
          style={{ width: "100%", height: "1800px" }}
          scrolling="no"
        />
      </div>
    </div>
  );
};

export default DesempenoCompetencias;