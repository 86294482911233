import React, { useEffect, useRef } from "react";

let viz;

const VisitantesExtranjeros = () => {
  const exportaRef = useRef();

  useEffect(() => {
    if (viz) {
      viz.dispose();
    }
    const vizUrl =
      "https://public.tableau.com/views/Turismo-VisitantesExtranjeros/VisitantesExtranjeros?:language=es-ES&amp;:display_count=n&amp;:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current, vizUrl);
  }, []);

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{ width: "100%", margin: "auto" }}></div>
      </div>
    </div>
  );
};

export default VisitantesExtranjeros;
