import React,{ useEffect, useRef } from "react";

// import StyledChart from "../../components/StyledChart";

let viz; 

const EvolucionClasesSociales = () => {

  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/IED-ProData/IED-ProData?:language=es-ES&:sid=&:display_count=n&:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
  );

//   const chartRef1 = useRef();
//   const chartRef2 = useRef();
  
//   return (
//     <div className="w-full pt-5 flex flex-col gap-5">
//       <StyledChart
//         chartRef={chartRef1}
//         serverId="evolucion-clases-sociales-barranquilla"
//         chartType="bar"
//         title="Evolución Clases Sociales: Barranquilla"
//         hintTitle="Evolución Clases Sociales: Barranquilla"
//         hintDescription="Análisis del comportamiento de la distribución de las clases sociales en Barranquilla, detallando su evolución a lo largo del tiempo y mostrando las tendencias y cambios en la estructura social de la ciudad."
//         footerText="Fuente: DANE - GEIH"
//         yearEnabled={false}
//         transformData={transformData}
//         legendPosition="bottom"
//         legendHorizontalAlign="center"
//         dataLabelsEnabled={true}
//         chartClassName="max-w-4xl px-0 md:px-3 pt-3"
//         strokeWidth={2}
//         strokeColor="transparent"
//         xAxisLabelVisible={true}
//         yAxisLabelVisible={true}
//         yAxisLabelFormatter={(val)=>{ return val+"%"; }}
//         stacked={true}
//         barColumnWidth={50}
//         chartColors={["#27388D","#F1D37C","#8EBAD1", "#F9B68A"]}
//         legendEnable={true}/>
//       <StyledChart
//         chartRef={chartRef2}
//         serverId="evolucion-clases-sociales-principales-ciudades"
//         dropdown1Key="clasesSociales"
//         dropdown1Items={[
//           {value: "Pobres", label: "Pobres"},
//           {value: "Vulnerables", label: "Vulnerables"},
//           {value: "Clase media", label: "Clase media"},
//           {value: "Clase Alta", label: "Clase Alta"},
//         ]}
//         chartType="line"
//         title="Evolución Clases sociales: Ciudades principales"
//         hintTitle="Evolución Clases sociales: Ciudades principales"
//         hintDescription="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
//         footerText="Fuente: DANE - GEIH"
//         yearEnabled={false}
//         transformData={transformData2}
//         legendPosition="bottom"
//         legendHorizontalAlign="center"
//         dataLabelsEnabled={false}
//         chartClassName="max-w-4xl px-0 md:px-3 pt-3"
//         strokeWidth={4}
//         xAxisLabelVisible={true}
//         yAxisLabelVisible={true}
//         yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val/100) : val; }}
//         dashArray={[0,3,3,3,3,3,3,3]}
//         chartColors={["#27388D", "#F1D37C", "#8EBAD1", "#F9B68A", "#F2B566", "#6F96DA", "#97B3E5", "#ACC7F6"]}
//         legendEnable={true}
//         onDataLoaded={(data)=>{
//           if(data?.series && data.series.length > 1){
//             for(let i=1;i<data.series.length;i++){
//               //chartRef2?.current?.chart?.hideSeries(data.series[i].name);
//             }
//           }
//         }}/>
//     </div>
//   );
// };


// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let categories = [];
//     let pobres = [];
//     let vulnerables = [];
//     let claseMedia = [];
//     let claseAlta = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ano)){
//         categories.push(data[i].ano);
//         pobres.push(data[i].pobres);
//         vulnerables.push(data[i].vulnerables);
//         claseMedia.push(data[i].claseMedia);
//         claseAlta.push(data[i].claseAlta);
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = [
//       {
//         name: "Pobres",
//         data: pobres
//       },
//       {
//         name: "Vulnerables",
//         data: vulnerables
//       },
//       {
//         name: "Clase Media",
//         data: claseMedia
//       },
//       {
//         name: "Clase Alta",
//         data: claseAlta
//       }
//     ];
//   }
//   return dataObj;
// };

// const transformData2 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let categories = [];
//     let barranquilla = [];
//     let bogota = [];
//     let bucaramanga = [];
//     let cali = [];
//     let cartagena = [];
//     let medellin = [];
//     let promNacional = [];
//     let santaMarta = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ano)){
//         categories.push(data[i].ano);
//         barranquilla.push(data[i].barranquilla);
//         bogota.push(data[i].bogota);
//         bucaramanga.push(data[i].bucaramanga);
//         cali.push(data[i].cali);
//         cartagena.push(data[i].cartagena);
//         medellin.push(data[i].medellin);
//         promNacional.push(data[i].promNacional);
//         santaMarta.push(data[i].santaMarta);
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = [
//       {
//         name: "Barranquilla",
//         data: barranquilla
//       },
//       {
//         name: "Bogotá",
//         data: bogota
//       },
//       {
//         name: "Cali",
//         data: cali
//       },
//       {
//         name: "Medellín",
//         data: medellin
//       },
//       {
//         name: "Bucaramanga",
//         data: bucaramanga
//       },
//       {
//         name: "Prom. Nacional",
//         data: promNacional
//       },
//       {
//         name: "Cartagena",
//         data: cartagena
//       },
//       {
//         name: "Santa Marta",
//         data: santaMarta
//       }
//     ];
//   }
//   return dataObj;
};

export default EvolucionClasesSociales;