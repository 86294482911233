import React, { useState, useRef, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import axios from "../../../api/axiosAuth";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import debounce from "debounce-promise";

const DocumentUpdateModal = (props) => {
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState();

  const [selectedThumbnailFile, setSelectedThumbnailFile] = useState();
  const [name, setName] = useState("");
  const [publishDate, setPublishDate] = useState();
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState();
  const [author, setAuthor] = useState();
  const [documentType, setDocumentType] = useState();
  const [fileType, setFileType] = useState();
  const [file, setFile] = useState();
  const [actionLink, setActionLink] = useState();

  const [thumbnailError, setThumbnailError] = useState();
  const [nameError, setNameError] = useState();
  const [publishDateError, setPublishDateError] = useState();
  const [descriptionError, setDescriptionError] = useState();
  const [categoryErrror, setCategoryError] = useState();
  const [authorErrror, setAuthorErrror] = useState();
  const [documentTypeErrror, setDocumentTypeErrror] = useState();
  const [fileTypeErrror, setFileTypeErrror] = useState();
  const [fileError, setFileError] = useState();
  const [linkError, setLinkError] = useState();

  const [errorMessage, setErrorMessage] = useState();

  const thumbnailFileInput = useRef();

  const [thumbnailPreview, setThumbnailPreview] = useState();

  const handleSubmit = async () => {
    setNameError();
    setLinkError();
    setPublishDateError();
    setThumbnailError();
    setDescriptionError();
    setCategoryError();
    setAuthorErrror();
    setDocumentTypeErrror();
    setFileTypeErrror();
    setFileError();
    let isError = false;
    if (!name || name.trim() == "") {
      setNameError("Please enter name");
      isError = true;
    }

    if (!publishDate) {
      setPublishDateError("Please enter publish date");
      isError = true;
    }
    if (!description || description.trim() == "") {
      setDescriptionError("Please enter description");
      isError = true;
    }
    if (!category || !category.id) {
      setCategoryError("Please select category");
      isError = true;
    }
    if (!author || !author.id) {
      setAuthorErrror("Please select author");
      isError = true;
    }
    if (!documentType || !documentType.id) {
      setDocumentTypeErrror("Please select document type");
      isError = true;
    }
    if (!fileType || !fileType.value || fileType.value.trim() == "") {
      setFileTypeErrror("Please select file type");
      isError = true;
    }
    if (fileType?.value && fileType.value === "link" && !actionLink) {
      setLinkError("Please enter a target link");
      isError = true;
    }
    if (isError) {
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name.trim());
      formData.append("description", description.trim());
      formData.append(
        "publishDate",
        new Date(publishDate).toLocaleDateString("sv-SE")
      );
      formData.append("author", author.id);
      formData.append("documentType", documentType.id);
      formData.append("category", category.id);
      formData.append("fileType", fileType.value);
      formData.append("action_link", actionLink);
      if (selectedThumbnailFile)
        formData.append("thumbnail", selectedThumbnailFile);
      if (file) formData.append("file", file);
      const response = await axios.put("/repository/" + id, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      toast.success(response.data.message);
      props.modalClose && props.modalClose(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMessage(
          "No server response, something went wrong. Please try again later."
        );
      } else if (err.response.data?.errors) {
        let isErrorSet = false;
        for (let i = 0; i < err.response.data.errors.length; i++) {
          let error = err.response.data.errors[i];
          if (error.property === "name") {
            setNameError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "description") {
            setDescriptionError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "publishDate") {
            setPublishDateError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "author") {
            setAuthorErrror(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "documentType") {
            setDocumentTypeErrror(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "category") {
            setCategoryError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "fileType") {
            setFileTypeErrror(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "file") {
            setFileError(Object.values(error.constraints).join());
            isErrorSet = true;
          }
        }
        if (!isErrorSet && err.response.data?.message) {
          setErrorMessage(err.response.data.message);
        }
      } else if (err.response.data?.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage(
          "Document update failed, something went wrong. Please try again later."
        );
      }
      setLoading(false);
    }
  };

  let debounceCategoryOptions;
  let debounceAuthorOptions;
  let debounceDocumentTypeOptions;
  useEffect(() => {
    setId(props.data?.id);
    setName(props.data?.name);
    setDescription(props.data?.description);
    setPublishDate(new Date(props.data?.publishDate));
    if (props.data?.author?._id)
      setAuthor({ id: props.data.author._id, name: props.data.author.name });
    if (props.data?.category?._id)
      setCategory({
        id: props.data.category._id,
        name: props.data.category.name,
      });
    if (props.data?.documentType?._id)
      setDocumentType({
        id: props.data.documentType._id,
        name: props.data.documentType.name,
      });
    if (props.data?.fileType) {
      setFileType({
        value: props.data.fileType,
        label: props.data.fileType?.toUpperCase(),
      });
      setActionLink(props.data?.action_link);
    }
    //console.log(axios.defaults.baseURL +"/"+ props.data?.thumbnail);
    setThumbnailPreview(axios.defaults.baseURL + "/" + props.data?.thumbnail);
    debounceCategoryOptions = debounce(loadCategories, 1000);
    debounceAuthorOptions = debounce(loadAuthors, 1000);
    debounceDocumentTypeOptions = debounce(loadDocumentTypes, 1000);
  }, []);

  useEffect(() => {
    if (!selectedThumbnailFile) {
      setThumbnailPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedThumbnailFile);
    setThumbnailPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedThumbnailFile]);

  const loadCategories = (searchQuery) => {
    return axios
      .get("/repository/category", {
        params: {
          filterQuery: {
            ...(searchQuery &&
              searchQuery.trim().length != 0 && { filter: searchQuery }),
          },
          pagination: true,
          page: 1,
          limit: 5,
        },
      })
      .then((response) => {
        return response.data.data;
      });
  };

  const loadAuthors = (searchQuery) => {
    return axios
      .get("/repository/author", {
        params: {
          filterQuery: {
            ...(searchQuery &&
              searchQuery.trim().length != 0 && { filter: searchQuery }),
          },
          pagination: true,
          page: 1,
          limit: 5,
        },
      })
      .then((response) => {
        return response.data.data;
      });
  };

  const loadDocumentTypes = (searchQuery) => {
    return axios
      .get("/repository/document_type", {
        params: {
          filterQuery: {
            ...(searchQuery &&
              searchQuery.trim().length != 0 && { filter: searchQuery }),
          },
          pagination: true,
          page: 1,
          limit: 5,
        },
      })
      .then((response) => {
        return response.data.data;
      });
  };

  return (
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Documento de actualización</h3>
      </div>
      <div className="w-full mt-3 flex justify-center items-center flex-col">
        <button
          type="button"
          className="flex justify-center items-center w-40 h-52 border border-gray border-dashed cursor-pointer"
          onClick={() => {
            thumbnailFileInput.current?.click();
          }}
        >
          {thumbnailPreview ? (
            <img
              className="w-100 h-100"
              src={thumbnailPreview}
              alt="Thumbnail preview"
            />
          ) : (
            <div className="">
              <p className="text-xs text-darkGray">Choose Thumbnail</p>
            </div>
          )}
          <input
            ref={thumbnailFileInput}
            type="file"
            className="hidden"
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) {
                setSelectedThumbnailFile(undefined);
                return;
              }
              setSelectedThumbnailFile(e.target.files[0]);
            }}
          />
        </button>
        {thumbnailError && thumbnailError.trim().length > 0 ? (
          <p className="text-sm text-red mt-1 font-medium">{thumbnailError}</p>
        ) : (
          <></>
        )}
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            type="text"
            className="border border-gray rounded px-2 py-1 w-full text-tiny"
            placeholder="Nombre"
          />
          {nameError && nameError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">{nameError}</p>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1">
          <DatePicker
            className="border border-gray rounded px-2 py-1 w-full text-tiny"
            placeholderText="Fecha de Publicación"
            selected={publishDate}
            onChange={setPublishDate}
            isClearable
            closeOnScroll={true}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          {publishDateError && publishDateError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {publishDateError}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            type="text"
            className="border border-gray rounded px-2 py-1 w-full text-tiny"
            placeholder="Breve descripción"
          />
          {descriptionError && descriptionError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {descriptionError}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <AsyncSelect
            cacheOptions
            unstyled
            className="w-full text-tiny"
            value={category}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
            loadOptions={(inputValue) => debounceCategoryOptions(inputValue)}
            placeholder="Category"
            onChange={setCategory}
          />
          {categoryErrror && categoryErrror.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {categoryErrror}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1">
          <AsyncSelect
            cacheOptions
            unstyled
            className="w-full text-tiny"
            value={author}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
            loadOptions={(inputValue) => debounceAuthorOptions(inputValue)}
            placeholder="Autor"
            onChange={setAuthor}
          />
          {authorErrror && authorErrror.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">{authorErrror}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <Select
            isSearchable={false}
            className="w-full text-tiny"
            value={fileType}
            options={[
              { value: "pdf", label: "PDF" },
              { value: "ppt", label: "PPT" },
              { value: "link", label: "LINK" },
            ]}
            placeholder="Tipo de archivo"
            onChange={setFileType}
          />
          {fileTypeErrror && fileTypeErrror.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {fileTypeErrror}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1">
          <AsyncSelect
            cacheOptions
            unstyled
            className="w-full text-tiny"
            value={documentType}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
            loadOptions={(inputValue) =>
              debounceDocumentTypeOptions(inputValue)
            }
            placeholder="Tipo de documento"
            onChange={setDocumentType}
          />
          {documentTypeErrror && documentTypeErrror.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {documentTypeErrror}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      {fileType?.value === "link" ? (
        <div className="w-full flex mt-3 gap-2">
          <div className="flex-1">
            <input
              value={actionLink}
              onChange={(e) => {
                setActionLink(e.target.value);
              }}
              type="text"
              className="border border-gray rounded px-2 py-1 w-full text-tiny"
              placeholder="link"
            />
            {linkError && linkError.trim().length > 0 ? (
              <p className="text-sm text-red mt-1 font-medium">{linkError}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex mt-3 gap-2">
          <div className="flex-1">
            <input
              onChange={(e) => {
                if (!e.target.files || e.target.files.length === 0) {
                  setFile(undefined);
                  return;
                }
                setFile(e.target.files[0]);
              }}
              type="file"
              className="border border-gray rounded px-2 py-1 w-full text-tiny"
              placeholder="Archivo"
            />
            {fileError && fileError.trim().length > 0 ? (
              <p className="text-sm text-red mt-1 font-medium">{fileError}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {errorMessage && errorMessage.trim().length > 0 ? (
        <p className="text-tiny text-red font-medium mt-3">{errorMessage}</p>
      ) : (
        <></>
      )}
      <div className="border-gray pt-2 flex justify-end mt-5">
        {loading ? (
          <Oval
            height={35}
            width={35}
            color="#005AA9"
            wrapperClass="w-fit mr-6"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#B3B9D5"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        ) : (
          <div className="flex gap-3">
            <button
              className="text-tiny bg-gray px-3 py-1 rounded shadow"
              onClick={() => {
                props.modalClose && props.modalClose();
              }}
            >
              Close
            </button>
            <button
              className="text-tiny bg-primary text-textColorSecondary px-3 py-1 rounded shadow"
              onClick={() => {
                handleSubmit();
              }}
            >
              Update
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default DocumentUpdateModal;
