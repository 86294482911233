import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import YearSelector from "../components/YearSelector";
import { MdFileDownload } from "react-icons/md";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import useModal from "../hooks/useModal";
import InfoModal from "../components/InfoModal";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import { useQuery } from "react-query";
import axios from "../api/axiosAuth";

const currentYear = 2022;
const yearsList = [];
for (let i = 0; i < 5; i++) {
  yearsList.push({
    label: currentYear - i,
    value: currentYear - i,
  });
}

const StyledChart = (props) => {
  const title = props.title;
  const hintTitle = props.hintTitle;
  const hintDescription = props.hintDescription;
  const chartType = props.chartType;
  const footerText = props.footerText;
  const serverId = props.serverId;
  const yearEnabled = props.yearEnabled;
  const dropdown1Key = props.dropdown1Key;
  const dropdown2Key = props.dropdown2Key;
  const dropdown3Key = props.dropdown3Key;
  const dropdown4Key = props.dropdown4Key;
  const labels = props.labels;
  const yearKey = props.yearKey;
  const legendPosition = props.legendPosition;
  const legendHorizontalAlign = props.legendHorizontalAlign;
  const dataLabelsEnabled = props.dataLabelsEnabled;
  const chartClassName = props.chartClassName;
  const wrapperClassName = props.wrapperClassName;
  const chartColors = props.chartColors;
  const strokeWidth = props.strokeWidth;
  const strokeColor = props.strokeColor;
  const curveType = props.curveType;
  const yAxisTitle = props.yAxisTitle;
  const yAxisTitleClassName = props.yAxisTitleClassName;
  const xAxisLabelVisible = props.xAxisLabelVisible;
  const yAxisLabelVisible = props.yAxisLabelVisible;
  const xAxisLabelFormatter = props.xAxisLabelFormatter;
  const yAxisLabelFormatter = props.yAxisLabelFormatter;
  const tooltipXFormatter = props.tooltipXFormatter;
  const tooltipYFormatter = props.tooltipYFormatter;
  const tooltipYTitleFormatter = props.tooltipYTitleFormatter;
  const onDataLoaded = props.onDataLoaded;
  const stacked = props.stacked;
  const legendMarkerRadius = props.legendMarkerRadius;
  const horizontalBar = props.horizontalBar;
  const yAxis = props.yAxis;
  const dashArray = props.dashArray;
  const customTooltip = props.customTooltip;
  const YAxisBorderShow = props.YAxisBorderShow;
  const roundedBar = props.roundedBar;
  const barDistributed = props.barDistributed;
  const treemapDistributed = props.treemapDistributed;
  const treemapEnableShades = props.treemapEnableShades;
  const legendWidth = props.legendWidth;
  const XAxisType = props.XAxisType;
  const XAxisPosition = props.XAxisPosition;
  const dataLabelPosition = props.dataLabelPosition;
  const dataLabelXOffset = props.dataLabelXOffset;
  const dataLabelYOffset = props.dataLabelYOffset;
  const dataLabelColor = props.dataLabelColor;
  const dataLabelFontSize = props.dataLabelFontSize;
  const dataLabelFormatter = props.dataLabelFormatter;
  const fill = props.fill;
  const YaxisTickAmount = props.YaxisTickAmount;
  const EnableBrushChart = props.EnableBrushChart;
  const brushTargetType = props.brushTargetType;
  const height = props.height;
  const tabItems = props.tabItems;
  const legendEnable = props.legendEnable;
  const barColumnWidth = props.barColumnWidth;
  const barColumnHeight = props.barColumnHeight;
  const xAxisLabelRotation = props.xAxisLabelRotation;
  const xAxisLabelClassName = props.xAxisLabelClassName;
  const xAxisHideOverlapingLable = props.xAxisHideOverlapingLable;
  const yAxisLabelClassName = props.yAxisLabelClassName;
  const dropdown1Select = props.dropdown1Select;
  const CustomChart = props.CustomChart;
  const onFilterChange = props.onFilterChange;
  const onDataPointClicked = props.onDataPointClicked;
  const sharedTooltip = props.sharedTooltip;
  const gridPaddingLeft = props.gridPaddingLeft;
  const gridPaddingRight = props.gridPaddingRight;
  const gridPaddingTop = props.gridPaddingTop;
  const gridPaddingBottom = props.gridPaddingBottom;
  const yAxisTitleOffsetX = props.yAxisTitleOffsetX;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const chartRef = props.chartRef ? props.chartRef : useRef();

  const [years, setYears] = useState(yearsList);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [dropdown1Items, setDropdown1Items] = useState();
  const [dropdown1Selected, setDropdown1Selected] = useState();
  const [dropdown2Items, setDropdown2Items] = useState();
  const [dropdown2Selected, setDropdown2Selected] = useState();
  const [dropdown3Items, setDropdown3Items] = useState();
  const [dropdown3Selected, setDropdown3Selected] = useState();
  const [dropdown4Items, setDropdown4Items] = useState();
  const [dropdown4Selected, setDropdown4Selected] = useState();
  const [activeTab, setActiveTab] = useState();
  const [queryEnable, setQueryEnable] = useState(false);

  const fetchData = (filterQuery, sortBy, sortOrder) => {
    return axios.get(serverId, {
      params: {
        filterQuery: JSON.stringify(filterQuery),
        ...(sortBy && { sortBy }),
        ...(sortOrder && { sortOrder }),
      },
    });
  };

  const { isLoading, isFetching, data } = useQuery(
    [
      serverId,
      ...(yearEnabled && yearKey ? [selectedYear] : []),
      ...(dropdown1Items &&
      dropdown1Items.length != 0 &&
      dropdown1Selected &&
      dropdown1Key &&
      dropdown1Selected?.value !== ""
        ? [dropdown1Selected.value]
        : []),
      ...(dropdown2Items &&
      dropdown2Items.length != 0 &&
      dropdown2Selected &&
      dropdown2Key &&
      dropdown2Selected?.value !== ""
        ? [dropdown2Selected.value]
        : []),
      ...(dropdown3Items &&
      dropdown3Items.length != 0 &&
      dropdown3Selected &&
      dropdown3Key &&
      dropdown3Selected?.value !== ""
        ? [dropdown3Selected.value]
        : []),
      ...(dropdown4Items &&
      dropdown4Items.length != 0 &&
      dropdown4Selected &&
      dropdown4Key &&
      dropdown4Selected?.value !== ""
        ? [dropdown4Selected.value]
        : []),
    ],
    () =>
      fetchData(
        {
          ...(yearEnabled && yearKey && { [yearKey]: selectedYear }),
          ...(dropdown1Items &&
            dropdown1Items.length != 0 &&
            dropdown1Selected &&
            dropdown1Key &&
            dropdown1Selected?.value !== "" && {
              [dropdown1Key]: dropdown1Selected.value,
            }),
          ...(dropdown2Items &&
            dropdown2Items.length != 0 &&
            dropdown2Selected &&
            dropdown2Key &&
            dropdown2Selected?.value !== "" && {
              [dropdown2Key]: dropdown2Selected.value,
            }),
          ...(dropdown3Items &&
            dropdown3Items.length != 0 &&
            dropdown3Selected &&
            dropdown3Key &&
            dropdown3Selected?.value !== "" && {
              [dropdown3Key]: dropdown3Selected.value,
            }),
          ...(dropdown4Items &&
            dropdown4Items.length != 0 &&
            dropdown4Selected &&
            dropdown4Key &&
            dropdown4Selected?.value !== "" && {
              [dropdown4Key]: dropdown4Selected.value,
            }),
        },
        props.sortBy,
        props.sortOrder
      ),
    {
      enabled: queryEnable,
      keepPreviousData: true,
      select: props.transformData,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 900000,
      staleTime: 900000,
    }
  );

  useEffect(() => {
    if (props.dropdown1Selected) {
      if (dropdown1Items && dropdown1Items.length > 0) {
        for (let i = 0; i < dropdown1Items.length; i++) {
          if (dropdown1Items[i].value === props.dropdown1Selected) {
            setDropdown1Selected(dropdown1Items[i]);
            break;
          }
        }
      }
    }
  }, [props.dropdown1Selected]);

  useEffect(() => {
    if (onDataLoaded) {
      onDataLoaded(data);
    }
  }, [data]);

  const defaultYaxis = {
    forceNiceScale: true,
    title: {
      text: yAxisTitle,
      ...(yAxisTitleOffsetX && { offsetX: yAxisTitleOffsetX }),
      style: {
        cssClass: yAxisTitleClassName,
      },
    },
    labels: {
      show: yAxisLabelVisible ? true : false,
      style: {
        cssClass: yAxisLabelClassName
          ? yAxisLabelClassName
          : "text-gray text-xxs md:text-xs lg:text-sm",
      },
      formatter: yAxisLabelFormatter,
    },
    axisBorder: {
      show: YAxisBorderShow ? true : false,
      offsetX: 0,
      offsetY: 0,
    },
    ...(YaxisTickAmount &&
      !EnableBrushChart && { tickAmount: YaxisTickAmount }),
    ...(EnableBrushChart && { tickAmount: 4 }),
  };

  const options = {
    chart: {
      fontFamily: "Montserrat",
      type: chartType,
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      stacked: stacked ? true : false,
      events: {
        mounted: () => {
          // setTimeout(()=>{
          //   chart.windowResizeHandler();
          // },1000);
        },
        dataPointSelection: function (event, chartContext, config) {
          if (onDataPointClicked)
            onDataPointClicked(event, chartContext, config);
        },
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: true,
      },
      ...(EnableBrushChart && {
        brush: {
          target: serverId + "_brush",
          enabled: true,
          autoScaleYaxis: true,
        },
      }),
      ...(EnableBrushChart && {
        selection: {
          enabled: true,
          xaxis: {
            // min: new Date("19 Jun 2020").getTime(),
            // max: new Date("14 Aug 2021").getTime()
            min:
              data?.series &&
              data.series.length > 0 &&
              data.series[0]?.data.length > 10
                ? new Date(
                    data.series[0].data[data.series[0].data.length - 10].x
                  ).getTime()
                : undefined,
            max:
              data?.series &&
              data.series.length > 0 &&
              data.series[0]?.data.length > 6
                ? new Date(
                    data.series[0].data[data.series[0].data.length - 1].x
                  ).getTime()
                : undefined,
          },
        },
      }),
    },
    colors: chartColors ? chartColors : ["#E8B626", "#27388D"],
    plotOptions: {
      bar: {
        distributed: barDistributed ? true : false,
        horizontal: horizontalBar ? true : false,
        barHeight: barColumnHeight ? barColumnHeight : "80%",
        ...(barColumnWidth && { columnWidth: barColumnWidth }),
        borderRadius: roundedBar ? 10 : 0,
        dataLabels: {
          ...(dataLabelPosition && { position: dataLabelPosition }),
        },
      },
      treemap: {
        distributed: treemapDistributed ? true : false,
        enableShades: treemapEnableShades ? true : false,
      },
      heatmap: {
        radius: 200,
        enableShades: false,
        distributed: false,
        colorScale: {
          inverse: true,
        },
      },
    },
    dataLabels: {
      enabled: dataLabelsEnabled ? true : false,
      ...(dataLabelXOffset && { offsetX: dataLabelXOffset }),
      ...(dataLabelYOffset && { offsetY: dataLabelYOffset }),
      style: {
        ...(dataLabelFontSize && { fontSize: dataLabelFontSize }),
        ...(dataLabelColor && { colors: dataLabelColor }),
      },
      ...(dataLabelFormatter && { formatter: dataLabelFormatter }),
    },
    stroke: {
      width: strokeWidth ? strokeWidth : 1,
      colors: strokeColor,
      curve: curveType ? curveType : "straight",
      ...(dashArray && { dashArray }),
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        ...(gridPaddingLeft && { left: gridPaddingLeft }),
        ...(gridPaddingRight && { right: gridPaddingRight }),
        ...(gridPaddingTop && { top: gridPaddingTop }),
        ...(gridPaddingBottom && { bottom: gridPaddingBottom }),
      },
    },
    tooltip: {
      ...(sharedTooltip && { shared: sharedTooltip }),
      ...(sharedTooltip && { intersect: false }),

      x: {
        formatter: tooltipXFormatter,
      },
      y: {
        formatter: tooltipYFormatter,
        title: {
          ...(tooltipYTitleFormatter && { formatter: tooltipYTitleFormatter }),
        },
      },
      ...(customTooltip && { custom: customTooltip }),
    },
    xaxis: {
      ...(data?.categories && { categories: data.categories }),
      labels: {
        show: xAxisLabelVisible ? true : false,
        formatter: xAxisLabelFormatter,
        ...(xAxisLabelRotation && { rotate: xAxisLabelRotation }),
        hideOverlappingLabels:
          xAxisHideOverlapingLable === true
            ? true
            : xAxisHideOverlapingLable === false
            ? false
            : true,
        style: {
          ...(xAxisLabelClassName && { cssClass: xAxisLabelClassName }),
        },
      },
      ...(XAxisType && { type: XAxisType }),
      ...(XAxisPosition && { position: XAxisPosition }),
    },
    yaxis: yAxis ? yAxis : defaultYaxis,
    legend: {
      show: legendEnable ? true : false,
      markers: {
        radius: legendMarkerRadius ? legendMarkerRadius : 0,
      },
      ...(legendPosition && { position: legendPosition }),
      ...(legendHorizontalAlign && { horizontalAlign: legendHorizontalAlign }),
      ...(legendWidth && { width: legendWidth }),
    },
    noData: {
      text: "Informacion no disponible",
      align: "center",
      verticalAlign: "middle",
    },
    ...(labels ? { labels: labels } : data?.labels && { labels: data.labels }),
    ...(fill && { fill }),
  };

  function download(type) {
    const ctx = chartRef.current.chart.ctx;
    switch (type) {
      case "svg":
        ctx.exports.exportToSVG();
        break;
      case "png":
        ctx.exports.exportToPng();
        break;
      case "csv":
        ctx.exports.exportToCSV({
          series: chartRef.current.chart.series.w.config.series,
          columnDelimiter: ",",
        });
        break;
    }
  }

  const onYearChange = (index, item) => {
    setSelectedYear(item.value);
  };

  const { setModalView, setModalVisibility, setModalCancelable } = useModal();

  useEffect(() => {
    if (props.tabKey && props.tabItems.length > 0) {
      setActiveTab(props.tabItems[0].value);
    }
    if (props.years) {
      setYears(props.years);
      setSelectedYear(props.years[0].value);
    }
    if (props.dropdown1Items && props.dropdown1Items.length > 0) {
      setDropdown1Items(props.dropdown1Items);
      setDropdown1Selected(props.dropdown1Items[0]);
    }
    if (props.dropdown2Items && props.dropdown2Items.length > 0) {
      setDropdown2Items(props.dropdown2Items);
      setDropdown2Selected(props.dropdown2Items[0]);
    }
    if (props.dropdown3Items && props.dropdown3Items.length > 0) {
      setDropdown3Items(props.dropdown3Items);
      setDropdown3Selected(props.dropdown3Items[0]);
    }
    if (props.dropdown4Items && props.dropdown4Items.length > 0) {
      setDropdown4Items(props.dropdown4Items);
      setDropdown4Selected(props.dropdown4Items[0]);
    }
    setTimeout(() => {
      setQueryEnable(true);
    }, 100);
    return () => {};
  }, []);

  useEffect(() => {
    let dropdown1Changed = false;
    if (props.dropdown1Items && dropdown1Items) {
      if (
        dropdown1Items &&
        props.dropdown1Items &&
        props.dropdown1Items.length !== dropdown1Items.length
      ) {
        dropdown1Changed = true;
      } else {
        for (let i = 0; i < props.dropdown1Items.length; i++) {
          if (props.dropdown1Items[i].value != dropdown1Items[i].value) {
            dropdown1Changed = true;
            break;
          }
        }
      }
    }
    let dropdown2Changed = false;
    if (props.dropdown2Items && props.dropdown2Items && dropdown2Items) {
      if (
        dropdown2Items &&
        props.dropdown2Items &&
        props.dropdown2Items.length !== dropdown2Items.length
      ) {
        dropdown2Changed = true;
      } else {
        for (let i = 0; i < props.dropdown2Items.length; i++) {
          if (props.dropdown2Items[i].value != dropdown2Items[i].value) {
            dropdown2Changed = true;
            break;
          }
        }
      }
    }
    let dropdown3Changed = false;
    if (props.dropdown3Items && props.dropdown3Items && dropdown3Items) {
      if (
        dropdown3Items &&
        props.dropdown3Items &&
        props.dropdown3Items.length !== dropdown3Items.length
      ) {
        dropdown3Changed = true;
      } else {
        for (let i = 0; i < props.dropdown3Items.length; i++) {
          if (props.dropdown3Items[i].value != dropdown3Items[i].value) {
            dropdown3Changed = true;
            break;
          }
        }
      }
    }
    let dropdown4Changed = false;
    if (props.dropdown4Items && props.dropdown4Items && dropdown4Items) {
      if (
        dropdown4Items &&
        props.dropdown4Items &&
        props.dropdown4Items.length !== dropdown4Items.length
      ) {
        dropdown4Changed = true;
      } else {
        for (let i = 0; i < props.dropdown4Items.length; i++) {
          if (props.dropdown4Items[i].value != dropdown4Items[i].value) {
            dropdown4Changed = true;
            break;
          }
        }
      }
    }
    setQueryEnable(false);
    if (
      props.dropdown1Items &&
      props.dropdown1Items.length > 0 &&
      dropdown1Changed
    ) {
      setDropdown1Items(props.dropdown1Items);
      setDropdown1Selected(props.dropdown1Items[0]);
    }
    if (
      props.dropdown2Items &&
      props.dropdown2Items.length > 0 &&
      dropdown2Changed
    ) {
      setDropdown2Items(props.dropdown2Items);
      setDropdown2Selected(props.dropdown2Items[0]);
    }
    if (
      props.dropdown3Items &&
      props.dropdown3Items.length > 0 &&
      dropdown3Changed
    ) {
      setDropdown3Items(props.dropdown3Items);
      setDropdown3Selected(props.dropdown3Items[0]);
    }
    if (
      props.dropdown4Items &&
      props.dropdown4Items.length > 0 &&
      dropdown4Changed
    ) {
      setDropdown4Items(props.dropdown4Items);
      setDropdown4Selected(props.dropdown4Items[0]);
    }
    setTimeout(() => {
      setQueryEnable(true);
    }, 100);
  }, [
    props.dropdown1Items,
    props.dropdown2Items,
    props.dropdown3Items,
    props.dropdown4Items,
  ]);

  const handleSelectChange = (e, selectName) => {
    if (selectName == "dropdown1") {
      setDropdown1Selected(e);
    } else if (selectName == "dropdown2") {
      setDropdown2Selected(e);
    } else if (selectName == "dropdown3") {
      setDropdown3Selected(e);
    } else if (selectName == "dropdown4") {
      setDropdown4Selected(e);
    }
    if (onFilterChange) {
      let otherDropdownValues = {};
      if (dropdown1Items && dropdown1Selected)
        otherDropdownValues.dropdown1 = dropdown1Selected.value;
      if (dropdown2Items && dropdown2Selected)
        otherDropdownValues.dropdown2 = dropdown2Selected.value;
      if (dropdown3Items && dropdown3Selected)
        otherDropdownValues.dropdown3 = dropdown3Selected.value;
      if (dropdown4Items && dropdown4Selected)
        otherDropdownValues.dropdown4 = dropdown4Selected.value;
      onFilterChange(e, selectName, otherDropdownValues);
    }
  };

  const handleTabChange = (value) => {
    setActiveTab(value);
    chartRef?.current.render();
  };

  return (
    <div
      className={
        "border relative border-gray rounded w-full pt-2 pb-3 " +
        (wrapperClassName ? wrapperClassName : "")
      }
    >
      <div className="pl-5 pr-3 flex flex-wrap">
        <div className="flex flex-row gap-1 items-start grow mt-2">
          <h3 className="text-base text-primary font-semibold">{title}</h3>
          <div
            className="cursor-pointer"
            onClick={() => {
              setModalView(
                <InfoModal title={hintTitle} description={hintDescription} />
              );
              setModalVisibility(true);
              setModalCancelable(true);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <img alt="info icon" className="h-5" src="/images/svg/info.svg" />
          </div>
        </div>
        <div className="order-last sm:order-none md:mr-2 flex gap-2 mt-2">
          {dropdown4Items && dropdown4Items.length != 0 ? (
            <div className="w-32 md:w-36">
              <Select
                options={dropdown4Items}
                menuPlacement="auto"
                menuPosition="fixed"
                styles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: 0,
                    margin: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                  control: (base) => ({
                    ...base,
                    padding: 0,
                    margin: 0,
                    minHeight: 26,
                    cursor: "pointer",
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }),
                  option: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                  }),
                }}
                value={dropdown4Selected}
                onChange={(e) => {
                  handleSelectChange(e, "dropdown4");
                }}
                isClearable={false}
                className="basic-single text-tiny p-0 font-medium z-30"
                classNamePrefix="select"
                isSearchable={false}
              />
            </div>
          ) : (
            <></>
          )}
          {dropdown3Items && dropdown3Items.length != 0 ? (
            <div className="w-32 md:w-36">
              <Select
                options={dropdown3Items}
                menuPlacement="auto"
                menuPosition="fixed"
                styles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: 0,
                    margin: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                  control: (base) => ({
                    ...base,
                    padding: 0,
                    margin: 0,
                    minHeight: 26,
                    cursor: "pointer",
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }),
                  option: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                  }),
                }}
                value={dropdown3Selected}
                onChange={(e) => {
                  handleSelectChange(e, "dropdown3");
                }}
                isClearable={false}
                className="basic-single text-tiny p-0 font-medium z-30"
                classNamePrefix="select"
                isSearchable={false}
              />
            </div>
          ) : (
            <></>
          )}
          {dropdown2Items && dropdown2Items.length != 0 ? (
            <div className="w-32 md:w-36">
              <Select
                options={dropdown2Items}
                menuPlacement="auto"
                menuPosition="fixed"
                defaultValue={"Barranquilla"}
                styles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: 0,
                    margin: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                  control: (base) => ({
                    ...base,
                    padding: 0,
                    margin: 0,
                    minHeight: 26,
                    cursor: "pointer",
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }),
                  option: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                  }),
                }}
                value={dropdown2Selected}
                onChange={(e) => {
                  handleSelectChange(e, "dropdown2");
                }}
                isClearable={false}
                className="basic-single text-tiny p-0 font-medium z-30"
                classNamePrefix="select"
                isSearchable={false}
              />
            </div>
          ) : (
            <></>
          )}
          {dropdown1Items && dropdown1Items.length != 0 ? (
            <div className="w-32 md:w-36">
              <Select
                options={dropdown1Items}
                menuPlacement="auto"
                menuPosition="fixed"
                defaultValue={"Barranquilla"}
                styles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: 0,
                    margin: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                  control: (base) => ({
                    ...base,
                    padding: 0,
                    margin: 0,
                    minHeight: 26,
                    cursor: "pointer",
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }),
                  option: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                  }),
                }}
                value={dropdown1Selected}
                onChange={(e) => {
                  handleSelectChange(e, "dropdown1");
                }}
                isClearable={false}
                className="basic-single text-tiny p-0 font-medium z-30"
                classNamePrefix="select"
                isSearchable={false}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <Menu
          transition={true}
          align="end"
          unmountOnClose={true}
          onItemClick={(e) => {
            download(e.value);
          }}
          menuClassName="border rounded border-gray shadow-sm"
          menuButton={
            <MenuButton>
              <MdFileDownload size={30} />
            </MenuButton>
          }
        >
          <MenuItem
            value="csv"
            className="text-sm cursor-pointer px-2 pt-1 whitespace-nowrap hover:bg-gray"
          >
            Descargar CSV
          </MenuItem>
          <MenuItem
            value="png"
            className="text-sm cursor-pointer px-2 pt-1 whitespace-nowrap hover:bg-gray"
          >
            Descargar PNG
          </MenuItem>
          <MenuItem
            value="svg"
            className="text-sm cursor-pointer px-2 pt-1 pb-1 whitespace-nowrap hover:bg-gray"
          >
            Descargar SVG
          </MenuItem>
        </Menu>
      </div>
      {tabItems ? (
        <div className="w-full flex flex-row justify-center items-center gap-3 mt-2">
          {tabItems.map((item) => (
            <button
              onClick={() => handleTabChange(item.value)}
              key={item.value}
              className={
                "p-1 text-sm font-semibold border rounded w-44" +
                (activeTab == item.value
                  ? " bg-primary text-textColorSecondary border-primary"
                  : " bg-textColorSecondary border-gray text-textColorPrimary")
              }
            >
              {item.label}
            </button>
          ))}
        </div>
      ) : (
        <></>
      )}
      {!CustomChart ? (
        <>
          {EnableBrushChart ? (
            <Chart
              id={serverId + "_brush"}
              series={data?.series ? data.series : []}
              type={brushTargetType}
              className={
                "mx-auto w-100 " + (chartClassName ? chartClassName : "")
              }
              width={"100%"}
              height={height ? height : "auto"}
              options={{
                ...options,
                fill: {},
                yaxis: {
                  ...options.yaxis,
                  ...(YaxisTickAmount && { tickAmount: YaxisTickAmount }),
                },
                chart: {
                  ...options.chart,
                  id: serverId + "_brush",
                  chartType: brushTargetType,
                  toolbar: {
                    autoSelected: "pan",
                    show: false,
                  },
                  brush: {
                    enabled: false,
                    autoScaleYaxis: true,
                  },
                },
              }}
            />
          ) : (
            <></>
          )}
          <Chart
            ref={chartRef}
            options={options}
            series={
              data?.series
                ? tabItems && tabItems.length > 0 && data.series[activeTab]
                  ? data.series[activeTab]
                  : dropdown1Select &&
                    dropdown1Selected?.value &&
                    data.series[dropdown1Selected.value]
                  ? data.series[dropdown1Selected.value]
                  : data.series
                : []
            }
            type={chartType}
            className={
              "mx-auto w-100 " + (chartClassName ? chartClassName : "")
            }
            width={"100%"}
            height={EnableBrushChart ? 170 : height ? height : "auto"}
          />
        </>
      ) : (
        <CustomChart data={data?.series ? data.series : []} />
      )}
      {yearEnabled ? (
        <div className="w-full flex flex-col md:flex-row justify-center items-center mt-3">
          <YearSelector
            wrapperClass="w-96 max-w-full px-5"
            items={years}
            selectedItem={selectedYear}
            onItemSelectionChange={onYearChange}
          />
        </div>
      ) : (
        <></>
      )}
      <p className="flex-1 min-w-fit text-xs text-end pr-5 mt-3">
        {footerText}
      </p>
      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center justify-center w-full h-full z-30"
        style={{
          backgroundColor: "rgba(255,255,255,0.75)",
          display: isLoading || isFetching ? "flex" : "none",
        }}
      >
        <Bars
          height="80"
          width="80"
          color="#005AA9"
          secondaryColor="#B3B9D5"
          wrapperClass=""
          visible={isLoading || isFetching}
        />
      </div>
    </div>
  );
};

export default StyledChart;

