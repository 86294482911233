import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { AiOutlineLeft,AiOutlineRight } from "react-icons/ai";

const YearSelector = (props) => {

  const items = props.items ? props.items : [];
  const selectedItem = props.selectedItem;

  return (
    <div className={props.wrapperClass}>
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        wrapperClassName='w-full' 
        scrollContainerClassName='no_scroll gap-0 pt-1'
        itemClassName=''>
        {
          items.map((item,index) => (   
            <div key={"year_item_"+index} className={"w-fit snap-always snap-start flex flex-col"+(index == 0 ? " items-start" : ( index == items.length -1 ? " items-end" : " items-center"))}>
              <div className='flex flex-row w-full items-center'>
                {
                  index != 0 ?
                    <div className='border-t-2 w-10 md:w-16 border-darkGray'></div>
                    :
                    <div className='w-2'></div>
                }
                <div 
                  role="button"
                  tabIndex={0}
                  onKeyPress={()=>{}}
                  className={"w-3 h-3 border cursor-pointer rounded-full"+( item.value === selectedItem ? " bg-primary border-primary" : " border-darkGray" )}
                  onClick={()=>{
                    props.onItemSelectionChange(index,item);
                  }}
                ></div>
                {
                  index != items.length -1 ? 
                    <div className='border-t-2 w-10 md:w-16 border-darkGray'></div>
                    :
                    <div className='w-2'></div> 
                }
                                  
              </div>
              <span 
                role="button"
                tabIndex={0}
                onKeyPress={()=>{}}
                className='text-tiny cursor-pointer font-medium'
                onClick={()=>{
                  props.onItemSelectionChange(index,item);
                }}>
                {item.label}
              </span>
            </div>
          ))
        }
      </ScrollMenu>
    </div>
  );
};


function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

  return (
    <div className={"flex items-start mr-1 cursor-pointer"} disabled={isFirstItemVisible} onClick={() => scrollPrev("smooth")} role="button" onKeyPress={()=>{}}>
      <AiOutlineLeft className={"text-darkGray"+( isFirstItemVisible ? " hidden" : "" )} size={23} />
    </div>
  );
}
  
function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  
  return (
    <div className={"flex items-start ml-1 cursor-pointer"} disabled={isLastItemVisible} onClick={() => scrollNext("smooth")} role="button" onKeyPress={()=>{}}>
      <AiOutlineRight className={"text-darkGray"+( isLastItemVisible ? " hidden" : "" )} size={23} />
    </div>
  );
}

export default YearSelector;