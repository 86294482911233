import React, { useEffect, useRef } from "react";

// import StyledChart from "../../components/StyledChart";
// import useAuth from "../../hooks/useAuth";

let viz; 

const ServiciosPublicos = () => {
  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/ServiciosPblicos-ProData/ServiciosPblicos?:language=en-US&:sid=&:display_count=n&:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
  );
  // const {
  //   auth: { user },
  // } = useAuth();
  // return (
  //   <div className="w-full pt-5 flex flex-col">
  //     <div className="w-full h-fit">
  //       <iframe
  //         src={`/api-dash/serviciospublicos/${user?.dashChartsToken}`}
  //         title="IPC"
  //         style={{ width: "100%", height: "2000px" }}
  //         scrolling="no"
  //       />
  //     </div>
  //   </div>
  //   // <div className="w-full pt-5 flex flex-col gap-5">
  //   //   <StyledChart
  //   //     serverId="energia"
  //   //     chartType="bar"
  //   //     dropdown1Key="segmento"
  //   //     dropdown1Items={[
  //   //       {value: "Estrato 1", label: "Estrato 1"},
  //   //       {value: "Estrato 2", label: "Estrato 2"},
  //   //       {value: "Estrato 3", label: "Estrato 3"},
  //   //       {value: "Estrato 4", label: "Estrato 4"},
  //   //       {value: "Estrato 5", label: "Estrato 5"},
  //   //       {value: "Estrato 6", label: "Estrato 6"},
  //   //       {value: "Comercial", label: "Comercial"},
  //   //       {value: "Industrial", label: "Industrial"}
  //   //     ]}
  //   //     tabKey="abc"
  //   //     tabItems={[
  //   //       {value: "promedioDeFacturacionTotalSuscriptor", label: "Promedio de Facturación total ($/Suscriptor)"},
  //   //       {value: "valorFacturadoPorKwConsumidoKWh", label: "Valor Facturado por KW consumido ($/kWh)"}
  //   //     ]}
  //   //     title="Energia"
  //   //     hintTitle="Energia"
  //   //     hintDescription="Presenta un histórico de los datos de facturación del servicio de energía eléctrica en las principales ciudades de Colombia. Incluye una comparación de los promedios de facturación por cada suscriptor, así como también un comparativo de las tarifas de facturación por kilovatio consumido."
  //   //     footerText="Fuente: SUI"
  //   //     yearEnabled={true}
  //   //     yearKey="periodo"
  //   //     years={[
  //   //       {
  //   //         label: "Feb/2022",
  //   //         value: "Febrero/2022*"
  //   //       },
  //   //       {
  //   //         label: "Mar/2022",
  //   //         value: "Mar/2022*"
  //   //       },
  //   //       {
  //   //         label: "Abr/2022",
  //   //         value: "Abr/2022*"
  //   //       },
  //   //     ]}
  //   //     transformData={transformData}
  //   //     legendPosition="bottom"
  //   //     legendEnable={false}
  //   //     legendHorizontalAlign="center"
  //   //     dataLabelsEnabled={false}
  //   //     chartClassName="max-w-4xl px-0 md:px-3 pt-3"
  //   //     strokeWidth={2}
  //   //     xAxisLabelVisible={true}
  //   //     yAxisLabelVisible={true}
  //   //     YAxisBorderShow={true}
  //   //     chartColors={["#27388D","#F5DFA1","#FACAAA","#ADCDDE","#97B3E5"]}
  //   //     barDistributed={true}
  //   //     height={300}
  //   //     barColumnWidth={20}
  //   //     YaxisTickAmount={6}
  //   //     yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
  //   //     customTooltip={
  //   //       function({ seriesIndex, dataPointIndex, w}) {
  //   //         var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  //   //         let color = w.config.colors.length < dataPointIndex+1 ? w.config.colors[0] : w.config.colors[dataPointIndex];
  //   //         return "<div class='text-sm w-96 overflow-hidden break-all whitespace-normal'>" +
  //   //         "<div class='px-2 py-1 inline-block w-full bg-darkGray'><h5 class='font-semibold'>"+data.x+"</h5></div>" +
  //   //         "<div class='h-px bg-gray border-0'></div>" +
  //   //         "<span class='px-3 mt-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Promedio de Facturación total ($/Suscriptor):</b> " + (data.promedioDeFacturacionTotalSuscriptor && typeof data.promedioDeFacturacionTotalSuscriptor !== "string" && !isNaN(data.promedioDeFacturacionTotalSuscriptor) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.promedioDeFacturacionTotalSuscriptor) : data.promedioDeFacturacionTotalSuscriptor) + "</span><br/>" +
  //   //         "<span class='px-3 mt-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Valor Facturado por KW consumido ($/kWh):</b> " + (data.valorFacturadoPorKwConsumidoKWh && typeof data.valorFacturadoPorKwConsumidoKWh !== "string" && !isNaN(data.valorFacturadoPorKwConsumidoKWh) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.valorFacturadoPorKwConsumidoKWh) : data.valorFacturadoPorKwConsumidoKWh) + "</span>" +
  //   //         "<span class='px-3 mt-2 mb-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Número de Suscriptores:</b> " + (data.numeroDeSuscriptores && typeof data.numeroDeSuscriptores !== "string" && !isNaN(data.numeroDeSuscriptores) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.numeroDeSuscriptores) : data.numeroDeSuscriptores) + "</span>" +
  //   //         "</div>";
  //   //       }
  //   //     }
  //   //   />
  //   //   <StyledChart
  //   //     serverId="gas"
  //   //     chartType="bar"
  //   //     dropdown1Key="segmento"
  //   //     dropdown1Items={[
  //   //       {value: "Estrato 1", label: "Estrato 1"},
  //   //       {value: "Estrato 2", label: "Estrato 2"},
  //   //       {value: "Estrato 3", label: "Estrato 3"},
  //   //       {value: "Estrato 4", label: "Estrato 4"},
  //   //       {value: "Estrato 5", label: "Estrato 5"},
  //   //       {value: "Estrato 6", label: "Estrato 6"},
  //   //       {value: "Comercial", label: "Comercial"},
  //   //       {value: "Industrial", label: "Industrial"}
  //   //     ]}
  //   //     tabKey="abc"
  //   //     tabItems={[
  //   //       {value: "promedioDeFacturacionTotal", label: "Promedio de Facturación total"},
  //   //       {value: "valorFacturadoPorUnidadDeConsumo", label: "Valor Facturado por unidad de consumo"},
  //   //       {value: "numeroDeSuscriptores", label: "Número de Suscriptores"}
  //   //     ]}
  //   //     title="Gas"
  //   //     hintTitle="Gas"
  //   //     hintDescription="Presenta un histórico de los datos de facturación del servicio de gas en las principales ciudades de Colombia. Incluye una comparación de los promedios de facturación por unidad de consumo, así como también un comparativo del número de suscriptores."
  //   //     footerText="Fuente: SUI"
  //   //     yearEnabled={true}
  //   //     yearKey="periodo"
  //   //     years={[
  //   //       {
  //   //         label: "Mar/2022",
  //   //         value: "Marzo / 2022*"
  //   //       },
  //   //       {
  //   //         label: "Abr/2022",
  //   //         value: "Abr/2022*"
  //   //       },
  //   //       {
  //   //         label: "May/2022",
  //   //         value: "Mayo/2022*"
  //   //       },
  //   //     ]}
  //   //     transformData={transformData2}
  //   //     legendPosition="bottom"
  //   //     legendEnable={false}
  //   //     legendHorizontalAlign="center"
  //   //     dataLabelsEnabled={false}
  //   //     chartClassName="max-w-4xl px-0 md:px-3 pt-3"
  //   //     strokeWidth={2}
  //   //     xAxisLabelVisible={true}
  //   //     yAxisLabelVisible={true}
  //   //     YAxisBorderShow={true}
  //   //     chartColors={["#27388D","#F5DFA1","#FACAAA","#ADCDDE","#97B3E5"]}
  //   //     barDistributed={true}
  //   //     height={300}
  //   //     barColumnWidth={20}
  //   //     YaxisTickAmount={6}
  //   //     yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
  //   //     customTooltip={
  //   //       function({ seriesIndex, dataPointIndex, w}) {
  //   //         var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  //   //         let color = w.config.colors.length < dataPointIndex+1 ? w.config.colors[0] : w.config.colors[dataPointIndex];
  //   //         return "<div class='text-sm w-96 overflow-hidden break-all whitespace-normal'>" +
  //   //         "<div class='px-2 py-1 inline-block w-full bg-darkGray'><h5 class='font-semibold'>"+data.x+"</h5></div>" +
  //   //         "<div class='h-px bg-gray border-0'></div>" +
  //   //         "<span class='px-3 mt-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Promedio de Facturación total:</b> " + (data.promedioDeFacturacionTotal && typeof data.promedioDeFacturacionTotal !== "string" && !isNaN(data.promedioDeFacturacionTotal) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.promedioDeFacturacionTotal) : data.promedioDeFacturacionTotal) + "</span><br/>" +
  //   //         "<span class='px-3 mt-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Valor Facturado por unidad de consumo:</b> " + (data.valorFacturadoPorUnidadDeConsumo && typeof data.valorFacturadoPorUnidadDeConsumo !== "string" && !isNaN(data.valorFacturadoPorUnidadDeConsumo) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.valorFacturadoPorUnidadDeConsumo) : data.valorFacturadoPorUnidadDeConsumo) + "</span>" +
  //   //         "<span class='px-3 mt-2 mb-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Número de Suscriptores:</b> " + (data.numeroDeSuscriptores && typeof data.numeroDeSuscriptores !== "string" && !isNaN(data.numeroDeSuscriptores) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.numeroDeSuscriptores) : data.numeroDeSuscriptores) + "</span>" +
  //   //         "</div>";
  //   //       }
  //   //     }
  //   //   />
  //   //   <StyledChart
  //   //     serverId="acueducto"
  //   //     chartType="bar"
  //   //     dropdown1Key="segmento"
  //   //     dropdown1Items={[
  //   //       {value: "Estrato 1", label: "Estrato 1"},
  //   //       {value: "Estrato 2", label: "Estrato 2"},
  //   //       {value: "Estrato 3", label: "Estrato 3"},
  //   //       {value: "Estrato 4", label: "Estrato 4"},
  //   //       {value: "Estrato 5", label: "Estrato 5"},
  //   //       {value: "Estrato 6", label: "Estrato 6"},
  //   //       {value: "Comercial", label: "Comercial"},
  //   //       {value: "Industrial", label: "Industrial"},
  //   //       {value: "Oficial y Especial", label: "Oficial y Especial"}
  //   //     ]}
  //   //     tabKey="abc"
  //   //     tabItems={[
  //   //       {value: "tarifaCargoFijo", label: "Tarifa Cargo Fijo"},
  //   //       {value: "tarifaConsumoBasico", label: "Tarifa Consumo Básico"}
  //   //     ]}
  //   //     title="Acueducto"
  //   //     hintTitle="Acueducto"
  //   //     hintDescription="Presenta un histórico de los datos de facturación del servicio de acueducto en las principales ciudades de Colombia. Incluye una comparación de las tarifas de cargo fijo y de consumo básico."
  //   //     footerText="Fuente: Probarranquilla, Entidades oficiales prestadoras del servicio"
  //   //     yearEnabled={true}
  //   //     yearKey="periodo"
  //   //     years={[
  //   //       {
  //   //         label: "Mayo/2022",
  //   //         value: "Mayo / 2022*"
  //   //       },
  //   //       {
  //   //         label: "Jun/2022",
  //   //         value: "Jun/2022*"
  //   //       },
  //   //       {
  //   //         label: "Jul/2022",
  //   //         value: "Jul/2022*"
  //   //       },
  //   //     ]}
  //   //     transformData={transformData3}
  //   //     legendPosition="bottom"
  //   //     legendEnable={false}
  //   //     legendHorizontalAlign="center"
  //   //     dataLabelsEnabled={false}
  //   //     chartClassName="max-w-4xl px-0 md:px-3 pt-3"
  //   //     strokeWidth={2}
  //   //     xAxisLabelVisible={true}
  //   //     yAxisLabelVisible={true}
  //   //     YAxisBorderShow={true}
  //   //     chartColors={["#27388D","#F5DFA1","#FACAAA","#ADCDDE","#97B3E5"]}
  //   //     barDistributed={true}
  //   //     height={300}
  //   //     barColumnWidth={20}
  //   //     YaxisTickAmount={6}
  //   //     yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
  //   //     customTooltip={
  //   //       function({ seriesIndex, dataPointIndex, w}) {
  //   //         var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  //   //         let color = w.config.colors.length < dataPointIndex+1 ? w.config.colors[0] : w.config.colors[dataPointIndex];
  //   //         return "<div class='text-sm w-96 overflow-hidden break-all whitespace-normal'>" +
  //   //         "<div class='px-2 py-1 inline-block w-full bg-darkGray'><h5 class='font-semibold'>"+data.x+"</h5></div>" +
  //   //         "<div class='h-px bg-gray border-0'></div>" +
  //   //         "<span class='px-3 mt-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Tarifa Cargo Fijo:</b> " + (data.tarifaCargoFijo && typeof data.tarifaCargoFijo !== "string" && !isNaN(data.tarifaCargoFijo) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.tarifaCargoFijo) : data.tarifaCargoFijo) + "</span><br/>" +
  //   //         "<span class='px-3 mt-2 mb-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Tarifa Consumo Básico:</b> " + (data.tarifaConsumoBasico && typeof data.tarifaConsumoBasico !== "string" && !isNaN(data.tarifaConsumoBasico) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.tarifaConsumoBasico) : data.tarifaConsumoBasico) + "</span>" +
  //   //         "</div>";
  //   //       }
  //   //     }
  //   //   />
  //   //   <StyledChart
  //   //     serverId="alcantarillado"
  //   //     chartType="bar"
  //   //     dropdown1Key="segmento"
  //   //     dropdown1Items={[
  //   //       {value: "Estrato 1", label: "Estrato 1"},
  //   //       {value: "Estrato 2", label: "Estrato 2"},
  //   //       {value: "Estrato 3", label: "Estrato 3"},
  //   //       {value: "Estrato 4", label: "Estrato 4"},
  //   //       {value: "Estrato 5", label: "Estrato 5"},
  //   //       {value: "Estrato 6", label: "Estrato 6"},
  //   //       {value: "Comercial", label: "Comercial"},
  //   //       {value: "Industrial", label: "Industrial"},
  //   //       {value: "Oficial y Especial", label: "Oficial y Especial"}
  //   //     ]}
  //   //     tabKey="abc"
  //   //     tabItems={[
  //   //       {value: "tarifaCargoFijo", label: "Tarifa Cargo Fijo"},
  //   //       {value: "tarifaConsumoBasico", label: "Tarifa Consumo Básico"}
  //   //     ]}
  //   //     title="Alcantarillado"
  //   //     hintTitle="Alcantarillado"
  //   //     hintDescription="Presenta un histórico de los datos de facturación del servicio de alcantarillado en las principales ciudades de Colombia. Incluye una comparación de las tarifas de cargo fijo y de consumo básico."
  //   //     footerText="Fuente: Probarranquilla, Entidades oficiales prestadoras del servicio"
  //   //     yearEnabled={true}
  //   //     yearKey="periodo"
  //   //     years={[
  //   //       {
  //   //         label: "Mayo/2022",
  //   //         value: "Mayo / 2022*"
  //   //       },
  //   //       {
  //   //         label: "Jun/2022",
  //   //         value: "Jun/2022*"
  //   //       },
  //   //       {
  //   //         label: "Jul/2022",
  //   //         value: "Jul/2022*"
  //   //       },
  //   //     ]}
  //   //     transformData={transformData4}
  //   //     legendPosition="bottom"
  //   //     legendEnable={false}
  //   //     legendHorizontalAlign="center"
  //   //     dataLabelsEnabled={false}
  //   //     chartClassName="max-w-4xl px-0 md:px-3 pt-3"
  //   //     strokeWidth={2}
  //   //     xAxisLabelVisible={true}
  //   //     yAxisLabelVisible={true}
  //   //     YAxisBorderShow={true}
  //   //     chartColors={["#27388D","#F5DFA1","#FACAAA","#ADCDDE","#97B3E5"]}
  //   //     barDistributed={true}
  //   //     height={300}
  //   //     barColumnWidth={20}
  //   //     YaxisTickAmount={6}
  //   //     yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
  //   //     customTooltip={
  //   //       function({ seriesIndex, dataPointIndex, w}) {
  //   //         var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  //   //         let color = w.config.colors.length < dataPointIndex+1 ? w.config.colors[0] : w.config.colors[dataPointIndex];
  //   //         return "<div class='text-sm w-96 overflow-hidden break-all whitespace-normal'>" +
  //   //         "<div class='px-2 py-1 inline-block w-full bg-darkGray'><h5 class='font-semibold'>"+data.x+"</h5></div>" +
  //   //         "<div class='h-px bg-gray border-0'></div>" +
  //   //         "<span class='px-3 mt-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Tarifa Cargo Fijo:</b> " + (data.tarifaCargoFijo && typeof data.tarifaCargoFijo !== "string" && !isNaN(data.tarifaCargoFijo) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.tarifaCargoFijo) : data.tarifaCargoFijo) + "</span><br/>" +
  //   //         "<span class='px-3 mt-2 mb-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Tarifa Consumo Básico:</b> " + (data.tarifaConsumoBasico && typeof data.tarifaConsumoBasico !== "string" && !isNaN(data.tarifaConsumoBasico) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.tarifaConsumoBasico) : data.tarifaConsumoBasico) + "</span>" +
  //   //         "</div>";
  //   //       }
  //   //     }
  //   //   />
  //   //   <StyledChart
  //   //     serverId="aseo"
  //   //     chartType="bar"
  //   //     dropdown1Key="segmento"
  //   //     dropdown1Items={[
  //   //       {value: "Estrato 1", label: "Estrato 1"},
  //   //       {value: "Estrato 2", label: "Estrato 2"},
  //   //       {value: "Estrato 3", label: "Estrato 3"},
  //   //       {value: "Estrato 4", label: "Estrato 4"},
  //   //       {value: "Estrato 5", label: "Estrato 5"},
  //   //       {value: "Estrato 6", label: "Estrato 6"},
  //   //       {value: "Comercial", label: "Comercial"},
  //   //       {value: "Industrial", label: "Industrial"},
  //   //       {value: "Oficial y Especial", label: "Oficial y Especial"}
  //   //     ]}
  //   //     tabKey="abc"
  //   //     tabItems={[
  //   //       {value: "tarifaCargoFijo", label: "Tarifa Cargo Fijo"},
  //   //       {value: "tarifaConsumoBasico", label: "Tarifa Consumo Básico"}
  //   //     ]}
  //   //     title="Aseo"
  //   //     hintTitle="Aseo"
  //   //     hintDescription="Presenta un histórico de los datos históricos de facturación del servicio de aseo en las principales ciudades de Colombia. Incluye una comparación de las tarifas de cargo fijo y de consumo básico."
  //   //     footerText="Fuente: Probarranquilla, Entidades oficiales prestadoras del servicio"
  //   //     yearEnabled={true}
  //   //     yearKey="periodo"
  //   //     years={[
  //   //       {
  //   //         label: "Mayo/2022",
  //   //         value: "Mayo / 2022*"
  //   //       },
  //   //       {
  //   //         label: "Jun/2022",
  //   //         value: "Jun/2022*"
  //   //       },
  //   //       {
  //   //         label: "Jul/2022",
  //   //         value: "Jul/2022*"
  //   //       },
  //   //     ]}
  //   //     transformData={transformData5}
  //   //     legendPosition="bottom"
  //   //     legendEnable={false}
  //   //     legendHorizontalAlign="center"
  //   //     dataLabelsEnabled={false}
  //   //     chartClassName="max-w-4xl px-0 md:px-3 pt-3"
  //   //     strokeWidth={2}
  //   //     xAxisLabelVisible={true}
  //   //     yAxisLabelVisible={true}
  //   //     YAxisBorderShow={true}
  //   //     chartColors={["#27388D","#F5DFA1","#FACAAA","#ADCDDE","#97B3E5"]}
  //   //     barDistributed={true}
  //   //     height={300}
  //   //     barColumnWidth={20}
  //   //     YaxisTickAmount={6}
  //   //     yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
  //   //     customTooltip={
  //   //       function({ seriesIndex, dataPointIndex, w}) {
  //   //         var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  //   //         let color = w.config.colors.length < dataPointIndex+1 ? w.config.colors[0] : w.config.colors[dataPointIndex];
  //   //         return "<div class='text-sm w-96 overflow-hidden break-all whitespace-normal'>" +
  //   //         "<div class='px-2 py-1 inline-block w-full bg-darkGray'><h5 class='font-semibold'>"+data.x+"</h5></div>" +
  //   //         "<div class='h-px bg-gray border-0'></div>" +
  //   //         "<span class='px-3 mt-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Tarifa Cargo Fijo:</b> " + (data.tarifaCargoFijo && typeof data.tarifaCargoFijo !== "string" && !isNaN(data.tarifaCargoFijo) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.tarifaCargoFijo) : data.tarifaCargoFijo) + "</span><br/>" +
  //   //         "<span class='px-3 mt-2 mb-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Tarifa Consumo Básico:</b> " + (data.tarifaConsumoBasico && typeof data.tarifaConsumoBasico !== "string" && !isNaN(data.tarifaConsumoBasico) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(data.tarifaConsumoBasico) : data.tarifaConsumoBasico) + "</span>" +
  //   //         "</div>";
  //   //       }
  //   //     }
  //   //   />
  //   // </div>
  // );
};

// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let finalData1 = [];
//     let finalData2 = [];
//     let categories = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ciudad)){
//         categories.push(data[i].ciudad);
//         finalData1.push({
//           x: data[i].ciudad,
//           y: data[i].promedioDeFacturacionTotalSuscriptor,
//           numeroDeSuscriptores: data[i].numeroDeSuscriptores,
//           promedioDeFacturacionTotalSuscriptor: data[i].promedioDeFacturacionTotalSuscriptor,
//           valorFacturadoPorKwConsumidoKWh: data[i].valorFacturadoPorKwConsumidoKWh,
//         });
//         finalData2.push({
//           x: data[i].ciudad,
//           y: data[i].valorFacturadoPorKwConsumidoKWh,
//           numeroDeSuscriptores: data[i].numeroDeSuscriptores,
//           promedioDeFacturacionTotalSuscriptor: data[i].promedioDeFacturacionTotalSuscriptor,
//           valorFacturadoPorKwConsumidoKWh: data[i].valorFacturadoPorKwConsumidoKWh,
//         });
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = {
//       promedioDeFacturacionTotalSuscriptor: [
//         { data: finalData1 }
//       ],
//       valorFacturadoPorKwConsumidoKWh: [
//         { data: finalData2 }
//       ]
//     };
//   }
//   else{
//     dataObj.series = {
//       promedioDeFacturacionTotalSuscriptor: [
//         { data: [] }
//       ],
//       valorFacturadoPorKwConsumidoKWh: [
//         { data: [] }
//       ]
//     };
//   }
//   return dataObj;
// };

// const transformData2 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let finalData1 = [];
//     let finalData2 = [];
//     let finalData3 = [];
//     let categories = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ciudad)){
//         categories.push(data[i].ciudad);
//         finalData1.push({
//           x: data[i].ciudad,
//           y: data[i].promedioDeFacturacionTotal,
//           numeroDeSuscriptores: data[i].numeroDeSuscriptores,
//           promedioDeFacturacionTotal: data[i].promedioDeFacturacionTotal,
//           valorFacturadoPorUnidadDeConsumo: data[i].valorFacturadoPorUnidadDeConsumo,
//         });
//         finalData2.push({
//           x: data[i].ciudad,
//           y: data[i].valorFacturadoPorUnidadDeConsumo,
//           numeroDeSuscriptores: data[i].numeroDeSuscriptores,
//           promedioDeFacturacionTotal: data[i].promedioDeFacturacionTotal,
//           valorFacturadoPorUnidadDeConsumo: data[i].valorFacturadoPorUnidadDeConsumo,
//         });
//         finalData3.push({
//           x: data[i].ciudad,
//           y: data[i].numeroDeSuscriptores,
//           numeroDeSuscriptores: data[i].numeroDeSuscriptores,
//           promedioDeFacturacionTotal: data[i].promedioDeFacturacionTotal,
//           valorFacturadoPorUnidadDeConsumo: data[i].valorFacturadoPorUnidadDeConsumo,
//         });
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = {
//       promedioDeFacturacionTotal: [
//         { data: finalData1 }
//       ],
//       valorFacturadoPorUnidadDeConsumo: [
//         { data: finalData2 }
//       ],
//       numeroDeSuscriptores: [
//         { data: finalData3 }
//       ]
//     };
//   }
//   else{
//     dataObj.series = {
//       promedioDeFacturacionTotal: [
//         { data: [] }
//       ],
//       valorFacturadoPorUnidadDeConsumo: [
//         { data: [] }
//       ],
//       numeroDeSuscriptores: [
//         { data: [] }
//       ]
//     };
//   }
//   return dataObj;
// };

// const transformData3 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let finalData1 = [];
//     let finalData2 = [];
//     let categories = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ciudad)){
//         categories.push(data[i].ciudad);
//         finalData1.push({
//           x: data[i].ciudad,
//           y: data[i].tarifaCargoFijo,
//           tarifaConsumoBasico: data[i].tarifaConsumoBasico,
//           tarifaCargoFijo: data[i].tarifaCargoFijo,
//         });
//         finalData2.push({
//           x: data[i].ciudad,
//           y: data[i].tarifaConsumoBasico,
//           tarifaConsumoBasico: data[i].tarifaConsumoBasico,
//           tarifaCargoFijo: data[i].tarifaCargoFijo,
//         });
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = {
//       tarifaCargoFijo: [
//         { data: finalData1 }
//       ],
//       tarifaConsumoBasico: [
//         { data: finalData2 }
//       ]
//     };
//   }
//   else{
//     dataObj.series = {
//       tarifaCargoFijo: [
//         { data: [] }
//       ],
//       tarifaConsumoBasico: [
//         { data: [] }
//       ]
//     };
//   }
//   return dataObj;
// };

// const transformData4 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let finalData1 = [];
//     let finalData2 = [];
//     let categories = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ciudad)){
//         categories.push(data[i].ciudad);
//         finalData1.push({
//           x: data[i].ciudad,
//           y: data[i].tarifaCargoFijo,
//           tarifaConsumoBasico: data[i].tarifaConsumoBasico,
//           tarifaCargoFijo: data[i].tarifaCargoFijo,
//         });
//         finalData2.push({
//           x: data[i].ciudad,
//           y: data[i].tarifaConsumoBasico,
//           tarifaConsumoBasico: data[i].tarifaConsumoBasico,
//           tarifaCargoFijo: data[i].tarifaCargoFijo,
//         });
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = {
//       tarifaCargoFijo: [
//         { data: finalData1 }
//       ],
//       tarifaConsumoBasico: [
//         { data: finalData2 }
//       ]
//     };
//   }
//   else{
//     dataObj.series = {
//       tarifaCargoFijo: [
//         { data: [] }
//       ],
//       tarifaConsumoBasico: [
//         { data: [] }
//       ]
//     };
//   }
//   return dataObj;
// };

// const transformData5 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let finalData1 = [];
//     let finalData2 = [];
//     let categories = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ciudad)){
//         categories.push(data[i].ciudad);
//         finalData1.push({
//           x: data[i].ciudad,
//           y: data[i].tarifaCargoFijo,
//           tarifaConsumoBasico: data[i].tarifaConsumoBasico,
//           tarifaCargoFijo: data[i].tarifaCargoFijo,
//         });
//         finalData2.push({
//           x: data[i].ciudad,
//           y: data[i].tarifaConsumoBasico,
//           tarifaConsumoBasico: data[i].tarifaConsumoBasico,
//           tarifaCargoFijo: data[i].tarifaCargoFijo,
//         });
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = {
//       tarifaCargoFijo: [
//         { data: finalData1 }
//       ],
//       tarifaConsumoBasico: [
//         { data: finalData2 }
//       ]
//     };
//   }
//   else{
//     dataObj.series = {
//       tarifaCargoFijo: [
//         { data: [] }
//       ],
//       tarifaConsumoBasico: [
//         { data: [] }
//       ]
//     };
//   }
//   return dataObj;
// };

export default ServiciosPublicos;