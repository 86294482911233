import React, { useEffect, useRef } from "react";

// import StyledChart from "../../components/StyledChart";
// import useAuth from "../../hooks/useAuth";

// const currentYear = 2022;
// const yearsList = [];
// for (let i = 2; i <= 10; i++) {
//   yearsList.push({
//     label: currentYear - i,
//     value: currentYear - i,
//   });
// }

// const PibYParticipacionPibNacionalOld = () => {
//   const chartRef1 = useRef();
//   const chartRef2 = useRef();

//   return (
//     <div className="w-full pt-5 flex flex-col gap-5">
//       <StyledChart
//         chartRef={chartRef1}
//         serverId="pib-por-departamentos"
//         dropdown1Key="grupo"
//         dropdown1Items={[
//           { value: "Principales economías", label: "Principales economías" },
//           { value: "Región Caribe", label: "Región Caribe" },
//         ]}
//         chartType="bar"
//         title="PIB por departamentos 2022"
//         hintTitle="PIB por departamentos 2022"
//         hintDescription="Desempeño del valor más reciente del PIB en los principales departamentos del país, comparando la capacidad de producción de los territorios a precios constantes con base al 2015."
//         footerText="Fuente: DANE-Cuentas Nacionales"
//         yearEnabled={false}
//         transformData={transformData}
//         legendPosition="top"
//         legendHorizontalAlign="center"
//         dataLabelsEnabled={false}
//         chartClassName="max-w-5xl px-0 md:px-3 pt-3"
//         strokeWidth={2}
//         strokeColor="transparent"
//         xAxisLabelVisible={true}
//         yAxisLabelVisible={true}
//         barColumnWidth={30}
//         YaxisTickAmount={7}
//         height={400}
//         sortBy="pibMilesDeMillonesCop"
//         sortOrder="desc"
//         yAxisLabelFormatter={(val) => {
//           return val && typeof val !== "string" && !isNaN(val)
//             ? Intl.NumberFormat("es-CO", {
//                 style: "decimal",
//                 minimumFractionDigits: 0,
//                 maximumFractionDigits: 2,
//               }).format(val)
//             : val;
//         }}
//         stacked={true}
//         customTooltip={function ({ seriesIndex, dataPointIndex, w }) {
//           var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
//           let color =
//             w.config.colors.length < dataPointIndex + 1
//               ? w.config.colors[0]
//               : w.config.colors[dataPointIndex];
//           return (
//             "<div class='p-3 text-sm'>" +
//             "<span><div style='background: " +
//             color +
//             "' class='w-5 h-3 inline-block mr-2'></div><b>PIB (Miles de Millones COP):</b> " +
//             (data.y && typeof data.y !== "string" && !isNaN(data.y)
//               ? Intl.NumberFormat("es-CO", {
//                   style: "decimal",
//                   minimumFractionDigits: 0,
//                   maximumFractionDigits: 2,
//                 }).format(data.y)
//               : data.y) +
//             "</span><br/>" +
//             "<span><div style='background: " +
//             color +
//             "' class='w-5 h-3 inline-block mr-2'></div><b>% Participación Nacional:</b> " +
//             (data.percentage &&
//             typeof data.percentage !== "string" &&
//             !isNaN(data.percentage)
//               ? Intl.NumberFormat("es-CO", {
//                   style: "percent",
//                   minimumFractionDigits: 0,
//                   maximumFractionDigits: 2,
//                 }).format(data.percentage / 100)
//               : data.percentage) +
//             "</span>" +
//             "</div>"
//           );
//         }}
//         yAxisTitle="Miles de Millones COP"
//         YAxisBorderShow={true}
//         roundedBar={true}
//         barDistributed={true}
//         chartColors={["#27388D", "#F1D37C", "#8EBAD1", "#F9B68A"]}
//       />
//       <StyledChart
//         chartRef={chartRef2}
//         serverId="valor-agregado-principales-ciudades"
//         chartType="treemap"
//         title="Valor agregado de la Producción: Principales Ciudades"
//         hintTitle="Valor agregado de la Producción: Principales Ciudades"
//         hintDescription="Presenta el desempeño del valor agregado de la producción a lo largo del tiempo de las principales ciudades de Colombia y el porcentaje que representa con respecto a su departamento.
//         Valor agregado de la producción= PIB - Impuestos recaudados.
//         "
//         footerText="Fuente: DANE-Cuentas Nacionales"
//         yearEnabled={true}
//         years={yearsList}
//         yearKey="ano"
//         transformData={transformData2}
//         legendPosition="top"
//         legendHorizontalAlign="center"
//         sortBy="valorAgregadoMilesDeMillonesCop"
//         sortOrder="desc"
//         yAxisTitle="Miles de Millones COP"
//         dataLabelsEnabled={true}
//         chartClassName="max-w-4xl px-0 md:px-3 pt-3"
//         height={400}
//         strokeWidth={4}
//         strokeColor={["#FFFFFF"]}
//         xAxisLabelVisible={true}
//         yAxisLabelVisible={true}
//         treemapEnableShades={false}
//         treemapDistributed={true}
//         chartColors={[
//           "#27388D",
//           "#F1D37C",
//           "#8EBAD1",
//           "#F9B68A",
//           "#F2B566",
//           "#6F96DA",
//           "#97B3E5",
//           "#ACC7F6",
//         ]}
//         customTooltip={function ({ seriesIndex, dataPointIndex, w }) {
//           var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
//           let color =
//             w.config.colors.length < dataPointIndex + 1
//               ? w.config.colors[0]
//               : w.config.colors[dataPointIndex];
//           return (
//             "<div class='p-3 text-sm'>" +
//             "<h5 class='font-semibold mb-2'>" +
//             data.x +
//             "</h5>" +
//             "<span><div style='background: " +
//             color +
//             "' class='w-5 h-3 inline-block mr-2'></div><b>Valor Agregado (Miles de Millones COP):</b> " +
//             (data.y && typeof data.y !== "string" && !isNaN(data.y)
//               ? Intl.NumberFormat("es-CO", {
//                   style: "decimal",
//                   minimumFractionDigits: 0,
//                   maximumFractionDigits: 2,
//                 }).format(data.y)
//               : data.y) +
//             "</span><br/>" +
//             "<span><div style='background: " +
//             color +
//             "' class='w-5 h-3 inline-block mr-2'></div><b>Peso relativo municipal en valor agregado departamental:</b> " +
//             (data.percentage &&
//             typeof data.percentage !== "string" &&
//             !isNaN(data.percentage)
//               ? Intl.NumberFormat("es-CO", {
//                   style: "percent",
//                   minimumFractionDigits: 0,
//                   maximumFractionDigits: 2,
//                 }).format(data.percentage / 100)
//               : data.percentage === null || data.percentage === undefined
//               ? "N.A."
//               : data.percentage) +
//             "</span>" +
//             "</div>"
//           );
//         }}
//       />
//       <StyledChart
//         serverId="valor-agregado-municipal-por-actividades-economicas"
//         dropdown1Key="municipio"
//         dropdown1Items={[
//           { value: "Barranquilla", label: "Barranquilla" },
//           { value: "Bogotá, D.C.", label: "Bogotá, D.C." },
//           { value: "Medellín", label: "Medellín" },
//           { value: "Bucaramanga", label: "Bucaramanga" },
//           { value: "Santa Marta", label: "Santa Marta" },
//           { value: "Cali", label: "Cali" },
//         ]}
//         chartType="treemap"
//         title="Valor Agregado municipal por actividades económicas"
//         hintTitle="Valor Agregado municipal por actividades económicas"
//         hintDescription="Análisis del desempeño del valor agregado municipal de las principales ciudades de Colombia en miles de millones de pesos, desglosado por principales actividades económicas."
//         footerText="Fuente: DANE-Cuentas Nacionales"
//         yearEnabled={true}
//         years={yearsList}
//         yearKey="ano"
//         transformData={transformData3}
//         legendPosition="top"
//         legendHorizontalAlign="center"
//         dataLabelsEnabled={true}
//         chartClassName="max-w-4xl px-0 md:px-3 pt-3"
//         height={400}
//         strokeWidth={4}
//         sortBy="valorAgregadoMilesMillonesCop"
//         sortOrder="desc"
//         strokeColor={["#FFFFFF"]}
//         xAxisLabelVisible={true}
//         yAxisLabelVisible={true}
//         treemapEnableShades={false}
//         treemapDistributed={true}
//         chartColors={["#97B3E5", "#9CABF9", "#758DFF"]}
//       />
//     </div>
//   );
// };

let viz; 

const PibYParticipacionPibNacional = () => {
  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/DesempeoEconmico_17176262394010/DesempeoPIB?:language=es-ES&:sid=&:display_count=n&:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);
  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
  );
};

// const PibYParticipacionPibNacional = () => {
//   const {
//     auth: { user },
//   } = useAuth();

//   return (
//     <div className="w-full pt-5 flex flex-col gap-5">
//       <iframe
//         src={`/api-dash/economia/${user?.dashChartsToken}`}
//         title="Desempeño PIB"
//         style={{ width: "100%", height: "2600px" }}
//         scrolling="no"
//       />
//     </div>
//   );
// };

// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: [],
//   };
//   data = data?.data?.data;
//   if (data && data.length > 0) {
//     let categories = [];
//     let finalData = [];
//     for (let i = 0; i < data.length; i++) {
//       if (!categories.includes(data[i].departamentos)) {
//         categories.push(data[i].departamentos);
//         finalData.push({
//           x: data[i].departamentos,
//           y: data[i].pibMilesDeMillonesCop,
//           percentage: data[i].participacionNacional,
//         });
//       }
//     }
//     if (categories.length > 0) {
//       dataObj.categories = categories;
//     }
//     dataObj.series = [
//       {
//         data: finalData,
//       },
//     ];
//   }
//   return dataObj;
// };

// const transformData2 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: [],
//   };
//   data = data?.data?.data;
//   if (data && data.length > 0) {
//     let categories = [];
//     let finalData = [];
//     for (let i = 0; i < data.length; i++) {
//       if (!categories.includes(data[i].ciudades)) {
//         categories.push(data[i].ciudades);
//         finalData.push({
//           x: data[i].ciudades,
//           y: data[i].valorAgregadoMilesDeMillonesCop,
//           percentage: data[i].pesoRelativoMunicipalEnValorAgregadoDepartamental,
//         });
//       }
//     }
//     if (categories.length > 0) {
//       dataObj.categories = categories;
//     }
//     dataObj.series = [
//       {
//         data: finalData,
//       },
//     ];
//   }
//   return dataObj;
// };

// const transformData3 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: [],
//   };
//   data = data?.data?.data;
//   if (data && data.length > 0) {
//     let seriesData = [];
//     for (let i = 0; i < data.length; i++) {
//       seriesData.push({
//         x: data[i].grandesActividades,
//         y: data[i].valorAgregadoMilesMillonesCop,
//       });
//     }
//     dataObj.series = [
//       {
//         data: seriesData,
//       },
//     ];
//   }
//   return dataObj;
// };

export default PibYParticipacionPibNacional;
