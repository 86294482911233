import React from "react";

import StyledChart from "../../components/StyledChart";

const RutasYFrecuenciasAereas = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="rutas-y-frecuencias-aereas"
        chartType="bar"
        dropdown1Key="tipoDeConectividad"
        dropdown1Items={[
          {value: "Doméstica", label: "Doméstica"},
          {value: "Internacional", label: "Internacional"}
        ]}
        dropdown2Key="aerolinia"
        dropdown2Items={[
          {value: "Avianca", label: "Avianca"},
          {value: "Latam", label: "Latam"},
          {value: "Viva", label: "Viva"},
          {value: "Easyfly", label: "Easyfly"},
          {value: "GCA", label: "GCA"},
          {value: "Wingo", label: "Wingo"},
          {value: "Todas", label: "Todas"},
          {value: "American Airlines", label: "American Airlines"},
          {value: "Copa Airlines", label: "Copa Airlines"},
          {value: "Spirit", label: "Spirit"},
          {value: "EzAir", label: "EzAir"}
        ]}
        title="Rutas y Frecuencias Aéreas desde el Atlántico"
        hintTitle="Rutas y Frecuencias Aéreas desde el Atlántico"
        hintDescription="Indica información sobre las diferentes rutas aéreas disponibles para viajar desde el departamento del Atlántico hacia diferentes destinos, incluyendo la frecuencia de los vuelos. La información también puede incluir las aerolíneas que ofrecen estos vuelos y la duración del viaje."
        footerText="Fuente: "
        transformData={transformData}
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        barColumnHeight={30}
        barColumnWidth={30}
        height={300}
        strokeWidth={2}
        strokeColor={"transparent"}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        horizontalBar={true}
        barDistributed={true}
        chartColors={["#27388D", "#F1D37C", "#8EBAD1", "#F9B68A", "#F2B566", "#6F96DA", "#97B3E5", "#ACC7F6"]}
      />
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let frecuenciaDiaria = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].destino)){
        categories.push(data[i].destino);
        frecuenciaDiaria.push(data[i].frecuenciaDiaria);
      }
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = [
      { name: "Frecuencia diaria", data: frecuenciaDiaria }
    ];
  }
  return dataObj;
};

export default RutasYFrecuenciasAereas;