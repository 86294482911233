import React, { useState } from "react";
import { useQuery } from "react-query";
import Filters from "./Filters";
import axios from "../../api/axiosAuth";
import { Oval } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import DocumentViewModal from "./DocumentViewModal";
import useModal from "../../hooks/useModal";

const Repository = () => {
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [authorFilters, setAuthorFilters] = useState([]);
  const [documentTypeFilters, setDocumentTypeFilters] = useState([]);
  const [fileTypeFilters, setFileTypeFilters] = useState([]);
  const [yearFilters, setYearFilters] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState();

  const [sortBy, setSortBy] = useState("recent");

  const { setModalView, setModalVisibility, setModalCancelable } = useModal();

  const fetchDocumentData = (
    sortBy,
    page,
    categories,
    authors,
    documentTypes,
    fileTypes,
    years
  ) => {
    let sortOrder;
    let sortByName;
    if (sortBy === "recent") {
      sortByName = "publishDate";
      sortOrder = "desc";
    } else if (sortBy === "name_az") {
      sortByName = "name";
      sortOrder = "asc";
    }
    return axios.get("repository", {
      params: {
        pagination: true,
        page: page,
        limit: 10,
        filterQuery: JSON.stringify({
          categories: categories,
          authors: authors,
          documentTypes: documentTypes,
          fileTypes: fileTypes,
          years: years,
        }),
        ...(sortByName && { sortBy: sortByName }),
        ...(sortOrder && { sortOrder }),
      },
    });
  };

  const handleFilterChange = (filterName, filterValue, filterState) => {
    if (filterName === "category") {
      if (filterState) {
        if (!categoryFilters.includes(filterValue)) {
          setCurrentPage(1);
          setCategoryFilters([...categoryFilters, filterValue]);
        }
      } else {
        let indexOfFilterValue = categoryFilters.indexOf(filterValue);
        if (indexOfFilterValue >= 0) {
          setCurrentPage(1);
          setCategoryFilters(
            categoryFilters.filter((item, index) => {
              return index !== indexOfFilterValue;
            })
          );
        }
      }
    } else if (filterName === "author") {
      if (filterState) {
        if (!authorFilters.includes(filterValue)) {
          setCurrentPage(1);
          setAuthorFilters([...authorFilters, filterValue]);
        }
      } else {
        let indexOfFilterValue = authorFilters.indexOf(filterValue);
        if (indexOfFilterValue >= 0) {
          setCurrentPage(1);
          setAuthorFilters(
            authorFilters.filter((item, index) => {
              return index !== indexOfFilterValue;
            })
          );
        }
      }
    } else if (filterName === "documentType") {
      if (filterState) {
        if (!documentTypeFilters.includes(filterValue)) {
          setCurrentPage(1);
          setDocumentTypeFilters([...documentTypeFilters, filterValue]);
        }
      } else {
        let indexOfFilterValue = documentTypeFilters.indexOf(filterValue);
        if (indexOfFilterValue >= 0) {
          setCurrentPage(1);
          setDocumentTypeFilters(
            documentTypeFilters.filter((item, index) => {
              return index !== indexOfFilterValue;
            })
          );
        }
      }
    } else if (filterName === "fileType") {
      if (filterState) {
        if (!fileTypeFilters.includes(filterValue)) {
          setCurrentPage(1);
          setFileTypeFilters([...fileTypeFilters, filterValue]);
        }
      } else {
        let indexOfFilterValue = fileTypeFilters.indexOf(filterValue);
        if (indexOfFilterValue >= 0) {
          setCurrentPage(1);
          setFileTypeFilters(
            fileTypeFilters.filter((item, index) => {
              return index !== indexOfFilterValue;
            })
          );
        }
      }
    } else if (filterName === "year") {
      if (filterState) {
        if (!yearFilters.includes(filterValue)) {
          setCurrentPage(1);
          setYearFilters([...yearFilters, filterValue]);
        }
      } else {
        let indexOfFilterValue = yearFilters.indexOf(filterValue);
        if (indexOfFilterValue >= 0) {
          setCurrentPage(1);
          setYearFilters(
            yearFilters.filter((item, index) => {
              return index !== indexOfFilterValue;
            })
          );
        }
      }
    }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const {
    isLoading: isDocumentsLoading,
    isFetching: isDocumentsFetching,
    data: documents,
  } = useQuery(
    [
      "repository-documents",
      sortBy,
      currentPage,
      ...categoryFilters,
      ...authorFilters,
      ...documentTypeFilters,
      ...fileTypeFilters,
      ...yearFilters,
    ],
    () =>
      fetchDocumentData(
        sortBy,
        currentPage,
        categoryFilters,
        authorFilters,
        documentTypeFilters,
        fileTypeFilters,
        yearFilters
      ),
    {
      keepPreviousData: true,
      select: (data) => {
        if (
          data?.data &&
          data?.data?.paginationDetails &&
          data?.data?.paginationDetails?.totalDocs !== totalDocs
        ) {
          setTotalDocs(data?.data?.paginationDetails.totalDocs);
        }
        if (
          data?.data &&
          data?.data?.paginationDetails &&
          data?.data?.paginationDetails?.totalPages !== pageCount
        ) {
          console.log("[totalPages]", data?.data?.paginationDetails.totalPages);
          setPageCount(data?.data?.paginationDetails.totalPages);
        }
        return data?.data?.data;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 900000,
      staleTime: 900000,
    }
  );

  return (
    <div className="flex flex-row w-100 h-full overflow-hidden">
      <div className="h-full w-72 px-7 overflow-y-scroll pt-7 pb-5 bg-lightGray">
        <Filters onFilterChanged={handleFilterChange} />
      </div>
      <div className="grow h-full bg-lightGray pt-3 md:pt-7 pr-0 md:pr-5 w-1/2 overflow-y-scroll pb-6">
        <div className="flex justify-between">
          <div>
            <h1 className="font-semibold text-lg text-black">
              Mostrando {totalDocs} documentos
            </h1>
          </div>
          <div className="filters">
            <label
              htmlFor="repository_sort_by"
              className="text-tiny font-medium"
            >
              Ordenar por:
            </label>
            <select
              id="repository_sort_by"
              value={sortBy}
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
              className="bg-transparent text-tiny text-black outline-none w-fit"
            >
              <option value="recent">Publicación más reciente</option>
              <option value="name_az">Nombre A-Z</option>
            </select>
          </div>
        </div>
        <div className="flex w-full flex-wrap pt-5 gap-24 px-auto">
          {isDocumentsFetching || isDocumentsLoading ? (
            <Oval
              height="60"
              width="60"
              radius="3"
              ariaLabel="three-dots-loading"
              wrapperClass="mx-auto mt-24"
              color="#005AA9"
              secondaryColor="#B3B9D5"
              visible={true}
            />
          ) : (
            <>
              {documents?.map((item, index) => {
                return (
                  <div key={index} className="flex flex-col w-60 gap-3">
                    <button
                      className="w-full"
                      onClick={() => {
                        setModalView(
                          <DocumentViewModal
                            data={item}
                            modalClose={() => {
                              setModalVisibility(false);
                              setModalCancelable(true);
                            }}
                          />
                        );
                        setModalVisibility(true);
                        setModalCancelable(false);
                      }}
                    >
                      <img
                        src={axios.defaults.baseURL + "/" + item.thumbnail}
                        className="w-full aspect-[2/3]"
                        alt="thumbnail"
                      />
                    </button>
                    <h3 className="text-tiny text-black text-medium">
                      {item.name}
                    </h3>
                    {item.fileType !== "link" ? (
                      <a
                        href={axios.defaults.baseURL + "/" + item.file}
                        className="bg-primary rounded-xl text-textColorSecondary text-semibold px-5 py-2 w-fit"
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        Descargar
                      </a>
                    ) : (
                      <a
                        href={item.action_link}
                        className="bg-primary rounded-xl text-textColorSecondary text-semibold px-5 py-2 w-fit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link
                      </a>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
        {(!documents || documents.length == 0) &&
        !(isDocumentsFetching || isDocumentsLoading) ? (
          <div className="w-full flex justify-center items-start mt-16">
            Informacion no disponible
          </div>
        ) : (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            containerClassName="flex gap-5 justify-center mt-5"
            pageLinkClassName="p-2"
            activeClassName="bg-primary text-textColorSecondary"
            onPageChange={handlePageClick}
            pageRangeDisplayed={10}
            pageCount={pageCount}
            forcePage={currentPage - 1}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </div>
  );
};

export default Repository;
