import React from "react";
import axios from "../api/axios";

const MenuContext = React.createContext(null);

export const useMenuContext = () => React.useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const [menus, setMenus] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("indicadores-y-categorias", {
        params: {
          pagination: false,
        },
      })
      .then((res) => res.data.data)
      .then((res) => {
        setMenus(res);
      });
  }, []);

  return (
    <MenuContext.Provider value={{ menus }}>{children}</MenuContext.Provider>
  );
};
