import React, { useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";

// import StyledChart from "../../components/StyledChart";

let viz; 

const Ipc = () => {

  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/IPC-ProData/IPC-ProData?:language=en-US&:sid=&:display_count=n&:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
  );
  // const {
  //   auth: { user },
  // } = useAuth();
  // return (
  //   <div className="w-full pt-5 flex flex-col">
  //     <div className="w-full h-fit">
  //       <iframe
  //         src={`/api-dash/ipc/${user?.dashChartsToken}`}
  //         title="IPC"
  //         style={{ width: "100%", height: "2600px" }}
  //         scrolling="no"
  //       />
  //     </div>
  //   </div>
  //   // <div className="w-full pt-5 flex flex-col gap-5">
  //   //   <StyledChart
  //   //     serverId="ipc-indices-de-precio-al-consumidor"
  //   //     chartType="area"
  //   //     dropdown1Key="grupo"
  //   //     dropdown1Items={[
  //   //       {value: "Bienes Y Servicios Diversos", label: "Bienes Y Servicios Diversos"},
  //   //       {value: "Alimentos Y Bebidas No Alcoholicas", label: "Alimentos Y Bebidas No Alcoholicas"},
  //   //       {value: "Bebidas Alcoholicas Y Tabaco", label: "Bebidas Alcoholicas Y Tabaco"},
  //   //       {value: "Prendas De Vestir Y Calzado", label: "Prendas De Vestir Y Calzado"},
  //   //       {value: "Alojamiento Agua Electricidad Gas Y Otros Combustibles", label: "Alojamiento Agua Electricidad Gas Y Otros Combustibles"},
  //   //       {value: "Muebles Articulos Para El Hogar Y Para La Conservacion Ordinaria Del Hogar", label: "Muebles Articulos Para El Hogar Y Para La Conservacion Ordinaria Del Hogar"},
  //   //       {value: "Salud", label: "Salud"},
  //   //       {value: "Transporte", label: "Transporte"},
  //   //       {value: "Informacion Y Comunicacion", label: "Informacion Y Comunicacion"},
  //   //       {value: "Recreacion Y Cultura", label: "Recreacion Y Cultura"},
  //   //       {value: "Educacion", label: "Educacion"},
  //   //       {value: "Restaurantes Y Hoteles", label: "Restaurantes Y Hoteles"},
  //   //       {value: "", label: "Todos"}
  //   //     ]}
  //   //     dropdown2Key="ciudades"
  //   //     dropdown2Items={[
  //   //       {value: "Barranquilla", label: "Barranquilla"},
  //   //       {value: "Nacional", label: "Nacional"},
  //   //       {value: "Medellin", label: "Medellin"},
  //   //       {value: "Bogota", label: "Bogota"},
  //   //       {value: "Cartagena", label: "Cartagena"},
  //   //       {value: "Tunja", label: "Tunja"},
  //   //       {value: "Manizales", label: "Manizales"},
  //   //       {value: "Florencia", label: "Florencia"},
  //   //       {value: "Popayan", label: "Popayan"},
  //   //       {value: "Monteria", label: "Monteria"},
  //   //       {value: "Neiva", label: "Neiva"},
  //   //       {value: "Riohacha", label: "Riohacha"},
  //   //       {value: "Santa Marta", label: "Santa Marta"},
  //   //       {value: "Villavicencio", label: "Villavicencio"},
  //   //       {value: "Pasto", label: "Pasto"},
  //   //       {value: "Cucuta", label: "Cucuta"},
  //   //       {value: "Armenia", label: "Armenia"},
  //   //       {value: "Pereira", label: "Pereira"},
  //   //       {value: "Bucaramanga", label: "Bucaramanga"},
  //   //       {value: "Sincelejo", label: "Sincelejo"},
  //   //       {value: "Ibague", label: "Ibague"},
  //   //       {value: "Cali", label: "Cali"},
  //   //       {value: "Otras areas urbanas", label: "Otras areas urbanas"},
  //   //     ]}
  //   //     title="IPC Indice de precios al consumidor"
  //   //     hintTitle="IPC Indice de precios al consumidor"
  //   //     hintDescription="Evaluación del comportamiento del Índice de Precios al Consumidor (IPC) en las principales ciudades de Colombia, mostrando las tendencias de inflación y los cambios en los precios de los bienes y servicios según las divisiones de gasto."
  //   //     footerText="Fuente: DANE-IPC"
  //   //     yearEnabled={false}
  //   //     sortBy="periodo"
  //   //     sortOrder="asc"
  //   //     transformData={transformData}
  //   //     legendPosition="bottom"
  //   //     legendHorizontalAlign="center"
  //   //     dataLabelsEnabled={false}
  //   //     chartClassName="max-w-5xl px-0 md:px-3 pt-3"
  //   //     strokeWidth={2}
  //   //     xAxisLabelVisible={true}
  //   //     yAxisLabelVisible={true}
  //   //     YAxisBorderShow={true}
  //   //     XAxisType="datetime"
  //   //     fill={{
  //   //       type: "gradient",
  //   //       gradient: {
  //   //         opacityFrom: 0.91,
  //   //         opacityTo: 0.1,
  //   //       } 
  //   //     }}
        
  //   //     YaxisTickAmount={5}
  //   //     EnableBrushChart={true}
  //   //     chartColors={["#27388D"]}
  //   //     brushTargetType={"line"}
  //   //     height={250}
  //   //   />
  //   // </div>
  // );
};
// function generateDayWiseTimeSeries(baseval, count, yrange) {
//   var i = 0;
//   var series = [];
//   while (i < count) {
//     var x = baseval;
//     var y =
//       Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

//     series.push([x, y]);
//     baseval += 2.592e+9;
//     i++;
//   }
//   return series;
// }

// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   let ciudades; 
//   if(data && data.length > 0) {
//     let finalData = [];
//     for(let i=0;i<data.length;i++){
//       finalData.push({
//         x: new Date(data[i].periodo),
//         y: data[i].cambioMensual,
//       });
//       ciudades = data[i].ciudades;
//     }
//     finalData.sort((a,b)=> a.x - b.x);
//     let filteredData = [];
//     let prevMonth = finalData[0].x.getUTCMonth() + 1;
//     let prevYear = finalData[0].x.getUTCFullYear();
//     let prevY = finalData[0].y;
//     for(let i=1;i<finalData.length;i++){
//       if(prevMonth === (finalData[i].x.getUTCMonth() + 1) && prevYear === (finalData[i].x.getUTCFullYear())){
//         prevY = (prevY + finalData[i].y) / 2;
//       } else{
//         filteredData.push({
//           x: new Date(prevYear,prevMonth-1,2),
//           y: prevY,
//         });
//         if(
//           !(prevMonth !== 12 && (prevMonth + 1) === (finalData[i].x.getUTCMonth() + 1) && prevYear === finalData[i].x.getUTCFullYear())
//           ||
//           !(prevMonth === 12 && 1 === (finalData[i].x.getUTCMonth() + 1) && (prevYear + 1) === finalData[i].x.getUTCFullYear())) {
//           let monthDifference = (finalData[i].x.getUTCMonth() + 1) - prevMonth;
//           let yearDifference = (finalData[i].x.getUTCFullYear()) - prevYear;
//           let emtyMonthCount = (monthDifference + yearDifference * 12) - 1;
//           let monthCounter = prevMonth;
//           let yearCounter = prevYear;
//           for(let k=0;k<emtyMonthCount;k++){
//             if(monthCounter === 12){
//               monthCounter = 1;
//               yearCounter += 1;
//             }
//             else{
//               monthCounter++;
//             }
//             filteredData.push({
//               x: new Date(yearCounter,monthCounter-1,2),
//               y: null,
//             });
//           }
//         }
//         prevY = finalData[i].y;
//         prevMonth = finalData[i].x.getUTCMonth() + 1;
//         prevYear = finalData[i].x.getUTCFullYear();
//       }
//     }
//     filteredData.push({
//       x: new Date(prevYear,prevMonth-1,2),
//       y: prevY,
//     });
//     dataObj.series = [{
//       name: ciudades,
//       data: filteredData
//     }];
//   }
//   return dataObj;
// };

export default Ipc;
