import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import PremiumAcessRequire from "../screens/PremiumAcessRequire";
import useAuth from "../hooks/useAuth";

const RequirePremium = (props) => {
  const { auth } = useAuth();
  const location = useLocation();
  
  
  return (
    auth?.user && (!props.userRole || auth.user.role === props.userRole)
      ? 
      <>
        {
          auth.user.emailVerified ? 
            <>
              {
                auth.user.subscription === "PREMIUM" ?
                  <Outlet />
                  :
                  <PremiumAcessRequire />
              }
            </>
            :
            <Navigate to="/auth/email_verify" state={{ from: location }} replace />
        }
      </>
      :
      <Navigate to="/auth" state={{ from: location }} replace />
  );
};

export default RequirePremium;