import React,{ useRef } from "react";

import StyledChart from "../../components/StyledChart";

const PobrezaMultidimensional = () => {

  const chartRef = useRef();
  
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        sharedTooltip={true}
        chartRef={chartRef}
        serverId="pobreza-multidimensional-departamentos"
        chartType="bar"
        title="Pobreza multidimensional: Principales Departamentos"
        hintTitle="Pobreza multidimensional: Principales Departamentos"
        hintDescription="Muestra información sobre el nivel de pobreza multidimensional, que incluye aspectos como la educación, la salud, la vivienda y la calidad del trabajo, en los principales departamentos de Colombia, permitiendo conocer la situación social de la población y la necesidad de políticas públicas para mejorar su calidad de vida."
        footerText="Fuente: DANE - GEIH"
        yearEnabled={false}
        transformData={transformData}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-4xl px-0 md:px-3 pt-3"
        strokeWidth={[2,2,2,2,2,2,4,4]}
        strokeColor={["transparent","transparent","transparent","transparent","transparent","transparent","#97B3E5","#ACC7F6"]}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val/100) : val; }}
        chartColors={["#27388D","#F1D37C","#F9B68A","#8EBAD1","#F19A7C","#6BA566","#97B3E5","#ACC7F6"]}
        legendEnable={true}
        barColumnWidth={"55%"}/>
    </div>
  );
};


const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let antioquia = [];
    let atlantico = [];
    let bogota = [];
    let nacional = [];
    let regionCaribe = [];
    let santander = [];
    let valleDelCauca = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
        antioquia.push(data[i].antioquia);
        atlantico.push(data[i].atlantico);
        bogota.push(data[i].bogota);
        nacional.push(data[i].nacional);
        regionCaribe.push(data[i].regionCaribe);
        santander.push(data[i].santander);
        valleDelCauca.push(data[i].valleDelCauca);
      }
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Atlántico",
        data: atlantico,
        type: "bar",
      },
      {
        name: "Bogotá",
        data: bogota,
        type: "bar"
      },
      {
        name: "Antioquia",
        data: antioquia,
        type: "bar"
      },
      {
        name: "Valle del Cauca",
        data: valleDelCauca,
        type: "bar"
      },
      {
        name: "Santander",
        data: santander,
        type: "bar"
      },
      {
        name: "Región Caribe",
        data: regionCaribe,
        type: "bar"
      },
      {
        name: "Nacional",
        data: nacional,
        type: "line"
      }
    ];
  }
  return dataObj;
};

export default PobrezaMultidimensional;