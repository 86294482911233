import React,{ useRef, useState } from "react";

// import StyledChart from "../../components/StyledChart";
// import StyledTreemap from "../../components/StyledTreemap";
// import useAuth from "../../hooks/useAuth";

// const currentYear = 2022;
// const yearsList = [];
// for(let i=0;i<=19;i++){
//   yearsList.push({
//     label: currentYear-i-2,
//     value: currentYear-i-2
//   });
// }

let viz;
const EgresadosAnualesPorAreaAcademica = () => {
  
    const exportaRef = React.useRef();
  
    React.useEffect(()=>{
      if(viz){
        viz.dispose();
      }
      const vizUrl = "https://public.tableau.com/views/Egresados-ProData/Egresados?:language=es-ES&:sid=&:display_count=n&:origin=viz_share_link";
      viz = new window.tableau.Viz(exportaRef.current,vizUrl);
    },[]);



  // const {auth: {user}} = useAuth()

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
    // <div className="w-full pt-5 flex flex-col gap-5">
    //    <iframe
    //     src={`/api-dash/egresados/${user?.dashChartsToken}`}
    //     title="Egresados por áreas de conocimiento y NBC: Departamentos"
    //     style={{ width: "100%", height: "2600px" }}
    //     scrolling="no"
    //   />
    // </div>
  )
}

// const EgresadosAnualesPorAreaAcademicaOld = () => {

//   const chartRef = useRef();

//   const [ dropdown1Items, setDropdown1Items ] = useState([
//     {value: "AGRONOMIA", label: "AGRONOMIA"},
//     {value: "MEDICINA VETERINARIA", label: "MEDICINA VETERINARIA"},
//     {value: "ZOOTECNIA", label: "ZOOTECNIA"},
//     {value: "", label: "TODOS"}
//   ]);
  
//   return (
//     <div className="w-full pt-5 flex flex-col gap-5">
//       <StyledChart
//         chartRef={chartRef}
//         serverId="egresados-por-area-de-conocimiento-y-nbc-departamentos"
//         dropdown1Key="nucleoBasicoDelConocimientoNbc"
//         dropdown1Items={dropdown1Items}
//         dropdown2Key="areaDeConocimiento"
//         dropdown2Items={[
//           {value: "AGRONOMIA, VETERINARIA Y AFINES", label: "AGRONOMIA, VETERINARIA Y AFINES"},
//           {value: "BELLAS ARTES", label: "BELLAS ARTES"},
//           {value: "CIENCIAS DE LA EDUCACION", label: "CIENCIAS DE LA EDUCACION"},
//           {value: "CIENCIAS DE LA SALUD", label: "CIENCIAS DE LA SALUD"},
//           {value: "CIENCIAS SOCIALES Y HUMANAS", label: "CIENCIAS SOCIALES Y HUMANAS"},
//           {value: "ECONOMIA, ADMINISTRACION, CONTADURIA Y AFINES", label: "ECONOMIA, ADMINISTRACION, CONTADURIA Y AFINES"},
//           {value: "INGENIERIA, ARQUITECTURA, URBANISMO Y AFINES", label: "INGENIERIA, ARQUITECTURA, URBANISMO Y AFINES"},
//           {value: "MATEMATICAS Y CIENCIAS NATURALES", label: "MATEMATICAS Y CIENCIAS NATURALES"},
//           {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//           {value: "", label: "TODOS"}
//         ]}
//         chartType="line"
//         title="Egresados por áreas de conocimiento y NBC: Departamentos"
//         hintTitle="Egresados por áreas de conocimiento y NBC: Departamentos"
//         hintDescription="Muestra la cantidad de egresados de la educación superior en Colombia a lo largo del tiempo, clasificados por áreas de conocimiento y el Núcleos Básicos de Conocimiento al que pertenecen."
//         footerText="Fuente: SNIES"
//         yearEnabled={false}
//         XAxisType="numeric"
//         transformData={transformData}
//         legendPosition="bottom"
//         dataLabelsEnabled={false}
//         chartClassName="max-w-5xl px-1 md:px-3 pt-3"
//         height={450}
//         strokeWidth={4}
//         xAxisLabelVisible={true}
//         yAxisLabelVisible={true}
//         yAxisLabelFormatter={(val)=>{ return val; }}
//         YAxisBorderShow={true}
//         chartColors={["#27388D","#F5DFA1","#F5B6A0", "#97B3E5","#ACC7F6","#ADCDDE","#758DFF","#FACAAA","#8094F5", "#B8CAE9","#8AABE3"]}
//         legendEnable={true}
//         xAxisLabelFormatter={
//           function (val) {
//             return val.toFixed(0);
//           }
//         }
//         gridPaddingRight={20}
//         onFilterChange={(e,selectName)=>{
//           chartRef?.current?.chart?.updateSeries([],false);
//           if(selectName === "dropdown2"){
//             if(e.value==="AGRONOMIA, VETERINARIA Y AFINES"){
//               setDropdown1Items([
//                 {value: "AGRONOMIA", label: "AGRONOMIA"},
//                 {value: "MEDICINA VETERINARIA", label: "MEDICINA VETERINARIA"},
//                 {value: "ZOOTECNIA", label: "ZOOTECNIA"},
//                 {value: "", label: "TODOS"}
//               ]);
//             } else if(e.value==="BELLAS ARTES"){
//               setDropdown1Items([
//                 {value: "ARTES PLASTICAS, VISUALES Y AFINES", label: "ARTES PLASTICAS, VISUALES Y AFINES"},
//                 {value: "ARTES REPRESENTATIVAS", label: "ARTES REPRESENTATIVAS"},
//                 {value: "DISEÑO", label: "DISEÑO"},
//                 {value: "MUSICA", label: "MUSICA"},
//                 {value: "OTROS PROGRAMAS ASOCIADOS A BELLAS ARTES", label: "OTROS PROGRAMAS ASOCIADOS A BELLAS ARTES"},
//                 {value: "PUBLICIDAD Y AFINES", label: "PUBLICIDAD Y AFINES"},
//                 {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//                 {value: "", label: "TODOS"}
//               ]);
//             }  else if(e.value==="CIENCIAS DE LA EDUCACION"){
//               setDropdown1Items([
//                 {value: "EDUCACION", label: "EDUCACION"},
//                 {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//                 {value: "", label: "TODOS"}
//               ]);
//             }  else if(e.value==="CIENCIAS DE LA SALUD"){
//               setDropdown1Items([
//                 {value: "BACTERIOLOGIA", label: "BACTERIOLOGIA"},
//                 {value: "ENFERMERIA", label: "ENFERMERIA"},
//                 {value: "INSTRUMENTACION QUIRURGICA", label: "INSTRUMENTACION QUIRURGICA"},
//                 {value: "MEDICINA", label: "MEDICINA"},
//                 {value: "NUTRICION Y DIETETICA", label: "NUTRICION Y DIETETICA"},
//                 {value: "ODONTOLOGIA", label: "ODONTOLOGIA"},
//                 {value: "OPTOMETRIA, OTROS PROGRAMAS DE CIENCIAS DE LA SALUD", label: "OPTOMETRIA, OTROS PROGRAMAS DE CIENCIAS DE LA SALUD"},
//                 {value: "SALUD PUBLICA", label: "SALUD PUBLICA"},
//                 {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//                 {value: "TERAPIAS", label: "TERAPIAS"},
//                 {value: "", label: "TODOS"}
//               ]);
//             }  else if(e.value==="CIENCIAS SOCIALES Y HUMANAS"){
//               setDropdown1Items([
//                 {value: "ANTROPOLOGIA Y ARTES LIBERALES", label: "ANTROPOLOGIA Y ARTES LIBERALES"},
//                 {value: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS", label: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS"},
//                 {value: "CIENCIA POLITICA, RELACIONES INTERNACIONALES", label: "CIENCIA POLITICA, RELACIONES INTERNACIONALES"},
//                 {value: "COMUNICACION SOCIAL, PERIODISMO Y AFINES", label: "COMUNICACION SOCIAL, PERIODISMO Y AFINES"},
//                 {value: "DEPORTES, EDUCACION FISICA Y RECREACION", label: "DEPORTES, EDUCACION FISICA Y RECREACION"},
//                 {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
//                 {value: "FILOSOFIA, TEOLOGIA Y AFINES", label: "FILOSOFIA, TEOLOGIA Y AFINES"},
//                 {value: "FORMACION RELACIONADA CON EL CAMPO MILITAR O POLICIAL", label: "FORMACION RELACIONADA CON EL CAMPO MILITAR O POLICIAL"},
//                 {value: "GEOGRAFIA, HISTORIA", label: "GEOGRAFIA, HISTORIA"},
//                 {value: "LENGUAS MODERNAS, LITERATURA, LINGUISTICA Y AFINES", label: "LENGUAS MODERNAS, LITERATURA, LINGUISTICA Y AFINES"},
//                 {value: "PSICOLOGIA", label: "PSICOLOGIA"},
//                 {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//                 {value: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES", label: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES"},
//                 {value: "", label: "TODOS"}
//               ]);
//             }  else if(e.value==="ECONOMIA, ADMINISTRACION, CONTADURIA Y AFINES"){
//               setDropdown1Items([
//                 {value: "ADMINISTRACION", label: "ADMINISTRACION"},
//                 {value: "CONTADURIA PUBLICA", label: "CONTADURIA PUBLICA"},
//                 {value: "ECONOMIA", label: "ECONOMIA"},
//                 {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//                 {value: "", label: "TODOS"}
//               ]);
//             }  else if(e.value==="INGENIERIA, ARQUITECTURA, URBANISMO Y AFINES"){
//               setDropdown1Items([
//                 {value: "ARQUITECTURA", label: "ARQUITECTURA"},
//                 {value: "INGENIERIA ADMINISTRATIVA Y AFINES", label: "INGENIERIA ADMINISTRATIVA Y AFINES"},
//                 {value: "INGENIERIA AGRICOLA, FORESTAL Y AFINES", label: "INGENIERIA AGRICOLA, FORESTAL Y AFINES"},
//                 {value: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES", label: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES"},
//                 {value: "INGENIERIA AGRONOMICA, PECUARIA Y AFINES", label: "INGENIERIA AGRONOMICA, PECUARIA Y AFINES"},
//                 {value: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES", label: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES"},
//                 {value: "INGENIERIA BIOMEDICA Y AFINES", label: "INGENIERIA BIOMEDICA Y AFINES"},
//                 {value: "INGENIERIA CIVIL Y AFINES", label: "INGENIERIA CIVIL Y AFINES"},
//                 {value: "INGENIERIA DE MINAS, METALURGIA Y AFINES", label: "INGENIERIA DE MINAS, METALURGIA Y AFINES"},
//                 {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
//                 {value: "INGENIERIA ELECTRICA Y AFINES", label: "INGENIERIA ELECTRICA Y AFINES"},
//                 {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
//                 {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
//                 {value: "INGENIERIA QUIMICA Y AFINES", label: "INGENIERIA QUIMICA Y AFINES"},
//                 {value: "OTRAS INGENIERIAS", label: "OTRAS INGENIERIAS"},
//                 {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//                 {value: "", label: "TODOS"},
//               ]);
//             }  else if(e.value==="MATEMATICAS Y CIENCIAS NATURALES"){
//               setDropdown1Items([
//                 {value: "BIOLOGIA, MICROBIOLOGIA Y AFINES", label: "BIOLOGIA, MICROBIOLOGIA Y AFINES"},
//                 {value: "FISICA", label: "FISICA"},
//                 {value: "GEOLOGIA, OTROS PROGRAMAS DE CIENCIAS NATURALES", label: "GEOLOGIA, OTROS PROGRAMAS DE CIENCIAS NATURALES"},
//                 {value: "MATEMATICAS, ESTADISTICA Y AFINES", label: "MATEMATICAS, ESTADISTICA Y AFINES"},
//                 {value: "QUIMICA Y AFINES", label: "QUIMICA Y AFINES"},
//                 {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//                 {value: "", label: "TODOS"}
//               ]);
//             }  else if(e.value==="SIN CLASIFICAR"){
//               setDropdown1Items([
//                 {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
//                 {value: "", label: "TODOS"}
//               ]);
//             } else {
//               setDropdown1Items([
//                 {value: "", label: "TODOS"}
//               ]);
//             }
//           }
//         }}
//         onDataLoaded={(data)=>{
//           if(data?.series && data.series.length > 1){
//             for(let i=0;i<data.series.length;i++){
//               if(data.series[i]?.name !== "ATLANTICO"){
//                 chartRef?.current?.chart?.hideSeries(data.series[i].name);
//               }
//               else{
//                 chartRef?.current?.chart?.showSeries(data.series[i].name);
//               }
//             }
//           }
//         }}/>
//       <StyledTreemap 
//         serverId="egresados-del-atlantico-por-areas-de-conocimiento-y-nbc"
//         title="Egresados del Atlántico por área de conocimiento y NBC"
//         hintTitle="Egresados del Atlántico por área de conocimiento y NBC"
//         hintDescription="Presenta el Número de egresados en educación superior en el departamento del Atlántico, clasificados por áreas de conocimiento y el Núcleo Básico de Conocimiento al que pertenecen."
//         footerText="Fuente: SNIES"
//         yearEnabled={true}
//         yearKey="ano"
//         years={yearsList}
//         transformData={transformData2}
//         chartColors={["#27388D", "#F1D37C", "#97B3E5", "#F5B6A0", "#E3A3A3", "#758DFF", "#ADCDDE", "#FACAAA","#8AABE3","#B8CAE9","#ACEEF6","#FE6A6A"]}
//         chartClassName="max-w-4xl px-0 md:px-3 pt-3"/>
//     </div>
//   );
// };


// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let categories = [];
//     let series = {};
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ano)){
//         categories.push(data[i].ano);
//       }
//       if(series[data[i].departamentos]){
//         if(series[data[i].departamentos][data[i].ano]){
//           series[data[i].departamentos][data[i].ano] +=  data[i].graduados;
//         }
//         else{
//           series[data[i].departamentos][data[i].ano] = data[i].graduados;
//         }
//       }
//       else{
//         series[data[i].departamentos] = {
//           [data[i].ano]: data[i].graduados 
//         };
//       }
//     }
//     let seriesData = [];
//     let seriesKeys = Object.keys(series);
//     for(let i=0;i<seriesKeys.length;i++){
      
//       let yearData = [];

//       for(let j=0;j<categories.length;j++){
//         if(series[seriesKeys[i]][categories[j]]){
//           yearData.push({
//             x: categories[j],
//             y: series[seriesKeys[i]][categories[j]]
//           });
//         }
//         else{
//           yearData.push({
//             x: categories[j],
//             y: null
//           });
//         }
//       }
      
//       seriesData[i] = {
//         name: seriesKeys[i],
//         data: yearData
//       };
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = seriesData;
//   }
//   return dataObj;
// };

// const transformData2 = (data) => {
//   const dataObj = {
//     series: [],
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let seriesData = {};
//     let categories = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].areaDeConocimiento)){
//         categories.push(data[i].areaDeConocimiento);
//         seriesData[data[i].areaDeConocimiento] = {
//           name: data[i].areaDeConocimiento,
//           children: [{
//             name:  data[i].nucleoBasicoDelConocimientoNbc,
//             value: data[i].graduados
//           }]
//         };
//       }
//       else{
//         seriesData[data[i].areaDeConocimiento].children.push({
//           name:  data[i].nucleoBasicoDelConocimientoNbc,
//           value: data[i].graduados
//         });
//       }
//     }
//     let finalData = [];
//     let seriesKeys = Object.keys(seriesData);
//     for(let i=0;i<seriesKeys.length;i++){
//       finalData[i] = seriesData[seriesKeys[i]];
//     }
//     dataObj.series = {
//       name: "Egresados",
//       children: finalData
//     };
//   }
//   return dataObj;
// };

export default EgresadosAnualesPorAreaAcademica;