import React from "react";
import { Outlet } from "react-router-dom";
import Menu from "../components/Menu";
import SubMenu from "../components/SubMenu";
import { MenuProvider } from "../context/MenuProvider";

const AppLayout = (props) => {
  const category = props.category;

  return (
    <MenuProvider>
      <div className="flex flex-row w-100 h-full overflow-hidden">
        <Menu className="shrink-0 h-full" />
        <div className="grow h-full bg-lightGray pt-3 md:pt-5 pr-0 md:pr-5 w-1/2 overflow-y-scroll">
          <div
            className="h-fit min-h-full bg-textColorSecondary rounded p-2 md:px-7 md:py-5"
            style={{ width: "100%" }}
          >
            <SubMenu category={category} />
            <Outlet />
          </div>
        </div>
      </div>
    </MenuProvider>
  );
};

export default AppLayout;
