import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ authObj, children }) => {
  //const [ auth, setAuth ] = useState({user: { email: "user1@gmail.com", profilePicture: "", role: "admin", emailVerified: true }});
  const [ auth, setAuth ] = useState({user: authObj?.data });

  useEffect(() => {
    setAuth({user: authObj?.data });
  }, [authObj]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;