import React from "react";

import StyledTreemap from "../../components/StyledTreemap";
import useAuth from "../../hooks/useAuth";

const currentYear = 2022;
const yearsList = [];
for(let i=3;i>0;i--){
  for(let j=1;j<=3;j++){
    yearsList.push({
      label: currentYear-i+"-"+j,
      value: currentYear-i+"-"+j
    });
  }
}

const AccesoAInternet = () => {
  const {
    auth: { user },
  } = useAuth();
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      {/* <StyledChart
        serverId="internet"
        chartType="treemap"
        title="Prestadores de servicios por segmentos y velocidad promedio"
        hintTitle="Prestadores de servicios por segmentos y velocidad promedio"
        hintDescription="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        footerText="Fuente: MIN TIC"
        yearEnabled={true}
        yearKey="periodo"
        years={yearsList}
        transformData={transformData}
        legendPosition="top"
        legendHorizontalAlign="center"
        dataLabelsEnabled={true}
        chartClassName="max-w-3xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        height={400}
        strokeColor={["#FFFFFF"]}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        treemapEnableShades={false}
        treemapDistributed={true}
        chartColors={["#27388D", "#FACAAA", "#9CABF9", "#6579DE", "#97B3E5", "#9CABF9", "#F5B6A0", "#758DFF","#8AABE3","#B8CAE9","#ACEEF6","#FE6A6A"]}
        customTooltip={
          function({ seriesIndex, dataPointIndex, w}) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let color = w.config.colors.length < dataPointIndex+1 ? w.config.colors[0] : w.config.colors[dataPointIndex];
            return "<div class='text-sm w-80 overflow-hidden break-all whitespace-normal'>" +
            "<div class='px-2 py-2 inline-block max-w-full'><h5 class='font-semibold'>"+data.x+"</h5></div>" +
            "<div class='h-px bg-gray border-0 mb-1'></div>" +
            "<span class='px-3 my-1 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>No de accesos:</b> " + data.noDeAccesos + "</span>" +
            "<span class='px-3 my-1 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Velocidad Subida:</b> " + data.velocidadSubida + "</span>" +
            "<span class='px-3 my-1 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Velocidad Bajada:</b> " + data.velocidadBajada + "</span>" +
            "</div>";
          }
        }/> */}
      {/* <StyledTreemap 
        serverId="internet"
        title="Prestadores de servicios por segmentos y velocidad promedio"
        hintTitle="Prestadores de servicios por segmentos y velocidad promedio"
        hintDescription="Muestra datos sobre el acceso a internet en Barranquilla, desglosada por segmento poblacional, proveedor de servicio y velocidad promedio en megabits por segundo (Mb/s)."
        footerText="Fuente: MIN TIC"
        yearEnabled={true}
        yearKey="periodo"
        years={yearsList}
        transformData={transformData}
        chartColors={["#27388D", "#FACAAA", "#9CABF9", "#6579DE", "#97B3E5", "#9CABF9", "#F5B6A0", "#758DFF","#8AABE3","#B8CAE9","#ACEEF6","#FE6A6A"]}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"/> */}
        
        <div className="w-full pt-5 flex flex-col">
          <div className="w-full h-fit">
            <iframe
              src={`/api-dash/internet/${user?.dashChartsToken}`}
              title="Conectividad a Internet"
              style={{ width: "100%", height: "2700px" }}
              scrolling="no"
            />
          </div>
        </div>
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let seriesObject = {};
    for(let i=0;i<data.length;i++){
      if(!seriesObject[data[i].segmento]){
        seriesObject[data[i].segmento] = {
          name: data[i].segmento,
          children: []
        };
      }
      seriesObject[data[i].segmento].children.push({
        name: data[i].proveedor,
        value: data[i].noDeAccesos,
        toolTip:[
          {label: "Velocidad Subida",value: data[i].velocidadSubida },
          {label: "Velocidad Bajada",value: data[i].velocidadBajada },
        ]
      });
    }
    let seriesData = [];
    let seriesKeys = Object.keys(seriesObject);
    for(let i=0;i<seriesKeys.length;i++){
      seriesData[i] = seriesObject[seriesKeys[i]];
    }
    dataObj.series = {
      name: "No De ACCESOS",
      children: seriesData
    };
  }
  return dataObj;
};

export default AccesoAInternet;