import React from "react";
import axios from "../api/axios";
import { useLocationContext } from "../context/LocationProvider";
import { useLocation } from "react-router-dom";

export default function TrackPageVisit({ children }) {
  const [lastPage, setLastPage] = React.useState("");
  const [startTime, setStartTime] = React.useState(null);

  const location = useLocation();

  const { location: geoLocation } = useLocationContext();

  const sendPageDuration = () => {
    if (startTime) {
      const endTime = Date.now();
      const duration = endTime - startTime;

      axios
        .post(
          "/analytic",
          JSON.stringify({
            page: lastPage,
            visitPageStart: new Date(startTime).toISOString(),
            visitPageEnd: new Date(endTime).toISOString(),
            visitPageDuration: duration,
            location: geoLocation,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          setStartTime(null);
        })
        .catch(() => {
          setStartTime(null);
        });
    }
  };

  React.useEffect(() => {
    const handleSendPageDuration = () => {
      if (startTime) {
        sendPageDuration();
      }
    };

    const handleBeforeUnload = () => {
      handleSendPageDuration();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Si hay un tiempo de inicio y la página actual es diferente a la última página, envía la duración de la página anterior
    if (startTime && lastPage !== location.pathname) {
      sendPageDuration();
    }

    // Establece el tiempo de inicio y la última página para la página actual
    setStartTime(Date.now());
    setLastPage(location.pathname);

    return () => {
      handleSendPageDuration();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname]);

  return <>{children}</>;
}
