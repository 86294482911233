
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { BiDownload } from "react-icons/bi";
import { TbFileDownload } from "react-icons/tb";
import DatePicker from "react-datepicker";
import {IoIosCalendar} from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { Oval } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import useModal from "../../hooks/useModal";
import CsvUploadModal from "./CsvUploadModal";
import axios from "../../api/axiosAuth";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import CsvDataUpdateModal from "./CsvDataUpdateModal";
import { BASE_URL }  from "../../api/axiosAuth";

const dropdownStyle = { 
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    paddingLeft: 5,
    paddingRight: 5,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  control: base => ({
    ...base,
    padding: 0,
    margin: 0,
    borderColor: "#E2E5ED",
    minHeight: 26,
    cursor: "pointer"
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer"
  })
};

const Administrador = (props) => {

  const [subCategories, setSubCategories] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [ selectedCategory, setSelectedCategory ] = useState();
  const [ selectedFileName, setSelectedFileName ] = useState();
  const [ filterDate, setFilterDate ] = useState();
  const [ searchQuery, setSearchQuery ] = useState("");
  const [ searchTerm, setSearchTerm ] = useState(searchQuery);
  const datePickerRef = useRef();

  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [sortBy, setSortBy] = useState();
  const [sortDirection, setSortDirection] = useState();

  const { setModalView ,setModalVisibility, setModalCancelable } = useModal();

  const columns = [];
  let fileColumns = [];
  if(selectedFileName && selectedFileName.columns) {
    fileColumns = selectedFileName.columns; 
  } else if(selectedCategory && selectedCategory.columns) {
    fileColumns = selectedCategory.columns; 
  }
  for(let i=0;i<fileColumns.length;i++){
    columns.push({
      name: fileColumns[i].label,
      selector: row => row[fileColumns[i].value],
      sortable: true,
      columnKey: fileColumns[i].value,
      nullable: fileColumns[i].nullable ? true : false,
      type: fileColumns[i].type ? fileColumns[i].type : "text"
    });
  }
  columns.push({
    name: "Acción",
    cell:(row)=>(<div className="flex gap-2">
      <button className="bg-primary p-2 rounded" onClick={()=>{
        setModalView(<CsvDataUpdateModal category={props.category} subCategory={selectedCategory} graphName={selectedFileName} columns={fileColumns} data={row} modalClose={()=>{setModalVisibility(false);setModalCancelable(true);}} />);
        setModalVisibility(true);
        setModalCancelable(false);
      }}><BsPencil size={17} color={"#ffffff"}/></button>
      <button className="bg-secondary p-2 rounded" onClick={()=>{
        let serverId = "";
        if(selectedFileName && selectedFileName.value){
          serverId = selectedFileName.value;
        } else if(selectedCategory && selectedCategory.value) {
          serverId = selectedCategory.value;
        }
        setModalView(<DeleteConfirmationModal deleteUrl={serverId+"/"+row.id} modalClose={()=>{setModalVisibility(false);setModalCancelable(true);}} />);
        setModalVisibility(true);
        setModalCancelable(false);
      }}><RiDeleteBin6Line size={17} color={"#ffffff"}/></button>
    </div>),
    sortable: false,
  });

  const handleSearchQueryChange = (val) => {
    setSearchQuery(val);
  };

  useEffect(() => {
    if(props.subCategories && props.subCategories.length>0){
      setSubCategories(props.subCategories);
      setSelectedCategory( props.subCategories[0]);
      if(props.subCategories[0].childs && props.subCategories[0].childs.length > 0){
        setFileNames(props.subCategories[0].childs);
        setSelectedFileName(props.subCategories[0].childs[0]);
        fetchData(1,perPage,sortBy,sortDirection,props.subCategories[0].childs[0].value,filterDate,searchTerm);
      }
      else{
        setFileNames([]);
        setSelectedFileName(null);
        fetchData(1,perPage,sortBy,sortDirection,props.subCategories[0].value,filterDate,searchTerm);
      }
    }
    return () => {};
  }, [props.subCategories]);

  const fetchData = async (page, per_page, sort_by, sort_direction,serverId, date_filter, search) => {
    setTableLoading(true);
    if(date_filter)
      date_filter = (new Date(date_filter)).toISOString().split("T")[0];
    axios.get(""+serverId,{
      params: {
        pagination: true,
        page: page,
        limit: per_page,
        ...(sort_by && { sortBy: sort_by }),
        ...(sort_direction && { sortOrder: sort_direction }),
        filterQuery:{
          ...( ( search && search.trim().length !=0 ) && { filter: search } ),
          ...(date_filter && { createdAt: date_filter })
        }
      }
    }).then((data)=>{
      data = data?.data;
      setTableLoading(false);
      setTableData(data.data);
      setTotalRows(data.paginationDetails.totalDocs);
      //setTotalRows(result.total);
    }).catch(()=>{
      setTableLoading(false);
      //setError(err);
    });
  };

  const handleSort = async (column, sort_direction) => {
    /// reach out to some API and get new data using or sortField and sortDirection
    // e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}
    setSortBy(column.columnKey);
    setSortDirection(sort_direction);
    if(selectedFileName && selectedFileName.value){
      fetchData(1,perPage,column.columnKey,sort_direction,selectedFileName.value,filterDate,searchTerm);
    } else if(selectedCategory && selectedCategory.value) {
      fetchData(1,perPage,column.columnKey,sort_direction,selectedCategory.value,filterDate,searchTerm);
    }
    
  };

  const handlePageChange = page => {
    if(selectedFileName && selectedFileName.value){
      fetchData(page,perPage,sortBy,sortDirection,selectedFileName.value,filterDate,searchTerm);
    } else if(selectedCategory && selectedCategory.value) {
      fetchData(page,perPage,sortBy,sortDirection,selectedCategory.value,filterDate,searchTerm);
    }
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPerPage(newPerPage);
    if(selectedFileName && selectedFileName.value){
      fetchData(1,newPerPage,sortBy,sortDirection,selectedFileName.value,filterDate,searchTerm);
    } else if(selectedCategory && selectedCategory.value) {
      fetchData(1,newPerPage,sortBy,sortDirection,selectedCategory.value,filterDate,searchTerm);
    }
  };

  const handleSubCategoryChange = (e) => {
    if(e.childs && e.childs.length > 0){
      setFileNames(e.childs);
      setSelectedFileName(e.childs[0]);
      fetchData(1,perPage,sortBy,sortDirection,e.childs[0].value,filterDate,searchTerm);
    }
    else{
      setFileNames([]);
      setSelectedFileName(null);
      fetchData(1,perPage,sortBy,sortDirection,e.value,filterDate,searchTerm);
    }
    setSelectedCategory(e);
  };

  const handleFileNameChange = (e) => {
    setSelectedFileName(e);
    fetchData(1,perPage,sortBy,sortDirection,e.value,filterDate,searchTerm);
  };

  const filterDateChanged = (e) => {
    setFilterDate(e);
    if(selectedFileName && selectedFileName.value){
      fetchData(1,perPage,sortBy,sortDirection,selectedFileName.value,e,searchTerm);
    } else if(selectedCategory && selectedCategory.value) {
      fetchData(1,perPage,sortBy,sortDirection,selectedCategory.value,e,searchTerm);
    }
  };

  // update 'term' value after 1 second from the last update of 'debouncedTerm'
  useEffect(() => {
    const timer = setTimeout(() => setSearchTerm(searchQuery), 1000);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  // submit a new search
  useEffect(() => {
    if(searchTerm !== ""){
      if(selectedCategory){
        if(selectedFileName && selectedFileName.value){
          fetchData(1,perPage,sortBy,sortDirection,selectedFileName.value,filterDate,searchTerm);
        } else if(selectedCategory && selectedCategory.value) {
          fetchData(1,perPage,sortBy,sortDirection,selectedCategory.value,filterDate,searchTerm);
        }
      }
    }
    else{
      if(selectedCategory){
        if(selectedFileName && selectedFileName.value){
          fetchData(1,perPage,sortBy,sortDirection,selectedFileName.value,filterDate);
        } else if(selectedCategory && selectedCategory.value) {
          fetchData(1,perPage,sortBy,sortDirection,selectedCategory.value,filterDate);
        }
      }
    }
  }, [searchTerm]);

  return (
    <div className="flex flex-1 relative pt-3 flex-col">
      <div className="w-full flex gap-2 flex-wrap">
        <div className="w-32 md:w-40">
          <Select 
            options={subCategories}
            menuPlacement="auto"
            menuPosition="fixed"
            defaultValue={"Barranquilla"}
            styles={dropdownStyle}
            value={selectedCategory}
            onChange={handleSubCategoryChange}
            isClearable={false}
            className="basic-single text-tiny p-0 font-medium"
            classNamePrefix="select"
            isSearchable={false} />
        </div>
        <div className="w-32 md:w-40">
          {
            fileNames && fileNames.length > 0 ?
              <Select 
                options={fileNames}
                menuPlacement="auto"
                menuPosition="fixed"
                defaultValue={"Barranquilla"}
                styles={dropdownStyle}
                value={selectedFileName}
                onChange={handleFileNameChange}
                isClearable={false}
                className="basic-single text-tiny p-0 font-medium"
                classNamePrefix="select"
                isSearchable={false} />
              :
              <></>
          }
        </div>
        <button className="bg-primary text-textColorSecondary px-4 py-2 text-tiny rounded flex gap-1 justify-center items-center ml-3"
          onClick={()=>{
            setModalView(<CsvUploadModal category={props.category} subCategory={selectedCategory} graphName={selectedFileName} modalClose={()=>{setModalVisibility(false);setModalCancelable(true);}} />);
            setModalVisibility(true);
            setModalCancelable(false);
          }}>
          <BiDownload size={22}/>
          Importar CSV
        </button>
        <a href={BASE_URL + "/upload/" + (selectedFileName ? selectedFileName.value : selectedCategory?.value ) } download rel="noreferrer" target="_blank" className="border border-primary text-primary px-4 py-2 text-sm rounded flex gap-1 justify-center items-center ml-3">
          <TbFileDownload size={22}/>
          Download sample CSV
        </a>
        <div className="ml-auto">
          <div className="w-44 relative border border-gray text-tiny rounded">
            <DatePicker 
              ref={datePickerRef}
              className="inline-block w-full outline-none py-2 px-2 bg-transparent"  
              placeholderText="Filtrar por fecha" 
              selected={filterDate} 
              onChange={filterDateChanged} 
              isClearable
              closeOnScroll={true}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"/>
            {
              !filterDate ?
                <IoIosCalendar className="absolute right-2 top-2 cursor-pointer" size={20} onClick={()=>{datePickerRef.current?.input?.click();}}/>
                :
                <></>
            }
          </div>
        </div>
      </div>
      <div className="w-full flex pt-5 gap-2 justify-between items-end">
        <button 
          onClick={()=>{
            let serverId = "";
            if(selectedFileName && selectedFileName.value){
              serverId = selectedFileName.value;
            } else if(selectedCategory && selectedCategory.value) {
              serverId = selectedCategory.value;
            }
            setModalView(<DeleteConfirmationModal deleteUrl={"upload/"+serverId} modalClose={(refresh)=>{
              setModalVisibility(false);
              setModalCancelable(true);
              if(refresh)
                fetchData(1, perPage,sortBy,sortDirection);
            }} />);
            setModalVisibility(true);
            setModalCancelable(false);
          }}
          className="bg-red px-3 py-2 rounded text-textColorSecondary text-tiny h-fit w-fit">
          Delete all data
        </button>
        <div className="border border-gray py-3 px-2 flex rounded w-64">
          <input value={searchQuery} onChange={(e)=>{handleSearchQueryChange(e.target.value);}} className="text-tiny outline-none grow-1 flex-1 w-auto" placeholder="Buscar"/>
          <IoSearch className=""/>
        </div>
      </div>
      <DataTable
        className="border border-gray mt-3 border-collapse"
        columns={columns}
        data={tableData}
        striped
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        persistTableHead={true}
        paginationRowsPerPageOptions={[10,25,50,75,100]}
        onChangeRowsPerPage={handlePerRowsChange}
        responsive
        progressComponent={<div className="h-36 flex justify-center items-center"><Oval height={40} color="#005AA9" secondaryColor="#B3B9D5"/></div>}
        progressPending={tableLoading}
        onSort={handleSort}
        customStyles={{ rows: { style:{ backgroundColor: "#FFFFFF" }, stripedStyle: { ":nth-child(odd)": { backgroundColor: "#F7F7F7" }} }, cells: { style: { borderLeftWidth: 1, borderRightWidth: 1,borderColor: "#E2E5ED" } }, headCells: { style: { fontWeight: "semi-bold", borderLeftWidth: 1, borderRightWidth: 1,borderColor: "#E2E5ED" } }, table: { style: { borderCollapse: "collapse" } } }}
      />
    </div>
  );
};

export default Administrador;