import React from "react";

import StyledChart from "../../components/StyledChart";

const SalariosPorSectoresYCargos = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="salarios-por-sector-y-nivel-organizacional"
        chartType="boxPlot"
        dropdown1Key="sector"
        dropdown1Items={[
          {value: "Sector 1", label: "Sector 1"},
          {value: "Sector 2", label: "Sector 2"},
          {value: "Sector 3", label: "Sector 3"},
        ]}
        dropdown2Key="nivelOrganizacional"
        dropdown2Items={[
          {value: "Nivel bajo", label: "Nivel bajo"},
          {value: "Nivel medio", label: "Nivel medio"},
          {value: "Nivel Alto", label: "Nivel Alto"}
        ]}
        dropdown3Key="tipoSalario"
        dropdown3Items={[
          {value: "Básico", label: "Básico"},
          {value: "Variable", label: "Variable"},
        ]}
        title="Salarios por sectores y cargos"
        hintTitle="Salarios por sectores y cargos"
        hintDescription="Muestra un comparativo de los salarios promedios en los principales sectores económicos de las principales ciudades de Colombia. Incluye una comparación de los cargos más representativos en cada industria."
        footerText="Fuente: SUI"
        transformData={transformData}
        legendPosition="bottom"
        legendEnable={false}
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-4xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        chartColors={["#27388D","#F5DFA1","#FACAAA","#ADCDDE","#97B3E5"]}
        barDistributed={true}
        YaxisTickAmount={6}
      />
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let series = [];
    let categories = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ciudad)){
        categories.push(data[i].ciudad);
        series.push({
          x: data[i].ciudad,
          y: [ data[i].minimos, data[i].promedio - 1,data[i].promedio, data[i].promedio + 1, data[i].maximos ]
        });
      }
    }
    dataObj.series = [
      { data: series }
    ];
  }
  return dataObj;
};


export default SalariosPorSectoresYCargos;