import React, { useEffect, useRef, useState } from "react";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import Leaflet from "leaflet";
// import CustomMarker from "./CustomMarker";
// import * as ReactDOMServer from "react-dom/server";
// import { useQueries } from "react-query";
// import axios from "../../api/axiosAuth";
// import { MagnifyingGlass } from  "react-loader-spinner";
// import { MdFileDownload } from "react-icons/md";
// import { CSVLink } from "react-csv";

// const categoriesList = [
//   {
//     "label": "Aeropuertos",
//     "value": "aeropuertos",
//     "color": "#ff3399"
//   },
//   {
//     "label": "Bodegas Refrigeradas",
//     "value": "bodegas-refrigeradas",
//     "color": "#ff8000"
//   },
//   {
//     "label": "Centros Empresariales y Edificios de Oficinas",
//     "value": "centros-empresariales-y-edificios-de-oficinas",
//     "color": "#ffff00"
//   },
//   {
//     "label": "Centros y Plazas Comerciales",
//     "value": "centros-y-plazas-comerciales",
//     "color": "#80ff00"
//   },
//   {
//     "label": "Espacios Coworking",
//     "value": "espacios-coworking",
//     "color": "#00ff80"
//   },
//   {
//     "label": "Instituciones de Educación Superior",
//     "value": "instituciones-de-educacion-superior",
//     "color": "#00ffff"
//   },
//   {
//     "label": "Nodos SENA",
//     "value": "nodos-sena",
//     "color": "#0080ff"
//   },
//   {
//     "label": "Parques industriales",
//     "value": "parques-industriales",
//     "color": "#0000ff"
//   },
//   {
//     "label": "Puertos Marítimos",
//     "value": "puertos-maritimos",
//     "color": "#7f66ff"
//   },
//   {
//     "label": "Red de Salud Pública",
//     "value": "red-de-salud-publica",
//     "color": "#ff00ff"
//   },
//   {
//     "label": "Terminal Terrestre",
//     "value": "terminal-terrestre",
//     "color": "#ff0080"
//   },
//   {
//     "label": "Zonas Francas",
//     "value": "zonas-francas",
//     "color": "#261926"
//   }
// ];

let viz; 

const Maps = () => {

  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/Mapa-ProData/Maps_colors?:language=es-ES&:sid=&:display_count=n&:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
  );

  // const [ categories ] = useState(categoriesList);
  // const [ activeCategories, setActiveCategories ] = useState(["aeropuertos"]);

  // const fetchData = (url,filterQuery,sortBy,sortOrder) => {
  //   return axios.get(url,{
  //     params: { 
  //       filterQuery: JSON.stringify(filterQuery),
  //       ...(sortBy && { sortBy }),
  //       ...(sortOrder && { sortOrder })
  //     }
  //   });
  // };

  // const queryResults = useQueries(
  //   categories.map(category => {
  //     return {
  //       queryKey: ["maps", category.value],
  //       queryFn: () => fetchData(category.value,{}),
  //       enabled: activeCategories.includes(category.value),
  //       keepPreviousData: true,
  //       refetchOnMount: false,
  //       refetchOnWindowFocus: false,
  //       cacheTime: 900000,
  //       staleTime: 900000,
  //       select: (data) => {
  //         return data?.data?.data;
  //       },
  //     };
  //   })
  // );

  // const onCategoryClicked = (categoryId) => {
  //   if(!activeCategories.includes(categoryId)){
  //     setActiveCategories([ ...activeCategories, categoryId ]);
  //   }
  //   else{
  //     let indexOfCategory = activeCategories.indexOf(categoryId);
  //     setActiveCategories(activeCategories.filter((item,index)=>{ return index !== indexOfCategory; }));
  //   }
  // };


  // let isLoading = false;
  // for(let k=0;k<queryResults.length;k++){
  //   if(queryResults[k].isLoading || queryResults[k].isFetching){
  //     if(activeCategories.includes(categories[k].value)){
  //       isLoading = true;
  //     }
  //   }
  // }

  // return (
  //   <div className="flex flex-row w-100 h-full overflow-hidden">
  //     <div className="w-64 h-full bg-textColorSecondary overflow-y-scroll">
  //       <ul className="flex flex-col text-tiny text-black font-medium">
  //         {
  //           categories.map((category,index)=>{
  //             let isActive = false;
  //             if(activeCategories.includes(category.value))
  //               isActive = true;
  //             return(
  //               <li key={"map_category_"+index} className="w-full"><button onClick={()=>{onCategoryClicked(category.value);}} className={"w-full flex flex-row gap-3 items-center text-left border-b border-gray px-3 py-3 hover:bg-lightGray hover:opacity-100" + ( isActive ? "" : " opacity-40" )}><div className="h-2 w-2 shrink-0 rounded-full" style={{ backgroundColor: category.color  }}></div>{category.label}</button></li>
  //             );
  //           })
  //         }
  //       </ul>
  //     </div>
  //     <div className="flex-1 h-full bg-lightGray relative">
  //       <MapContainer className="w-full h-full" center={[10.983778, -74.804997]} zoom={12} scrollWheelZoom={false}>
  //         <TileLayer
  //           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  //           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  //         />
  //         {
  //           queryResults.map((result,categoryIndex)=>{
  //             if(!result.data || !Array.isArray(result.data) || result.data.length == 0 || !activeCategories.includes(categories[categoryIndex].value)){
  //               return <></>;
  //             }
  //             return result.data.map((location)=>{
  //               return (
  //                 <Marker key={location.id} position={[location.latitud, location.longitud]} icon={Leaflet.divIcon({
  //                   className: "custom-icon",
  //                   iconSize: [25, 35],
  //                   iconAnchor: [10, 35],
  //                   popupAnchor: [2, -34],
  //                   html: ReactDOMServer.renderToString(<CustomMarker color={categories[categoryIndex].color}/>)  
  //                 })}>
  //                   <Popup>
  //                     <div className="flex flex-col w-56">
  //                       <div className="font-semibold border-b border-gray pb-2">{location.elemento}</div>
  //                       {
  //                         location.imageUrl && location.imageUrl.trim().length != 0 ? 
  //                           <img alt="location preview" src={location.imageUrl} className="w-full"/>
  //                           :
  //                           <></>
  //                       }
  //                       <div className="text-tiny mt-2">DPTO: <b>{location.dpto}</b></div>
  //                       <div className="text-tiny mt-2">Municipio: <b>{location.municipio}</b></div>
  //                       {
  //                         location.nivelDeAtencion && <div className="text-tiny mt-2">Nivel de atención: <b>{location.nivelDeAtencion}</b></div>
  //                       }
  //                       {
  //                         location.direccion && <div className="text-tiny mt-2">Dirección: <b>{location.direccion}</b></div>
  //                       }
  //                       {
  //                         location.servicios && <div className="text-tiny mt-2">Servicios: <b>{location.servicios}</b></div>
  //                       }
  //                       {
  //                         location.callCenter && <div className="text-tiny mt-2">Call Center: <b>{location.callCenter.trim().replace(/^"+|"+$/g, "")}</b></div>
  //                       }
  //                     </div>
  //                   </Popup>
  //                 </Marker>
  //               );
  //             });
  //           })
  //         }
  //       </MapContainer>
  //       <CSVLink 
  //         filename={"prodata_map_export.csv"}
  //         data={
  //           queryResults.filter((result,index)=>{
  //             if(activeCategories.includes(categories[index].value) && !result.isFetching && !result.isLoading){
  //               return true;
  //             }
  //             return false;
  //           }).map((result)=>{
  //             return result.data.map((data)=>{
  //               let returnData = {...data};
  //               delete returnData.id;
  //               delete returnData._id;
  //               delete returnData.createdAt;
  //               delete returnData.updatedAt;
  //               return returnData;
  //             });
  //           }).flat(1)
  //         }
  //         className="bg-textColorSecondary z-10 absolute top-5 right-5 p-2 rounded-full shadow"
  //       >
  //         <MdFileDownload size={25}/>
  //       </CSVLink>
  //       <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center justify-center w-full h-full" style={{ backgroundColor: "rgba(255,255,255,0.75)",zIndex: 401, display: (isLoading) ? "flex" : "none" }}>
  //         <MagnifyingGlass
  //           height="80"
  //           width="80"
  //           color="#005AA9"
  //           secondaryColor="#B3B9D5"
  //           wrapperClass=""
  //           visible={isLoading}
  //           // visible={isLoading || isFetching}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );
};


export default Maps;