import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "./context/ModalProvider";
import { AuthProvider } from "./context/AuthProvider";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import "./style/basic.css";
import "react-datepicker/dist/react-datepicker.css";
import Router from "./Router";
import Modal from "react-modal";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "./api/axios";
import "react-phone-number-input/style.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { LocationProvider } from "./context/LocationProvider";
import TrackPageVisit from "./components/TrackPageVisit";

const loader = document.querySelector(".loader");

if (process.env.REACT_APP_NODE_ENV === "production") {
  disableReactDevTools();
}
Modal.setAppElement("#modal_element");
const queryClient = new QueryClient();

function App() {
  const [authObj, setAuthObj] = useState({ checked: false });

  useEffect(() => {
    axios
      .get("users")
      .then((data) => {
        setAuthObj({ checked: true, data: data.data.data });
        loader.classList.add("loader--hide");
      })
      .catch(() => {
        setAuthObj({ checked: true });
        loader.classList.add("loader--hide");
      });
  }, []);

  if (authObj?.checked) {
    return (
      <BrowserRouter>
        <LocationProvider>
          <AuthProvider authObj={authObj}>
            <TrackPageVisit>
              <ModalProvider>
                <QueryClientProvider client={queryClient}>
                  <Router />
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                  />
                </QueryClientProvider>
              </ModalProvider>
            </TrackPageVisit>
          </AuthProvider>
        </LocationProvider>
      </BrowserRouter>
    );
  }
  return <></>;
}

export default App;
