import React,{ useState } from "react";

import StyledChart from "../../components/StyledChart";

const currentYear = 2022;
const yearsList = [];
for(let i=2;i<=4;i++){
  yearsList.push({
    label: currentYear-i,
    value: currentYear-i
  });
}

const CompetitividadDepartamentos = () => {

  const [ dropdown1Items, setDropdown1Items ] = useState([
    {value: "PILAR 5: SALUD", label: "PILAR 5: SALUD"},
    {value: "Acceso a salud", label: "Acceso a salud"},
    {value: "Capacidades en salud", label: "Capacidades en salud"},
    {value: "Resultados en salud", label: "Resultados en salud"}
  ]);

  const [ dropdown2Items, setDropdown2Items ] = useState([
    {value: "PILAR 5: SALUD", label: "PILAR 5: SALUD"},
    {value: "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA", label: "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA"},
    {value: "PILAR 7: EDUCACIÓN SUPERIOR Y FORMACIÓN PARA EL TRABAJO", label: "PILAR 7: EDUCACIÓN SUPERIOR Y FORMACIÓN PARA EL TRABAJO"}
  ]);

  const [ dropdown3Items ] = useState([
    {value: "CAPITAL HUMANO", label: "CAPITAL HUMANO"},
    {value: "CONDICIONES HABILITANTES", label: "CONDICIONES HABILITANTES"},
    {value: "ECOSISTEMA INNOVADOR", label: "ECOSISTEMA INNOVADOR"},
    {value: "EFICIENCIA DE LOS MERCADOS", label: "EFICIENCIA DE LOS MERCADOS"},
    {value: "PUNTAJE GLOBAL", label: "PUNTAJE GLOBAL"}
  ]);
  
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="competitividad-de-departamentos"
        dropdown1Key="categoria"
        dropdown1Items={dropdown1Items}
        dropdown2Key="pilar"
        dropdown2Items={dropdown2Items}
        dropdown3Key="factor"
        dropdown3Items={dropdown3Items}
        chartType="bar"
        title="Índice Departamental de Competitividad - IDC"
        hintTitle="Índice Departamental de Competitividad - IDC"
        hintDescription="Esta sección proporciona información detallada sobre los resultados del índice departamental de competitividad de Colombia, permitiendo comparar los departamentos y sus diferentes aspectos, como la educación, la salud, la seguridad, la infraestructura y la innovación."
        footerText="Fuente: Consejo Privado de Competitividad CPC. Universidad del Rosario."
        yearEnabled={true}
        years={yearsList}
        yearKey="ano"
        transformData={transformData}
        legendPosition="top"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={0}
        height={500}
        strokeColor="transparent"
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        barColumnWidth={30}
        YaxisTickAmount={6}
        sortBy="puntaje"
        sortOrder="desc"
        yAxisLabelFormatter={(val)=>{ return val; }}
        stacked={false}
        chartColors={["#ADCDDE"]}
        gridPaddingBottom={20}
        gridPaddingLeft={20}
        onFilterChange={(e,selectName)=>{
          if(selectName === "dropdown3"){
            if(e.value==="CAPITAL HUMANO"){
              setDropdown2Items([
                {value: "PILAR 5: SALUD", label: "PILAR 5: SALUD"},
                {value: "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA", label: "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA"},
                {value: "PILAR 7: EDUCACIÓN SUPERIOR Y FORMACIÓN PARA EL TRABAJO", label: "PILAR 7: EDUCACIÓN SUPERIOR Y FORMACIÓN PARA EL TRABAJO"}
              ]);
              setDropdown1Items([
                {value: "Acceso a salud", label: "Acceso a salud"},
                {value: "Capacidades en salud", label: "Capacidades en salud"},
                {value: "PILAR 5: SALUD", label: "PILAR 5: SALUD"},
                {value: "Resultados en salud", label: "Resultados en salud"}
              ]);
            } else if(e.value==="CONDICIONES HABILITANTES"){
              setDropdown2Items([
                {value: "PILAR 1: INSTITUCIONES", label: "PILAR 1: INSTITUCIONES"},
                {value: "PILAR 2: INFRAESTRUCTURA Y EQUIPAMIENTO", label: "PILAR 2: INFRAESTRUCTURA Y EQUIPAMIENTO"},
                {value: "PILAR 3: Adopción TIC", label: "PILAR 3: Adopción TIC"},
                {value: "PILAR 4: SOSTENIBILIDAD AMBIENTAL", label: "PILAR 4: SOSTENIBILIDAD AMBIENTAL"}
              ]);
              setDropdown1Items([
                {value: "Desempeño administrativo", label: "Desempeño administrativo"},
                {value: "Gestión fiscal", label: "Gestión fiscal"},
                {value: "PILAR 1: INSTITUCIONES", label: "PILAR 1: INSTITUCIONES"},
                {value: "Seguridad y justicia", label: "Seguridad y justicia"},
                {value: "Transparencia y contratación pública", label: "Transparencia y contratación pública"}
              ]);
            } else if(e.value==="ECOSISTEMA INNOVADOR"){
              setDropdown2Items([
                {value: "PILAR 12: SOFISTICACIÓN Y DIVERSIFICACIÓN", label: "PILAR 12: SOFISTICACIÓN Y DIVERSIFICACIÓN"},
                {value: "PILAR 13: INNOVACIÓN", label: "PILAR 13: INNOVACIÓN"}
              ]);
              setDropdown1Items([
                {value: "PILAR 12: SOFISTICACIÓN Y DIVERSIFICACIÓN", label: "PILAR 12: SOFISTICACIÓN Y DIVERSIFICACIÓN"}
              ]);
            } else if(e.value==="EFICIENCIA DE LOS MERCADOS"){
              setDropdown2Items([
                {value: "PILAR 8: ENTORNO PARA LOS NEGOCIOS", label: "PILAR 8: ENTORNO PARA LOS NEGOCIOS"},
                {value: "PILAR 9: MERCADO LABORAL", label: "PILAR 9: MERCADO LABORAL"},
                {value: "PILAR 10: SISTEMA FINANCIERO", label: "PILAR 10: SISTEMA FINANCIERO"},
                {value: "PILAR 11: TAMAÑO DEL MERCADO", label: "PILAR 11: TAMAÑO DEL MERCADO"}

              ]);
              setDropdown1Items([
                {value: "PILAR 8: ENTORNO PARA LOS NEGOCIOS", label: "PILAR 8: ENTORNO PARA LOS NEGOCIOS"}
              ]);
            } else if(e.value==="PUNTAJE GLOBAL"){
              setDropdown2Items([
                {value: "PUNTAJE GLOBAL", label: "PUNTAJE GLOBAL"}
              ]);
              setDropdown1Items([
                {value: "PUNTAJE GLOBAL", label: "PUNTAJE GLOBAL"}
              ]);
            }
          }
          else if(selectName === "dropdown2"){
            if(e.value==="PILAR 1: INSTITUCIONES"){
              setDropdown1Items([
                {value: "Desempeño administrativo", label: "Desempeño administrativo"},
                {value: "Gestión fiscal", label: "Gestión fiscal"},
                {value: "PILAR 1: INSTITUCIONES", label: "PILAR 1: INSTITUCIONES"},
                {value: "Seguridad y justicia", label: "Seguridad y justicia"},
                {value: "Transparencia y contratación pública", label: "Transparencia y contratación pública"}
              ]);
            } else if(e.value==="PILAR 2: INFRAESTRUCTURA Y EQUIPAMIENTO"){
              setDropdown1Items([
                {value: "Conectividad", label: "Conectividad"},
                {value: "Infraestructura de servicios y vivienda", label: "Infraestructura de servicios y vivienda"},
                {value: "Infraestructura vial", label: "Infraestructura vial"},
                {value: "PILAR 2: INFRAESTRUCTURA Y EQUIPAMIENTO", label: "PILAR 2: INFRAESTRUCTURA Y EQUIPAMIENTO"}
              ]);
            } else if(e.value==="PILAR 3: Adopción TIC"){
              setDropdown1Items([
                {value: "Capacidades TIC", label: "Capacidades TIC"},
                {value: "Infraestructura TIC", label: "Infraestructura TIC"},
                {value: "PILAR 3: Adopción TIC", label: "PILAR 3: Adopción TIC"}
              ]);
            } else if(e.value==="PILAR 4: SOSTENIBILIDAD AMBIENTAL"){
              setDropdown1Items([
                {value: "Activos naturales", label: "Activos naturales"},
                {value: "Gestión ambiental y del riesgo", label: "Gestión ambiental y del riesgo"},
                {value: "PILAR 4: SOSTENIBILIDAD AMBIENTAL", label: "PILAR 4: SOSTENIBILIDAD AMBIENTAL"}
              ]);
            } else if(e.value === "PILAR 5: SALUD") {
              setDropdown1Items([
                {value: "PILAR 5: SALUD", label: "PILAR 5: SALUD"},
                {value: "Acceso a salud", label: "Acceso a salud"},
                {value: "Capacidades en salud", label: "Capacidades en salud"},
                {value: "Resultados en salud", label: "Resultados en salud"}
              ]);
            } else if(e.value === "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA") {
              setDropdown1Items([
                {value: "Calidad en educación", label: "Calidad en educación"},
                {value: "Cobertura en educación", label: "Cobertura en educación"},
                {value: "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA", label: "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA"}
              ]);
            } else if(e.value === "PILAR 7: EDUCACIÓN SUPERIOR Y FORMACIÓN PARA EL TRABAJO") {
              setDropdown1Items([
                {value: "Calidad en educación superior", label: "Calidad en educación superior"},
                {value: "Cobertura en educación superior", label: "Cobertura en educación superior"},
                {value: "Educación para el trabajo y el desarrollo humano", label: "Educación para el trabajo y el desarrollo humano"},
                {value: "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA", label: "PILAR 6: EDUCACIÓN BÁSICA Y MEDIA"},
                {value: "PILAR 7: EDUCACIÓN SUPERIOR Y FORMACIÓN PARA EL TRABAJO", label: "PILAR 7: EDUCACIÓN SUPERIOR Y FORMACIÓN PARA EL TRABAJO"}
              ]);
            }   else if(e.value === "PILAR 8: ENTORNO PARA LOS NEGOCIOS") {
              setDropdown1Items([
                {value: "PILAR 8: ENTORNO PARA LOS NEGOCIOS", label: "PILAR 8: ENTORNO PARA LOS NEGOCIOS"}
              ]);
            } else if(e.value === "PILAR 9: MERCADO LABORAL") {
              setDropdown1Items([
                {value: "Desempeño del mercado laboral", label: "Desempeño del mercado laboral"},
                {value: "PILAR 9: MERCADO LABORAL", label: "PILAR 9: MERCADO LABORAL"},
                {value: "Utilización del talento", label: "Utilización del talento"}
              ]);
            } else if(e.value === "PILAR 10: SISTEMA FINANCIERO") {
              setDropdown1Items([
                {value: "PILAR 10: SISTEMA FINANCIERO", label: "PILAR 10: SISTEMA FINANCIERO"}
              ]);
            } else if(e.value === "PILAR 11: TAMAÑO DEL MERCADO") {
              setDropdown1Items([
                {value: "PILAR 11: TAMAÑO DEL MERCADO", label: "PILAR 11: TAMAÑO DEL MERCADO"},
                {value: "Mercado externo", label: "Mercado externo"},
                {value: "Mercado Interno", label: "Mercado Interno"}
              ]);
            } else if(e.value === "PILAR 12: SOFISTICACIÓN Y DIVERSIFICACIÓN") {
              setDropdown1Items([
                {value: "PILAR 12: SOFISTICACIÓN Y DIVERSIFICACIÓN", label: "PILAR 12: SOFISTICACIÓN Y DIVERSIFICACIÓN"}
              ]);
            }  else if(e.value === "PILAR 13: INNOVACIÓN") {
              setDropdown1Items([
                {value: "Dinámica empresarial", label: "Dinámica empresarial"},
                {value: "Investigación", label: "Investigación"},
                {value: "PILAR 13: INNOVACIÓN", label: "PILAR 13: INNOVACIÓN"},
                {value: "Registros de propiedad industrial", label: "Registros de propiedad industrial"}
              ]);
            }   else if(e.value === "PUNTAJE GLOBAL") {
              setDropdown1Items([
                {value: "PUNTAJE GLOBAL", label: "PUNTAJE GLOBAL"}
              ]);
            }
          }
        }}/>
    </div>
  );
};


const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let finalData = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].departamento)){
        categories.push(data[i].departamento);
        finalData.push({
          x: data[i].departamento,
          y: data[i].puntaje,
          ranking: data[i].ranking
        });
      }
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Puntaje",
        data: finalData
      }
    ];
  }
  return dataObj;
};

export default CompetitividadDepartamentos;
