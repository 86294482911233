import React, { useState, useEffect, useRef } from "react";
import { MdFileDownload } from "react-icons/md";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import useModal from "../hooks/useModal";
import InfoModal from "../components/InfoModal";
import YearSelector from "../components/YearSelector";
import { Bars } from  "react-loader-spinner";
import Select from "react-select";
import { useQuery } from "react-query";
import axios from "../api/axiosAuth";
import Treemap from "./Treemap";

const currentYear = new Date().getFullYear();
const yearsList = [];
for(let i=0;i<5;i++){
  yearsList.push({
    label: currentYear-i,
    value: currentYear-i
  });
}


const StyledTreemap = (props) => {

  const title = props.title;
  const hintTitle = props.hintTitle;
  const hintDescription = props.hintDescription;
  const footerText = props.footerText;
  const serverId = props.serverId;
  const dropdown1Key = props.dropdown1Key;
  const dropdown2Key = props.dropdown2Key;
  const dropdown3Key = props.dropdown3Key;
  const dropdown4Key = props.dropdown4Key;
  const chartClassName = props.chartClassName;
  const wrapperClassName = props.wrapperClassName;
  const onDataLoaded = props.onDataLoaded;
  const onFilterChange = props.onFilterChange;
  const dropdown1Select = props.dropdown1Select;
  const dropdown2Select = props.dropdown2Select;

  const yearEnabled = props.yearEnabled;
  const yearKey = props.yearKey;

  const [height, setHeight] = useState(200);
  const [width, setWidth] = useState(220);

  const chartContainerRef = useRef();

  const [ dropdown1Items, setDropdown1Items ] = useState();
  const [ dropdown1Selected, setDropdown1Selected ] = useState();
  const [ dropdown2Items, setDropdown2Items ] = useState();
  const [ dropdown2Selected, setDropdown2Selected ] = useState();
  const [ dropdown3Items, setDropdown3Items ] = useState();
  const [ dropdown3Selected, setDropdown3Selected ] = useState();
  const [ dropdown4Items, setDropdown4Items ] = useState();
  const [ dropdown4Selected, setDropdown4Selected ] = useState();
  const [ queryEnable, setQueryEnable ] = useState(false);

  const [ years, setYears ] = useState(yearsList);
  const [ selectedYear, setSelectedYear ] = useState(currentYear);


  const fetchData = (filterQuery,sortBy,sortOrder) => {
    return axios.get(serverId,{
      params: { 
        filterQuery: JSON.stringify(filterQuery),
        ...(sortBy && { sortBy }),
        ...(sortOrder && { sortOrder })
      }
    });
  };



  const { isLoading, isFetching, data } = useQuery(
    [
      serverId,
      ...(yearEnabled && yearKey ? [selectedYear] : []),
      ...(dropdown1Items && dropdown1Items.length != 0 && dropdown1Selected && dropdown1Key ? [dropdown1Selected.value] : []),
      ...(dropdown2Items && dropdown2Items.length != 0 && dropdown2Selected && dropdown2Key ? [dropdown2Selected.value] : []),
      ...(dropdown3Items && dropdown3Items.length != 0 && dropdown3Selected && dropdown3Key ? [dropdown3Selected.value] : []),
      ...(dropdown4Items && dropdown4Items.length != 0 && dropdown4Selected && dropdown4Key ? [dropdown4Selected.value] : []),
    ], 
    () => fetchData({
      ...(yearEnabled && yearKey && { [yearKey]: selectedYear }),
      ...(dropdown1Items && dropdown1Items.length != 0 && dropdown1Selected && dropdown1Key && { [dropdown1Key]: dropdown1Selected.value }),
      ...(dropdown2Items && dropdown2Items.length != 0 && dropdown2Selected && dropdown2Key && { [dropdown2Key]: dropdown2Selected.value }),
      ...(dropdown3Items && dropdown3Items.length != 0 && dropdown3Selected && dropdown3Key && { [dropdown3Key]: dropdown3Selected.value }),
      ...(dropdown4Items && dropdown4Items.length != 0 && dropdown4Selected && dropdown4Key && { [dropdown4Key]: dropdown4Selected.value }),
    },props.sortBy,props.sortOrder),
    {
      enable: queryEnable,
      keepPreviousData: true,
      select: props.transformData,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 900000,
      staleTime: 900000,
    }
  );

  useEffect(() => {
    if(onDataLoaded){
      onDataLoaded(data);
    }
  }, [data]);

  function download(type){
    console.log(type);
  }

  const { setModalView ,setModalVisibility, setModalCancelable } = useModal();

  const onYearChange = (index,item) => {
    setSelectedYear(item.value);
  };

  useEffect(() => {
    if(props.years){
      setYears(props.years);
      setSelectedYear(props.years[0].value);
    }
    if(props.dropdown1Items && props.dropdown1Items.length>0){
      setDropdown1Items(props.dropdown1Items);
      setDropdown1Selected(props.dropdown1Items[0]);
    }
    if(props.dropdown2Items && props.dropdown2Items.length>0){
      setDropdown2Items(props.dropdown2Items);
      setDropdown2Selected(props.dropdown2Items[0]);
    }
    if(props.dropdown3Items && props.dropdown3Items.length>0){
      setDropdown3Items(props.dropdown3Items);
      setDropdown3Selected(props.dropdown3Items[0]);
    }
    if(props.dropdown4Items && props.dropdown4Items.length>0){
      setDropdown4Items(props.dropdown4Items);
      setDropdown4Selected(props.dropdown4Items[0]);
    }
    setHeight(chartContainerRef.current.offsetWidth * 50 / 100);
    setWidth(chartContainerRef.current.offsetWidth);
    setTimeout(()=>{
      setQueryEnable(true);
    },100);
    return () => {};
  }, []);


  const handleSelectChange = (e,selectName) => {
    if(selectName=="dropdown1"){
      setDropdown1Selected(e);
    }
    else if(selectName=="dropdown2"){
      setDropdown2Selected(e);
    }
    else if(selectName=="dropdown3"){
      setDropdown3Selected(e);
    }
    else if(selectName=="dropdown4"){
      setDropdown4Selected(e);
    }
    if(onFilterChange)
      onFilterChange(e,selectName);
  };

  let chartSelectedData = [];
  if(data?.series){
    if(dropdown2Select){
      let tempData;
      if(dropdown1Select){
        if(dropdown1Selected && data.series[dropdown1Selected.value]){
          tempData = data.series[dropdown1Selected.value];
        }
        else{
          tempData = [];
        }
      }
      else{
        tempData = data.series;
      }
      if(dropdown2Selected && tempData[dropdown2Selected.value]){
        chartSelectedData = tempData[dropdown2Selected.value];
      }
      else{
        chartSelectedData = [];
      }
    }else if(dropdown1Select){
      if(dropdown1Selected && data.series[dropdown1Selected.value]){
        chartSelectedData = data.series[dropdown1Selected.value];
      }
      else{
        chartSelectedData = [];
      }
    }
    else{
      chartSelectedData = data.series;
    }
  }
  return(
    <div className={"border relative border-gray rounded w-full pt-2 pb-3 "+( wrapperClassName ? wrapperClassName : "" )}>
      <div className='pl-5 pr-3 flex flex-wrap'>
        <div className='flex flex-row gap-1 items-start grow mt-2'>
          <h3 className='text-base text-primary font-semibold'>{title}</h3>
          <div className='cursor-pointer' 
            onClick={()=>{
              setModalView(<InfoModal 
                title={hintTitle}
                description={hintDescription}
              />);
              setModalVisibility(true);
              setModalCancelable(true);
            }} 
            onKeyDown={()=>{}} role="button" tabIndex={0}>
            <img alt="info icon" className="h-5" src='/images/svg/info.svg'/>
          </div>
        </div>
        <div className="order-last sm:order-none md:mr-2 flex gap-2 mt-2">
          {
            dropdown4Items && dropdown4Items.length != 0 ?
              <div className="w-32 md:w-36">
                <Select options={dropdown4Items}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{ 
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: 0,
                      margin: 0,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    control: base => ({
                      ...base,
                      padding: 0,
                      margin: 0,
                      minHeight: 26,
                      cursor: "pointer"
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                    option: (provided) => ({
                      ...provided,
                      cursor: "pointer"
                    })
                  }}
                  value={dropdown4Selected}
                  onChange={(e)=>{handleSelectChange(e,"dropdown4");}}
                  isClearable={false}
                  className="basic-single text-tiny p-0 font-medium"
                  classNamePrefix="select"
                  isSearchable={false} />
              </div>
              :
              <></>
          }
          {
            dropdown3Items && dropdown3Items.length != 0 ?
              <div className="w-32 md:w-36">
                <Select options={dropdown3Items}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{ 
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: 0,
                      margin: 0,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    control: base => ({
                      ...base,
                      padding: 0,
                      margin: 0,
                      minHeight: 26,
                      cursor: "pointer"
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                    option: (provided) => ({
                      ...provided,
                      cursor: "pointer"
                    })
                  }}
                  value={dropdown3Selected}
                  onChange={(e)=>{handleSelectChange(e,"dropdown3");}}
                  isClearable={false}
                  className="basic-single text-tiny p-0 font-medium"
                  classNamePrefix="select"
                  isSearchable={false} />
              </div>
              :
              <></>
          }
          {
            dropdown2Items && dropdown2Items.length != 0 ?
              <div className="w-32 md:w-36">
                <Select options={dropdown2Items}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  defaultValue={"Barranquilla"}
                  styles={{ 
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: 0,
                      margin: 0,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    control: base => ({
                      ...base,
                      padding: 0,
                      margin: 0,
                      minHeight: 26,
                      cursor: "pointer"
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                    option: (provided) => ({
                      ...provided,
                      cursor: "pointer"
                    })
                  }}
                  value={dropdown2Selected}
                  onChange={(e)=>{handleSelectChange(e,"dropdown2");}}
                  isClearable={false}
                  className="basic-single text-tiny p-0 font-medium"
                  classNamePrefix="select"
                  isSearchable={false} />
              </div>
              :
              <></>
          }
          {
            dropdown1Items && dropdown1Items.length != 0 ?
              <div className="w-32 md:w-36">
                <Select options={dropdown1Items}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  defaultValue={"Barranquilla"}
                  styles={{ 
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: 0,
                      margin: 0,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    control: base => ({
                      ...base,
                      padding: 0,
                      margin: 0,
                      minHeight: 26,
                      cursor: "pointer"
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                    option: (provided) => ({
                      ...provided,
                      cursor: "pointer"
                    })
                  }}
                  value={dropdown1Selected}
                  onChange={(e)=>{handleSelectChange(e,"dropdown1");}}
                  isClearable={false}
                  className="basic-single text-tiny p-0 font-medium"
                  classNamePrefix="select"
                  isSearchable={false} />
              </div>
              :
              <></>
          }
        </div>
        <Menu 
          transition={true}
          align="end"
          unmountOnClose={true}
          onItemClick={(e)=>{download(e.value);}}
          menuClassName='border rounded border-gray shadow-sm' 
          menuButton={<MenuButton><MdFileDownload size={30}/></MenuButton>}>
          <MenuItem value="csv" className='text-sm cursor-pointer px-2 pt-1 whitespace-nowrap hover:bg-gray'>Descargar CSV</MenuItem>
          <MenuItem value="png" className='text-sm cursor-pointer px-2 pt-1 whitespace-nowrap hover:bg-gray'>Descargar PNG</MenuItem>
          <MenuItem value="svg" className='text-sm cursor-pointer px-2 pt-1 pb-1 whitespace-nowrap hover:bg-gray'>Descargar SVG</MenuItem>
        </Menu>
      </div>
      <div className={ "px-2 mt-5 mx-auto w-100 "+( chartClassName ? chartClassName : "" ) }>
        <div className="w-100" ref={chartContainerRef}>
          <Treemap
            chartColors={props.chartColors} 
            data={chartSelectedData} 
            width={width} 
            height={height} />  
        </div>
      </div>
      {
        yearEnabled ? 
          <div className='w-full flex flex-col md:flex-row justify-center items-center mt-3'>
            <YearSelector wrapperClass="w-96 max-w-full px-5" items={years} selectedItem={selectedYear} onItemSelectionChange={onYearChange}/>
          </div>
          :
          <></>
      }
      <p className="flex-1 min-w-fit text-xs text-end pr-5 mt-3">{footerText}</p>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center justify-center w-full h-full" style={{ backgroundColor: "rgba(255,255,255,0.75)", display: isLoading || isFetching ? "flex" : "none" }}>
        <Bars
          height="80"
          width="80"
          color="#005AA9"
          secondaryColor="#B3B9D5"
          wrapperClass=""
          visible={isLoading || isFetching}
        />
      </div>
    </div>
  );
};

export default StyledTreemap;