import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-1 justify-center items-center">
      <div className="h-fit w-fit">
        <h1 className="font-semibold text-2xl">404 | Page Not Found</h1>
      </div>
    </div>
  );
};

export default NotFound;