import React,{ useRef, useState } from "react";

import StyledChart from "../../components/StyledChart";
import StyledTreemap from "../../components/StyledTreemap";

const currentYear = 2022;
const yearsList = [];
for(let i=0;i<=19;i++){
  yearsList.push({
    label: currentYear-i-2,
    value: currentYear-i-2
  });
}


const EgresadosAnualesPorNivelDeFormacion = () => {

  const chartRef = useRef();

  const [ dropdown1Items, setDropdown1Items ] = useState([
    {value: "ADMINISTRACION", label: "ADMINISTRACION"},
    {value: "BIOLOGIA, MICROBIOLOGIA Y AFINES", label: "BIOLOGIA, MICROBIOLOGIA Y AFINES"},
    {value: "COMUNICACION SOCIAL, PERIODISMO Y AFINES", label: "COMUNICACION SOCIAL, PERIODISMO Y AFINES"},
    {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
    {value: "EDUCACION", label: "EDUCACION"},
    {value: "FISICA", label: "FISICA"},
    {value: "INGENIERIA CIVIL Y AFINES", label: "INGENIERIA CIVIL Y AFINES"},
    {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
    {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
    {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
    {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
    {value: "PSICOLOGIA", label: "PSICOLOGIA"},
    {value: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES", label: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES"},
    {value: "", label: "TODOS"}
  ]);
  
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        chartRef={chartRef}
        serverId="egresados-por-niveles-de-formacion-y-nbc-departamentos"
        dropdown1Key="nucleoBasicoDelConocimientoNbc"
        dropdown1Items={dropdown1Items}
        dropdown2Key="nivelDeFormacion"
        dropdown2Items={[
          {value: "DOCTORADO", label: "DOCTORADO"},
          {value: "ESPECIALIZACION MEDICO QUIRURGICA", label: "ESPECIALIZACION MEDICO QUIRURGICA"},
          {value: "ESPECIALIZACION TECNICO PROFESIONAL", label: "ESPECIALIZACION TECNICO PROFESIONAL"},
          {value: "ESPECIALIZACION TECNOLOGICA", label: "ESPECIALIZACION TECNOLOGICA"},
          {value: "ESPECIALIZACION UNIVERSITARIA", label: "ESPECIALIZACION UNIVERSITARIA"},
          {value: "FORMACION TECNICA PROFESIONAL", label: "FORMACION TECNICA PROFESIONAL"},
          {value: "MAESTRIA", label: "MAESTRIA"},
          {value: "TECNOLOGICA", label: "TECNOLOGICA"},
          {value: "UNIVERSITARIA", label: "UNIVERSITARIA"},
          {value: "", label: "TODOS"}
        ]}
        chartType="line"
        title="Egresados por nivel de formación y NBC: Departamentos"
        hintTitle="Egresados por nivel de formación y NBC: Departamentos"
        hintDescription="Datos sobre la cantidad de egresados de la educación superior en Colombia a lo largo del tiempo, clasificados por el nivel de formación y el Núcleo Básico de Conocimiento (NBC) al que pertenecen."
        footerText="Fuente: SNIES"
        yearEnabled={false}
        transformData={transformData}
        legendPosition="bottom"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-1 md:px-3 pt-3"
        height={450}
        strokeWidth={4}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        yAxisLabelFormatter={(val)=>{ return val; }}
        YAxisBorderShow={true}
        chartColors={["#27388D","#F5DFA1","#F5B6A0", "#97B3E5","#ACC7F6","#ADCDDE","#758DFF","#FACAAA","#8094F5", "#B8CAE9","#8AABE3"]}
        legendEnable={true}
        XAxisType="numeric"
        xAxisLabelFormatter={
          function (val) {
            return val.toFixed(0);
          }
        }
        gridPaddingRight={20}
        gridPaddingLeft={20}
        onFilterChange={(e,selectName)=>{
          chartRef?.current?.chart?.updateSeries([],false);
          if(selectName === "dropdown2"){
            if(e.value==="DOCTORADO") {
              setDropdown1Items([
                {value: "ADMINISTRACION", label: "ADMINISTRACION"},
                {value: "BIOLOGIA, MICROBIOLOGIA Y AFINES", label: "BIOLOGIA, MICROBIOLOGIA Y AFINES"},
                {value: "COMUNICACION SOCIAL, PERIODISMO Y AFINES", label: "COMUNICACION SOCIAL, PERIODISMO Y AFINES"},
                {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
                {value: "EDUCACION", label: "EDUCACION"},
                {value: "FISICA", label: "FISICA"},
                {value: "INGENIERIA CIVIL Y AFINES", label: "INGENIERIA CIVIL Y AFINES"},
                {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
                {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
                {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
                {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
                {value: "PSICOLOGIA", label: "PSICOLOGIA"},
                {value: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES", label: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES"},
                {value: "", label: "TODOS"}
              ]);
            } else if(e.value==="ESPECIALIZACION MEDICO QUIRURGICA") {
              setDropdown1Items([
                {value: "ADMINISTRACION", label: "ADMINISTRACION"},
                {value: "ANTROPOLOGIA Y ARTES LIBERALES", label: "ANTROPOLOGIA Y ARTES LIBERALES"},
                {value: "ARQUITECTURA", label: "ARQUITECTURA"},
                {value: "CIENCIA POLITICA, RELACIONES INTERNACIONALES", label: "CIENCIA POLITICA, RELACIONES INTERNACIONALES"},
                {value: "COMUNICACION SOCIAL, PERIODISMO Y AFINES", label: "COMUNICACION SOCIAL, PERIODISMO Y AFINES"},
                {value: "CONTADURIA PUBLICA", label: "CONTADURIA PUBLICA"},
                {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
                {value: "ECONOMIA", label: "ECONOMIA"},
                {value: "EDUCACION", label: "EDUCACION"},
                {value: "ENFERMERIA", label: "ENFERMERIA"},
                {value: "FILOSOFIA, TEOLOGIA Y AFINES", label: "FILOSOFIA, TEOLOGIA Y AFINES"},
                {value: "FISICA", label: "FISICA"},
                {value: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES", label: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES"},
                {value: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES", label: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES"},
                {value: "INGENIERIA CIVIL Y AFINES", label: "INGENIERIA CIVIL Y AFINES"},
                {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
                {value: "INGENIERIA ELECTRICA Y AFINES", label: "INGENIERIA ELECTRICA Y AFINES"},
                {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
                {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
                {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
                {value: "LENGUAS MODERNAS, LITERATURA, LINGUISTICA Y AFINES", label: "LENGUAS MODERNAS, LITERATURA, LINGUISTICA Y AFINES"},
                {value: "MATEMATICAS, ESTADISTICA Y AFINES", label: "MATEMATICAS, ESTADISTICA Y AFINES"},
                {value: "MEDICINA", label: "MEDICINA"},
                {value: "NUTRICION Y DIETETICA", label: "NUTRICION Y DIETETICA"},
                {value: "ODONTOLOGIA", label: "ODONTOLOGIA"},
                {value: "OTRAS INGENIERIAS", label: "OTRAS INGENIERIAS"},
                {value: "PSICOLOGIA", label: "PSICOLOGIA"},
                {value: "QUIMICA Y AFINES", label: "QUIMICA Y AFINES"},
                {value: "SALUD PUBLICA", label: "SALUD PUBLICA"},
                {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
                {value: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES", label: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES"},
                {value: "TERAPIAS", label: "TERAPIAS"},
                {value: "ZOOTECNIA", label: "ZOOTECNIA"},
                {value: "", label: "TODOS"}
              ]);
            }  else if(e.value==="ESPECIALIZACION TECNICO PROFESIONAL") {
              setDropdown1Items([
                {value: "MEDICINA", label: "MEDICINA"},
                {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
                {value: "", label: "TODOS"}
              ]);
            }  else if(e.value==="ESPECIALIZACION TECNOLOGICA") {
              setDropdown1Items([
                {value: "ADMINISTRACION", label: "ADMINISTRACION"},
                {value: "AGRONOMIA", label: "AGRONOMIA"},
                {value: "ARQUITECTURA", label: "ARQUITECTURA"},
                {value: "DISEÑO", label: "DISEÑO"},
                {value: "ECONOMIA", label: "ECONOMIA"},
                {value: "EDUCACION", label: "EDUCACION"},
                {value: "FORMACION RELACIONADA CON EL CAMPO MILITAR O POLICIAL", label: "FORMACION RELACIONADA CON EL CAMPO MILITAR O POLICIAL"},
                {value: "GEOLOGIA, OTROS PROGRAMAS DE CIENCIAS NATURALES", label: "GEOLOGIA, OTROS PROGRAMAS DE CIENCIAS NATURALES"},
                {value: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES", label: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES"},
                {value: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES", label: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES"},
                {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
                {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
                {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
                {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
                {value: "NUTRICION Y DIETETICA", label: "NUTRICION Y DIETETICA"},
                {value: "OTRAS INGENIERIAS", label: "OTRAS INGENIERIAS"},
                {value: "PUBLICIDAD Y AFINES", label: "PUBLICIDAD Y AFINES"},
                {value: "", label: "TODOS"}
              ]);
            }  else if(e.value==="ESPECIALIZACION UNIVERSITARIA") {
              setDropdown1Items([
                {value: "ADMINISTRACION", label: "ADMINISTRACION"},
                {value: "ARQUITECTURA", label: "ARQUITECTURA"},
                {value: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS", label: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS"},
                {value: "CIENCIA POLITICA, RELACIONES INTERNACIONALES", label: "CIENCIA POLITICA, RELACIONES INTERNACIONALES"},
                {value: "COMUNICACION SOCIAL, PERIODISMO Y AFINES", label: "COMUNICACION SOCIAL, PERIODISMO Y AFINES"},
                {value: "CONTADURIA PUBLICA", label: "CONTADURIA PUBLICA"},
                {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
                {value: "ECONOMIA", label: "ECONOMIA"},
                {value: "EDUCACION", label: "EDUCACION"},
                {value: "ENFERMERIA", label: "ENFERMERIA"},
                {value: "FISICA", label: "FISICA"},
                {value: "INGENIERIA ADMINISTRATIVA Y AFINES", label: "INGENIERIA ADMINISTRATIVA Y AFINES"},
                {value: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES", label: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES"},
                {value: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES", label: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES"},
                {value: "INGENIERIA CIVIL Y AFINES", label: "INGENIERIA CIVIL Y AFINES"},
                {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
                {value: "INGENIERIA ELECTRICA Y AFINES", label: "INGENIERIA ELECTRICA Y AFINES"},
                {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
                {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
                {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
                {value: "MATEMATICAS, ESTADISTICA Y AFINES", label: "MATEMATICAS, ESTADISTICA Y AFINES"},
                {value: "MEDICINA", label: "MEDICINA"},
                {value: "ODONTOLOGIA", label: "ODONTOLOGIA"},
                {value: "OTRAS INGENIERIAS", label: "OTRAS INGENIERIAS"},
                {value: "PSICOLOGIA", label: "PSICOLOGIA"},
                {value: "QUIMICA Y AFINES", label: "QUIMICA Y AFINES"},
                {value: "SALUD PUBLICA", label: "SALUD PUBLICA"},
                {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
                {value: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES", label: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES"},
                {value: "TERAPIAS", label: "TERAPIAS"},
                {value: "", label: "TODOS"}
              ]);
            }  else if(e.value==="FORMACION TECNICA PROFESIONAL") {
              setDropdown1Items([
                {value: "ADMINISTRACION", label: "ADMINISTRACION"},
                {value: "AGRONOMIA", label: "AGRONOMIA"},
                {value: "ARQUITECTURA", label: "ARQUITECTURA"},
                {value: "BACTERIOLOGIA", label: "BACTERIOLOGIA"},
                {value: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS", label: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS"},
                {value: "CONTADURIA PUBLICA", label: "CONTADURIA PUBLICA"},
                {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
                {value: "DISEÑO", label: "DISEÑO"},
                {value: "ECONOMIA", label: "ECONOMIA"},
                {value: "EDUCACION", label: "EDUCACION"},
                {value: "FORMACION RELACIONADA CON EL CAMPO MILITAR O POLICIAL", label: "FORMACION RELACIONADA CON EL CAMPO MILITAR O POLICIAL"},
                {value: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES", label: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES"},
                {value: "INGENIERIA AGRONOMICA, PECUARIA Y AFINES", label: "INGENIERIA AGRONOMICA, PECUARIA Y AFINES"},
                {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
                {value: "INGENIERIA ELECTRICA Y AFINES", label: "INGENIERIA ELECTRICA Y AFINES"},
                {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
                {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
                {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
                {value: "INSTRUMENTACION QUIRURGICA", label: "INSTRUMENTACION QUIRURGICA"},
                {value: "MEDICINA", label: "MEDICINA"},
                {value: "NUTRICION Y DIETETICA", label: "NUTRICION Y DIETETICA"},
                {value: "ODONTOLOGIA", label: "ODONTOLOGIA"},
                {value: "OPTOMETRIA, OTROS PROGRAMAS DE CIENCIAS DE LA SALUD", label: "OPTOMETRIA, OTROS PROGRAMAS DE CIENCIAS DE LA SALUD"},
                {value: "OTRAS INGENIERIAS", label: "OTRAS INGENIERIAS"},
                {value: "OTROS PROGRAMAS ASOCIADOS A BELLAS ARTES", label: "OTROS PROGRAMAS ASOCIADOS A BELLAS ARTES"},
                {value: "PSICOLOGIA", label: "PSICOLOGIA"},
                {value: "PUBLICIDAD Y AFINES", label: "PUBLICIDAD Y AFINES"},
                {value: "SALUD PUBLICA", label: "SALUD PUBLICA"},
                {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
                {value: "", label: "TODOS"}
              ]);
            }  else if(e.value==="MAESTRIA") {
              setDropdown1Items([
                {value: "ADMINISTRACION", label: "ADMINISTRACION"},
                {value: "ARQUITECTURA", label: "ARQUITECTURA"},
                {value: "BACTERIOLOGIA", label: "BACTERIOLOGIA"},
                {value: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS", label: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS"},
                {value: "BIOLOGIA, MICROBIOLOGIA Y AFINES", label: "BIOLOGIA, MICROBIOLOGIA Y AFINES"},
                {value: "CIENCIA POLITICA, RELACIONES INTERNACIONALES", label: "CIENCIA POLITICA, RELACIONES INTERNACIONALES"},
                {value: "COMUNICACION SOCIAL, PERIODISMO Y AFINES", label: "COMUNICACION SOCIAL, PERIODISMO Y AFINES"},
                {value: "CONTADURIA PUBLICA", label: "CONTADURIA PUBLICA"},
                {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
                {value: "ECONOMIA", label: "ECONOMIA"},
                {value: "EDUCACION", label: "EDUCACION"},
                {value: "ENFERMERIA", label: "ENFERMERIA"},
                {value: "FILOSOFIA, TEOLOGIA Y AFINES", label: "FILOSOFIA, TEOLOGIA Y AFINES"},
                {value: "FISICA", label: "FISICA"},
                {value: "GEOGRAFIA, HISTORIA", label: "GEOGRAFIA, HISTORIA"},
                {value: "INGENIERIA ADMINISTRATIVA Y AFINES", label: "INGENIERIA ADMINISTRATIVA Y AFINES"},
                {value: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES", label: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES"},
                {value: "INGENIERIA CIVIL Y AFINES", label: "INGENIERIA CIVIL Y AFINES"},
                {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
                {value: "INGENIERIA ELECTRICA Y AFINES", label: "INGENIERIA ELECTRICA Y AFINES"},
                {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
                {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
                {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
                {value: "LENGUAS MODERNAS, LITERATURA, LINGUISTICA Y AFINES", label: "LENGUAS MODERNAS, LITERATURA, LINGUISTICA Y AFINES"},
                {value: "MATEMATICAS, ESTADISTICA Y AFINES", label: "MATEMATICAS, ESTADISTICA Y AFINES"},
                {value: "MEDICINA", label: "MEDICINA"},
                {value: "NUTRICION Y DIETETICA", label: "NUTRICION Y DIETETICA"},
                {value: "PSICOLOGIA", label: "PSICOLOGIA"},
                {value: "QUIMICA Y AFINES", label: "QUIMICA Y AFINES"},
                {value: "SALUD PUBLICA", label: "SALUD PUBLICA"},
                {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
                {value: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES", label: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES"},
                {value: "TERAPIAS", label: "TERAPIAS"},
                {value: "", label: "TODOS"}
              ]);
            }  else if(e.value==="TECNOLOGICA") {
              setDropdown1Items([
                {value: "ADMINISTRACION", label: "ADMINISTRACION"},
                {value: "AGRONOMIA", label: "AGRONOMIA"},
                {value: "ARQUITECTURA", label: "ARQUITECTURA"},
                {value: "ARTES PLASTICAS, VISUALES Y AFINES", label: "ARTES PLASTICAS, VISUALES Y AFINES"},
                {value: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS", label: "BIBLIOTECOLOGIA, OTROS DE CIENCIAS SOCIALES Y HUMANAS"},
                {value: "COMUNICACION SOCIAL, PERIODISMO Y AFINES", label: "COMUNICACION SOCIAL, PERIODISMO Y AFINES"},
                {value: "CONTADURIA PUBLICA", label: "CONTADURIA PUBLICA"},
                {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
                {value: "DISEÑO", label: "DISEÑO"},
                {value: "ECONOMIA", label: "ECONOMIA"},
                {value: "EDUCACION", label: "EDUCACION"},
                {value: "ENFERMERIA", label: "ENFERMERIA"},
                {value: "FORMACION RELACIONADA CON EL CAMPO MILITAR O POLICIAL", label: "FORMACION RELACIONADA CON EL CAMPO MILITAR O POLICIAL"},
                {value: "GEOLOGIA, OTROS PROGRAMAS DE CIENCIAS NATURALES", label: "GEOLOGIA, OTROS PROGRAMAS DE CIENCIAS NATURALES"},
                {value: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES", label: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES"},
                {value: "INGENIERIA AGRONOMICA, PECUARIA Y AFINES", label: "INGENIERIA AGRONOMICA, PECUARIA Y AFINES"},
                {value: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES", label: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES"},
                {value: "INGENIERIA BIOMEDICA Y AFINES", label: "INGENIERIA BIOMEDICA Y AFINES"},
                {value: "INGENIERIA CIVIL Y AFINES", label: "INGENIERIA CIVIL Y AFINES"},
                {value: "INGENIERIA DE MINAS, METALURGIA Y AFINES", label: "INGENIERIA DE MINAS, METALURGIA Y AFINES"},
                {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
                {value: "INGENIERIA ELECTRICA Y AFINES", label: "INGENIERIA ELECTRICA Y AFINES"},
                {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
                {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
                {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
                {value: "INGENIERIA QUIMICA Y AFINES", label: "INGENIERIA QUIMICA Y AFINES"},
                {value: "MEDICINA", label: "MEDICINA"},
                {value: "NUTRICION Y DIETETICA", label: "NUTRICION Y DIETETICA"},
                {value: "ODONTOLOGIA", label: "ODONTOLOGIA"},
                {value: "OPTOMETRIA, OTROS PROGRAMAS DE CIENCIAS DE LA SALUD", label: "OPTOMETRIA, OTROS PROGRAMAS DE CIENCIAS DE LA SALUD"},
                {value: "OTRAS INGENIERIAS", label: "OTRAS INGENIERIAS"},
                {value: "OTROS PROGRAMAS ASOCIADOS A BELLAS ARTES", label: "OTROS PROGRAMAS ASOCIADOS A BELLAS ARTES"},
                {value: "PUBLICIDAD Y AFINES", label: "PUBLICIDAD Y AFINES"},
                {value: "QUIMICA Y AFINES", label: "QUIMICA Y AFINES"},
                {value: "SALUD PUBLICA", label: "SALUD PUBLICA"},
                {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
                {value: "ZOOTECNIA", label: "ZOOTECNIA"},
                {value: "", label: "TODOS"}
              ]);
            }  else if(e.value==="UNIVERSITARIA") {
              setDropdown1Items([
                {value: "ADMINISTRACION", label: "ADMINISTRACION"},
                {value: "AGRONOMIA", label: "AGRONOMIA"},
                {value: "ARQUITECTURA", label: "ARQUITECTURA"},
                {value: "ARTES PLASTICAS, VISUALES Y AFINES", label: "ARTES PLASTICAS, VISUALES Y AFINES"},
                {value: "ARTES REPRESENTATIVAS", label: "ARTES REPRESENTATIVAS"},
                {value: "BACTERIOLOGIA", label: "BACTERIOLOGIA"},
                {value: "BIOLOGIA, MICROBIOLOGIA Y AFINES", label: "BIOLOGIA, MICROBIOLOGIA Y AFINES"},
                {value: "CIENCIA POLITICA, RELACIONES INTERNACIONALES", label: "CIENCIA POLITICA, RELACIONES INTERNACIONALES"},
                {value: "COMUNICACION SOCIAL, PERIODISMO Y AFINES", label: "COMUNICACION SOCIAL, PERIODISMO Y AFINES"},
                {value: "CONTADURIA PUBLICA", label: "CONTADURIA PUBLICA"},
                {value: "DEPORTES, EDUCACION FISICA Y RECREACION", label: "DEPORTES, EDUCACION FISICA Y RECREACION"},
                {value: "DERECHO Y AFINES", label: "DERECHO Y AFINES"},
                {value: "DISEÑO", label: "DISEÑO"},
                {value: "ECONOMIA", label: "ECONOMIA"},
                {value: "EDUCACION", label: "EDUCACION"},
                {value: "ENFERMERIA", label: "ENFERMERIA"},
                {value: "FILOSOFIA, TEOLOGIA Y AFINES", label: "FILOSOFIA, TEOLOGIA Y AFINES"},
                {value: "FISICA", label: "FISICA"},
                {value: "GEOGRAFIA, HISTORIA", label: "GEOGRAFIA, HISTORIA"},
                {value: "GEOLOGIA, OTROS PROGRAMAS DE CIENCIAS NATURALES", label: "GEOLOGIA, OTROS PROGRAMAS DE CIENCIAS NATURALES"},
                {value: "INGENIERIA ADMINISTRATIVA Y AFINES", label: "INGENIERIA ADMINISTRATIVA Y AFINES"},
                {value: "INGENIERIA AGRICOLA, FORESTAL Y AFINES", label: "INGENIERIA AGRICOLA, FORESTAL Y AFINES"},
                {value: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES", label: "INGENIERIA AGROINDUSTRIAL, ALIMENTOS Y AFINES"},
                {value: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES", label: "INGENIERIA AMBIENTAL, SANITARIA Y AFINES"},
                {value: "INGENIERIA BIOMEDICA Y AFINES", label: "INGENIERIA BIOMEDICA Y AFINES"},
                {value: "INGENIERIA CIVIL Y AFINES", label: "INGENIERIA CIVIL Y AFINES"},
                {value: "INGENIERIA DE MINAS, METALURGIA Y AFINES", label: "INGENIERIA DE MINAS, METALURGIA Y AFINES"},
                {value: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES", label: "INGENIERIA DE SISTEMAS, TELEMATICA Y AFINES"},
                {value: "INGENIERIA ELECTRICA Y AFINES", label: "INGENIERIA ELECTRICA Y AFINES"},
                {value: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES", label: "INGENIERIA ELECTRONICA, TELECOMUNICACIONES Y AFINES"},
                {value: "INGENIERIA INDUSTRIAL Y AFINES", label: "INGENIERIA INDUSTRIAL Y AFINES"},
                {value: "INGENIERIA MECANICA Y AFINES", label: "INGENIERIA MECANICA Y AFINES"},
                {value: "INGENIERIA QUIMICA Y AFINES", label: "INGENIERIA QUIMICA Y AFINES"},
                {value: "INSTRUMENTACION QUIRURGICA", label: "INSTRUMENTACION QUIRURGICA"},
                {value: "MATEMATICAS, ESTADISTICA Y AFINES", label: "MATEMATICAS, ESTADISTICA Y AFINES"},
                {value: "MEDICINA", label: "MEDICINA"},
                {value: "MEDICINA VETERINARIA", label: "MEDICINA VETERINARIA"},
                {value: "MUSICA", label: "MUSICA"},
                {value: "NUTRICION Y DIETETICA", label: "NUTRICION Y DIETETICA"},
                {value: "ODONTOLOGIA", label: "ODONTOLOGIA"},
                {value: "OPTOMETRIA, OTROS PROGRAMAS DE CIENCIAS DE LA SALUD", label: "OPTOMETRIA, OTROS PROGRAMAS DE CIENCIAS DE LA SALUD"},
                {value: "OTROS PROGRAMAS ASOCIADOS A BELLAS ARTES", label: "OTROS PROGRAMAS ASOCIADOS A BELLAS ARTES"},
                {value: "PSICOLOGIA", label: "PSICOLOGIA"},
                {value: "QUIMICA Y AFINES", label: "QUIMICA Y AFINES"},
                {value: "SALUD PUBLICA", label: "SALUD PUBLICA"},
                {value: "SIN CLASIFICAR", label: "SIN CLASIFICAR"},
                {value: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES", label: "SOCIOLOGIA, TRABAJO SOCIAL Y AFINES"},
                {value: "TERAPIAS", label: "TERAPIAS"},
                {value: "ZOOTECNIA", label: "ZOOTECNIA"},
                {value: "", label: "TODOS"}
              ]);
            } else {
              setDropdown1Items([
                {value: "", label: "TODOS"}
              ]);
            }
          }
        }}
        onDataLoaded={(data)=>{
          if(data?.series && data.series.length > 1){
            for(let i=0;i<data.series.length;i++){
              if(data.series[i]?.name !== "ATLANTICO"){
                chartRef?.current?.chart?.hideSeries(data.series[i].name);
              }
              else{
                chartRef?.current?.chart?.showSeries(data.series[i].name);
              }
            }
          }
        }}/>
      {/* <StyledChart
        serverId="egresados-del-atlantico-por-niveles-de-formacion-y-nbc"
        chartType="treemap"
        title="Egresados del Atlántico por nivel de formación y NBC"
        hintTitle="Egresados del Atlántico por nivel de formación y NBC"
        hintDescription="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        footerText="Fuente: SNIES"
        yearEnabled={true}
        years={yearsList}
        yearKey="ano"
        transformData={transformData2}
        dataLabelsEnabled={true}
        wrapperClassName=""
        chartClassName="max-w-5xl pt-3 px-3"
        height={350}
        strokeColor={["#FFFFFF"]}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        treemapDistributed={true}
        treemapEnableShades={false}
        chartColors={["#27388D", "#F1D37C", "#97B3E5", "#F5B6A0", "#E3A3A3", "#758DFF", "#ADCDDE", "#FACAAA","#8AABE3","#B8CAE9","#ACEEF6","#FE6A6A"]}
        customTooltip={
          function({ seriesIndex, dataPointIndex, w}) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let color = w.config.colors.length < dataPointIndex+1 ? w.config.colors[0] : w.config.colors[dataPointIndex];
            let listHtml = "";
            for(let i=0;i<data.list.length;i++){
              listHtml += "<span class='px-3 mt-2 inline-block text-xs'><div style='background: "+color+"' class='w-2 h-1 inline-block mr-2 text-xs'></div><b>"+data.list[i].label+":</b> " + data.list[i].value + "</span><br/>";
            }
            return "<div class='text-sm w-96 overflow-hidden break-all whitespace-normal'>" +
            "<div class='px-2 py-2 inline-block w-full bg-darkGray'><h5 class='font-semibold'>"+data.x+"</h5></div>" +
            "<div class='h-px bg-gray border-0'></div>" +
            listHtml +
            "</div>";
          }
        }/> */}
      <StyledTreemap 
        serverId="egresados-del-atlantico-por-niveles-de-formacion-y-nbc"
        title="Egresados del Atlántico por nivel de formación y NBC"
        hintTitle="Egresados del Atlántico por nivel de formación y NBC"
        hintDescription="Presenta el Número de egresados en educación superior en el departamento del Atlántico, clasificados por nivel de conocimiento y el Núcleo Básico de Conocimiento (NBC) al que pertenecen."
        footerText="Fuente: SNIES"
        yearEnabled={true}
        yearKey="ano"
        years={yearsList}
        transformData={transformData2}
        chartColors={["#27388D", "#F1D37C", "#97B3E5", "#F5B6A0", "#E3A3A3", "#758DFF", "#ADCDDE", "#FACAAA","#8AABE3","#B8CAE9","#ACEEF6","#FE6A6A"]}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"/>
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let series = {};
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
      }
      if(series[data[i].departamentoDeOfertaDelPrograma]){
        if(series[data[i].departamentoDeOfertaDelPrograma][data[i].ano]){
          series[data[i].departamentoDeOfertaDelPrograma][data[i].ano] +=  data[i].graduados;
        }
        else{
          series[data[i].departamentoDeOfertaDelPrograma][data[i].ano] = data[i].graduados;
        }
      }
      else{
        series[data[i].departamentoDeOfertaDelPrograma] = {
          [data[i].ano]: data[i].graduados 
        };
      }
    }
    let seriesData = [];
    let seriesKeys = Object.keys(series);
    for(let i=0;i<seriesKeys.length;i++){
      
      let yearData = [];

      for(let j=0;j<categories.length;j++){
        if(series[seriesKeys[i]][categories[j]]){
          yearData.push({
            x: categories[j],
            y: series[seriesKeys[i]][categories[j]]
          });
        }
        else{
          yearData.push({
            x: categories[j],
            y: null
          });
        }
      }
      
      seriesData[i] = {
        name: seriesKeys[i],
        data: yearData
      };
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = seriesData;
  }
  return dataObj;
};

const transformData2 = (data) => {
  const dataObj = {
    series: [],
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let seriesData = {};
    let categories = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].nivelDeFormacion)){
        categories.push(data[i].nivelDeFormacion);
        seriesData[data[i].nivelDeFormacion] = {
          name: data[i].nivelDeFormacion,
          children: [{
            name:  data[i].nucleoBasicoDeConocimientoNbc,
            value: data[i].graduados
          }]
        };
      }
      else{
        seriesData[data[i].nivelDeFormacion].children.push({
          name:  data[i].nucleoBasicoDeConocimientoNbc,
          value: data[i].graduados
        });
      }
    }
    let finalData = [];
    let seriesKeys = Object.keys(seriesData);
    for(let i=0;i<seriesKeys.length;i++){
      finalData[i] = seriesData[seriesKeys[i]];
    }
    dataObj.series = {
      name: "Egresados",
      children: finalData
    };
  }
  return dataObj;
};

export default EgresadosAnualesPorNivelDeFormacion;