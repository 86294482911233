import React,{ useState } from "react";

import StyledChart from "../../components/StyledChart";

const TasaDeOcupados = () => {

  const [ dropdown1Items, setDropdown1Items ] = useState([
    {value: "18 a 28", label: "18 a 28"},
    {value: "29 a 40", label: "29 a 40"},
    {value: "41 a 60", label: "41 a 60"}
  ]);

  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="to-comparativo-ciudades-y-areas-metropolitanas"
        chartType="area"
        dropdown1Key="subcategoria"
        dropdown1Items={dropdown1Items}
        dropdown2Key="categoria"
        dropdown2Items={[
          {value: "Rango de edad", label: "Rango de edad"},
          {value: "Sexo", label: "Sexo"},
          {value: "Total", label: "Total"}
        ]}
        title="Tasa de Ocupados"
        hintTitle="Tasa de Ocupados"
        hintDescription="Análisis del desempeño de la tasa de ocupados trimestral en las principales ciudades de Colombia, desglosada por grupos de edad y género. Permite conocer la distribución del empleo en función de estas variables.
        TO = Representa el porcentaje de personas ocupadas con respecto al total de la población que se encuentra en edad de trabajar.
        "
        footerText="Fuente: DANE - GEIH"
        yearEnabled={false}
        sortBy="periodo"
        sortOrder="asc"
        transformData={transformData}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        XAxisType="datetime"
        fill={{
          type: "gradient",
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          } 
        }}
        YaxisTickAmount={5}
        EnableBrushChart={true}
        chartColors={["#27388D"]}
        brushTargetType={"line"}
        height={250}
        onFilterChange={(e,selectName)=>{
          if(selectName === "dropdown2"){
            if(e.value==="Rango de edad"){
              setDropdown1Items([
                {value: "18 a 28", label: "18 a 28"},
                {value: "29 a 40", label: "29 a 40"},
                {value: "41 a 60", label: "41 a 60"}
              ]);
            } else if(e.value==="Sexo"){
              setDropdown1Items([
                {value: "Hombres", label: "Hombres"},
                {value: "Mujeres", label: "Mujeres"}
              ]);
            } else if(e.value==="Total"){
              setDropdown1Items([
                {value: "Total", label: "Total"}
              ]);
            }
          }
        }}
      />
    </div>
  );
};
const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  let seriesName;
  if(data && data.length > 0) {
    let finalData = [];
    for(let i=0;i<data.length;i++){
      finalData.push({
        x: new Date(data[i].periodo),
        y: data[i].to,
      });
      seriesName = data[i].subcategoria;
    }
    dataObj.series = [{
      name: seriesName,
      data: finalData
    }];
  }
  return dataObj;
};

export default TasaDeOcupados;