import React,{ useEffect, useRef } from "react";

let viz; 

const RetencionInversiones = () => {

  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/ResultadosPB-InversionesProData/Dashboard1?:language=es-ES&publish=yes&:display_count=n&:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
  );
};

export default RetencionInversiones;