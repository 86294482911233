import React,{ useState } from "react";

import StyledChart from "../../components/StyledChart";

const TasaDeDesocupados = () => {
  
  const [ dropdown1Items, setDropdown1Items ] = useState([
    {value: "Mujeres", label: "Mujeres"},
    {value: "Hombres", label: "Hombres"}
  ]);

  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="td-comparativo-ciudades-y-areas-metropolitanas"
        chartType="area"
        dropdown1Key="subcategoria"
        dropdown1Items={dropdown1Items}
        dropdown2Key="categoria"
        dropdown2Items={[
          {value: "Sexo", label: "Sexo"},
          {value: "Total", label: "Total"}
        ]}
        title="Tasa de Desocupados"
        hintTitle="Tasa de Desocupados"
        hintDescription="Análisis del desempeño de la tasa de desocupados en las principales ciudades de Colombia, desglosada por género. Permite conocer la distribución del desempleo en función del género.
        TD = Representa el porcentaje de personas que aún estando económicamente activas (con la edad requerida y con la intención de ocuparse) no logran encontrar trabajo.
        "
        footerText="Fuente: DANE - IPC"
        yearEnabled={false}
        sortBy="periodo"
        sortOrder="asc"
        transformData={transformData}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        XAxisType="datetime"
        fill={{
          type: "gradient",
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          } 
        }}
        YaxisTickAmount={5}
        EnableBrushChart={true}
        chartColors={["#27388D"]}
        brushTargetType={"line"}
        height={250}
        onFilterChange={(e,selectName)=>{
          if(selectName === "dropdown2"){
            if(e.value==="Sexo"){
              setDropdown1Items([
                {value: "Hombres", label: "Hombres"},
                {value: "Mujeres", label: "Mujeres"}
              ]);
            } else if(e.value==="Total"){
              setDropdown1Items([
                {value: "Total", label: "Total"}
              ]);
            }
          }
        }}
      />
    </div>
  );
};
const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  let seriesName;
  if(data && data.length > 0) {
    let finalData = [];
    for(let i=0;i<data.length;i++){
      finalData.push({
        x: new Date(data[i].periodo),
        y: data[i].td
      });
      seriesName = data[i].subcategoria;
    }
    dataObj.series = [{
      name: seriesName,
      data: finalData
    }];
  }
  return dataObj;
};

export default TasaDeDesocupados;