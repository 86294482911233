import React from "react";
import { Outlet } from "react-router-dom";
import AdminMenu from "../components/AdminMenu";

const AdminLayout = () => {

  return (
    <div className="flex flex-row w-100 h-full overflow-hidden">
      <AdminMenu className="shrink-0 h-full"/>
      <div className='grow h-full bg-lightGray pt-3 md:pt-5 pr-0 md:pr-5 w-1/2 overflow-y-scroll'>
        <div className='h-fit min-h-full bg-textColorSecondary rounded p-2 md:p-10 md:pt-5' style={{ width: "100%"  }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;