import React from "react";

import StyledChart from "../../components/StyledChart";

const CargaAerea = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="historico-de-carga-aerea-nacional-e-internacional"
        chartType="area"
        dropdown1Key="ciudad"
        dropdown1Items={[
          {value: "Barranquilla", label: "Barranquilla"},
          {value: "Nacional", label: "Nacional"},
          {value: "Medellin", label: "Medellin"},
          {value: "Bogota", label: "Bogota"},
          {value: "Cartagena", label: "Cartagena"},
          {value: "Tunja", label: "Tunja"},
          {value: "Manizales", label: "Manizales"},
          {value: "Florencia", label: "Florencia"},
          {value: "Popayan", label: "Popayan"},
          {value: "Monteria", label: "Monteria"},
          {value: "Neiva", label: "Neiva"},
          {value: "Riohacha", label: "Riohacha"},
          {value: "Santa Marta", label: "Santa Marta"},
          {value: "Villavicencio", label: "Villavicencio"},
          {value: "Pasto", label: "Pasto"},
          {value: "Cucuta", label: "Cucuta"},
          {value: "Armenia", label: "Armenia"},
          {value: "Pereira", label: "Pereira"},
          {value: "Bucaramanga", label: "Bucaramanga"},
          {value: "Sincelejo", label: "Sincelejo"},
          {value: "Ibague", label: "Ibague"},
          {value: "Cali", label: "Cali"},
          {value: "Otras areas urbanas", label: "Otras areas urbanas"},
        ]}
        title="Histórico de carga aérea nacional e internacional"
        hintTitle="Histórico de carga aérea nacional e internacional"
        hintDescription="Histórico de datos sobre el volumen de carga aérea nacional e internacional que entró y salió del departamento del Atlántico. Los datos se pueden desglosar por ciudad."
        footerText="Fuente: Aeronáutica civil, aeropuerto Ernesto Cortissoz"
        yearEnabled={false}
        sortBy="fecha"
        sortOrder="asc"
        transformData={transformData}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        XAxisType="datetime"
        fill={{
          type: "gradient",
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          } 
        }}
        YaxisTickAmount={5}
        EnableBrushChart={true}
        chartColors={["#27388D","#ADCDDE"]}
        brushTargetType={"line"}
        height={250}
      />
    </div>
  );
};
// function generateDayWiseTimeSeries(baseval, count, yrange) {
//   var i = 0;
//   var series = [];
//   while (i < count) {
//     var x = baseval;
//     var y =
//       Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

//     series.push([x, y]);
//     baseval += 2.592e+9;
//     i++;
//   }
//   return series;
// }

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let entradas = [];
    let salidas = [];
    for(let i=0;i<data.length;i++){
      entradas.push({
        x: new Date(data[i].fecha),
        y: data[i].entradas
      });
      salidas.push({
        x: new Date(data[i].fecha),
        y: data[i].salidas
      });
    }
    dataObj.series = [
      {
        name: "Entradas",
        data: entradas
      },
      {
        name: "Salidas",
        data: salidas
      }
    ];
  }
  return dataObj;
};

export default CargaAerea;