import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import ChangePasswordModal from "./ChangePasswordModal";
import useModal from "../hooks/useModal";
import { Oval } from  "react-loader-spinner";
import axios from "../api/axiosAuth";
import {  toast } from "react-toastify";

const Profile = () => {
  
  const [ profileImageError, setProfileImageError] = useState(false);
 
  const [ user, setUser ] = useState({});

  const [ loading, setLoading ] = useState(true);

  const { setModalView ,setModalVisibility, setModalCancelable } = useModal();

  useEffect(()=>{ 
    axios.get("users").then((data)=>{
      setUser({ ...data.data.data });
      setLoading(false);
    }).catch((error) => {
      setUser({});
      setLoading(false);
      if(error.response && error.response.message){
        toast.error(error.response.message);
      }
      else{
        toast.error(error.message);
      }
    });
  },[]);

  if(loading){
    return(
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center justify-center w-full h-full" style={{ backgroundColor: "rgba(255,255,255,0.75)", display: "flex" }}>
        <Oval
          height="60"
          width="60"
          color="#005AA9"
          secondaryColor="#B3B9D5"
          wrapperClass=""
          visible={loading}
        />
      </div>
    );
  }

  return(
    <div className="flex flex-row w-100 h-full overflow-hidde bg-lightGray">
      <div className="flex flex-row gap-10 w-full px-10 py-10 max-w-6xl mx-auto flex-wrap"> 
        <div className="flex flex-col gap-2 items-center h-fit px-8 py-16 bg-textColorSecondary border border-darkGray rounded-sm max-w-xs">
          {
            profileImageError || !user.profilePicture ? 
              <FaUser size={45} className="border rounded-full pt-1 text-primary"/>
              :
              <img src={user.profilePicture} alt="User profile" className="text-primary border w-16 h-16 rounded-full object-scale-down" onError={()=>{setProfileImageError(true);}}/>
          }
          <p className="max-w-full text-center text-tiny break-words">{user.email}</p>
          <p className="max-w-full text-center text-tiny break-words">{user.firstName + " " + user.lastName }</p>
          {
            user.position && user.position.trim().length != "" ?
              <p className="max-w-full text-center text-tiny break-words">{user.position}</p>
              :
              <></>
          }
        </div>
        <div className="flex-1 shrink-0 h-fit border border-darkGray rounded px-6 py-10 bg-textColorSecondary" style={{ minWidth: "10rem" }}>
          <h1 className="font-medium text-xl">Datos de la cuenta</h1>
          <div className="mt-10 flex flex-col w-full gap-1">
            <label className="font-medium ml-5" htmlFor="name_input">Nombre</label>
            <input value={user.firstName} className="text-tiny border border-darkGray bg-lightGray rounded px-5 py-3" id="name_input" readOnly/>
          </div>
          <div className="mt-5 flex flex-col w-full gap-1">
            <label className="font-medium ml-5" htmlFor="name_input">Correo electrónico</label>
            <input value={user.email} className="text-tiny border border-darkGray bg-lightGray rounded px-5 py-3" id="name_input" readOnly/>
          </div>
          <div className="mt-5 flex flex-col w-full gap-1">
            <label className="font-medium ml-5" htmlFor="name_input">Teléfono</label>
            <input value={user.telephone} className="text-tiny border border-darkGray bg-lightGray rounded px-5 py-3" id="name_input" readOnly/>
          </div>
          <div className="mt-5 flex flex-col w-full gap-1">
            <label className="font-medium ml-5" htmlFor="name_input">Organización</label>
            <input value={user.organization} className="text-tiny border border-darkGray bg-lightGray rounded px-5 py-3" id="name_input" readOnly/>
          </div>
          <div className="mt-5 flex flex-col w-full gap-1">
            <label className="font-medium ml-5" htmlFor="name_input">Sector</label>
            <input value={user.sector} className="text-tiny border border-darkGray bg-lightGray rounded px-5 py-3" id="name_input" readOnly/>
          </div>
          <button onClick={()=>{
            setModalView(<ChangePasswordModal modalClose={()=>{setModalVisibility(false);setModalCancelable(true);}} />);
            setModalVisibility(true);
            setModalCancelable(false);
          }}
          className="mt-10 bg-primary text-textColorSecondary rounded px-5 py-3 text-tiny w-full">Cambiar contraseña</button>
        </div>
      </div>
    </div>
  );
};
export default Profile;
