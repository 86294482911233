import React, { useEffect, useRef } from "react";

// import StyledChart from "../../components/StyledChart";
// import LogoList from "../../components/LogoList";

let viz;

const OfertaAcademica = () => {

  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/OfertaAcadmica-ProData/OfertaAcadmica-ProData?:language=es-ES&amp;:display_count=n&amp;:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);
  

  //const [selectedFilter, setSelectedFilter] = useState();

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="w-full pt-5 flex flex-col gap-5">
  //     <StyledChart
  //       serverId="oferta-academica-atlantico"
  //       chartType="heatmap"
  //       title="Programas por Nivel de formación y área de conocimiento en el Atlántico"
  //       hintTitle="Programas por Nivel de formación y área de conocimiento en el Atlántico"
  //       hintDescription="Muestra el número de programas académicos en las principales instituciones de educación superior en Barranquilla y el Atlántico, clasificados por nivel de formación y áreas de conocimiento."
  //       footerText="Fuente: SNIES"
  //       yearEnabled={false}
  //       transformData={transformData}
  //       legendPosition="bottom"
  //       dataLabelsEnabled={true}
  //       chartClassName="max-w-3xl px-1 md:px-3 pt-3"
  //       xAxisLabelVisible={true}
  //       yAxisLabelVisible={true}
  //       height={600}
  //       yAxisLabelFormatter={(val)=>{ return val; }}
  //       YAxisBorderShow={false}
  //       barColumnWidth={10}
  //       strokeWidth={2}
  //       strokeColor={["#FFFFFF"]}
  //       chartColors={["#27388D"]}
  //       legendEnable={false}
  //       xAxisLabelClassName="w-full inline-block text-xs whitespace-normal break-all overflow-hidden text-center px-auto mx-auto"
  //       yAxisLabelClassName="text-xs whitespace-normal break-all overflow-hidden"
  //       xAxisHideOverlapingLable={false}
  //       onDataPointClicked={(event, chartContext, config)=>{
  //         let filter = chartContext.w.globals.labels[config.dataPointIndex];
  //         filter = filter.toUpperCase();
  //         filter = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  //         setSelectedFilter(filter);
  //       }}/>
  //     <StyledChart
  //       dropdown1Selected={selectedFilter}
  //       serverId="oferta-institucional-ies-del-atlantico"
  //       chartType="bar"
  //       title="Directorio de Instituciones de Educación Superior"
  //       hintTitle="Directorio de Instituciones de Educación Superior"
  //       hintDescription="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
  //       footerText="Fuente: SNIES"
  //       yearEnabled={false}
  //       dropdown1Key="nivelDeFormacion"
  //       dropdown1Items={[
  //         {value: "UNIVERSITARIA", label: "UNIVERSITARIA"},
  //         {value: "TECNOLOGICA", label: "TECNOLOGICA"},
  //         {value: "MAESTRIA", label: "MAESTRIA"},
  //         {value: "FORMACION TECNICA PROFESIONAL", label: "FORMACION TECNICA PROFESIONAL"},
  //         {value: "ESPECIALIZACION UNIVERSITARIA", label: "ESPECIALIZACION UNIVERSITARIA"},
  //         {value: "ESPECIALIZACION TECNOLOGICA", label: "ESPECIALIZACION TECNOLOGICA"},
  //         {value: "ESPECIALIZACION MEDICO QUIRURGICA", label: "ESPECIALIZACION MEDICO QUIRURGICA"},
  //         {value: "DOCTORADO", label: "DOCTORADO"},
  //         {value: "TODOS", label: "TODOS"}
  //       ]}
  //       sortBy="orden"
  //       sortOrder="asc"
  //       transformData={transformData2}
  //       CustomChart={(props)=>(
  //         <LogoList tooltipNameKey="institucionDeEducacionSuperiorIes"  data={props.data} />
  //       )}
  //     />
  //   </div>
  // );
};


// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let categories = [];
//     let finalData = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].areaDeConocimiento)){
//         categories.push(data[i].areaDeConocimiento);
//         finalData.push({
//           name: data[i].areaDeConocimiento,
//           data: [
//             {x: "Doctorado",y: data[i].doctorado},
//             {x: "Especialización medico quirúrgica",y: data[i].especializacionMedicoQuirurgica},
//             {x: "Especialización tecnólogica",y: data[i].especializacionTecnologica},
//             {x: "Especialización universitaria",y: data[i].especializacionUniversitaria},
//             {x: "Formación técnica profesional",y: data[i].formacionTecnicaProfesional},
//             {x: "Maestria",y: data[i].maestria},
//             {x: "Tecnólogica",y: data[i].tecnologica},
//             {x: "Universitaria",y: data[i].universitaria}
//           ]
//         });
//       }
//     }
//     dataObj.series = finalData;
//   }
//   return dataObj;
// };

// const transformData2 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     dataObj.series = data;
//   }
//   return dataObj;
// };

export default OfertaAcademica;