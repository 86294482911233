import React from "react";

import StyledChart from "../../components/StyledChart";

const currentYear = 2022;
const yearsList = [];
for(let i=2;i<=10;i++){
  yearsList.push({
    label: currentYear-i,
    value: currentYear-i
  });
}

const PibDistribucionSectorial = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="distribucion-de-sectores-en-el-pib-del-atlantico"
        chartType="treemap"
        title="Participación de sectores en el PIB del Atlántico"
        hintTitle="Participación de sectores en el PIB del Atlántico"
        hintDescription="Muestra la distribución del PIB en los diferentes sectores que componen la economía del departamento del Atlántico, identificando la contribución de cada sector al PIB total a lo largo del tiempo."
        footerText="Fuente: DANE-Cuentas Nacionales"
        yearEnabled={true}
        yearKey="ano"
        years={yearsList}
        sortBy="milesDeMillonesCop"
        sortOrder="desc"
        transformData={transformData}
        legendPosition="top"
        legendHorizontalAlign="center"
        dataLabelsEnabled={true}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        height={400}
        strokeColor={["#FFFFFF"]}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        treemapEnableShades={false}
        treemapDistributed={true}
        chartColors={["#27388D", "#F1D37C", "#97B3E5", "#F5B6A0", "#E3A3A3", "#758DFF", "#ADCDDE", "#FACAAA","#8AABE3","#B8CAE9","#ACEEF6","#FE6A6A"]}
        customTooltip={
          function({ seriesIndex, dataPointIndex, w}) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let color = w.config.colors.length < dataPointIndex+1 ? w.config.colors[0] : w.config.colors[dataPointIndex];
            return "<div class='text-sm w-80 overflow-hidden break-all whitespace-normal'>" +
            "<div class='px-2 py-2 inline-block max-w-full'><h5 class='font-semibold'>"+data.sector+"</h5></div>" +
            "<div class='h-px bg-gray border-0'></div>" +
            "<span class='px-3 mt-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>Miles de Millones COP:</b> " + data.y + "</span><br/>" +
            "<span class='px-3 mb-2 inline-block'><div style='background: "+color+"' class='w-5 h-3 inline-block mr-2'></div><b>% Participación:</b> " + data.participacion + "%</span>" +
            "</div>";
          }
        }/>
      <StyledChart
        serverId="participacion-por-departamentos-en-los-sectores-del-pib-nacional"
        chartType="treemap"
        dropdown1Key="actividad"
        dropdown1Items={[
          {value: "Agricultura, ganadería, caza, silvicultura y pesca", label: "Agricultura, ganadería, caza, silvicultura y pesca"},
          {value: "Explotación de minas y canteras", label: "Explotación de minas y canteras"},
          {value: "Industrías Manufactureras", label: "Industrías Manufactureras"},
          {value: "Suministro de electricidad, gas, vapor y aire acondicionado; distribución de agua; evacuación y tratamiento de aguas residuales, gestión de desechos y actividades de saneamiento ambiental", label: "Suministro de electricidad, gas, vapor y aire acondicionado"},
          {value: "Construcción", label: "Construcción"},
          {value: "Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas; transporte y almacenamiento; alojamiento y servicios de comida", label: "Comercio al por mayor y al por menor"},
          {value: "Información y comunicaciones", label: "Información y comunicaciones"},
          {value: "Actividades financieras y de seguros", label: "Actividades financieras y de seguros"},
          {value: "Actividades Inmobiliarias", label: "Actividades Inmobiliarias"},
          {value: "Actividades profesionales, científicas y técnicas; actividades de servicios administrativos y de apoyo", label: "Actividades profesionales, científicas y técnicas"},
          {value: "Administración pública y defensa; planes de seguridad social de afiliación obligatoria; educación; actividades de atención de la salud humana y de servicios sociales", label: "Administración pública y defensa"},
          {value: "Actividades artísticas, de entretenimiento y recreación y otras actividades de servicios; actividades de los hogares individuales en calidad de empleadores; actividades no diferenciadas de los hogares individuales como productores de bienes y servicios para uso propio", label: "Actividades artísticas, de entretenimiento y recreación"},
        ]}
        title="Participación por departamentos en sectores en el PIB Nacional"
        hintTitle="Evaluación de la participación de los principales departamentos de Colombia en el desempeño de los diferentes sectores económicos a nivel nacional, comparando su desempeño a lo largo del tiempo."
        footerText="Fuente: DANE-Cuentas Nacionales"
        yearEnabled={true}
        years={yearsList}
        yearKey="ano"
        transformData={transformData2}
        legendPosition="top"
        legendHorizontalAlign="center"
        dataLabelsEnabled={true}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        sortBy="valor"
        sortOrder="desc"
        strokeWidth={2}
        height={400}
        strokeColor={["#FFFFFF"]}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        treemapEnableShades={false}
        treemapDistributed={true}
        tooltipYTitleFormatter={(val) => val + "(Miles de millones de COP $)"}
        yAxisLabelFormatter={(val)=>{ return new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP",minimumFractionDigits: 0, maximumFractionDigits: 1}).format(val); }}
        chartColors={["#27388D", "#F1D37C", "#97B3E5", "#F5B6A0", "#E3A3A3", "#758DFF", "#ADCDDE", "#FACAAA","#8AABE3","#B8CAE9","#ACEEF6","#FE6A6A"]}
      />
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let seriesData = [];
    for(let i=0;i<data.length;i++){
      seriesData.push(
        {
          x: data[i].label,
          y: data[i].milesDeMillonesCop,
          sector:  data[i].sector,
          departamento: data[i].departamento,
          participacion: data[i].participacion
        }
      );
    }
    dataObj.series = [
      {
        data: seriesData
      }
    ];
  }
  return dataObj;
};

const transformData2 = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let seriesData = [];
    for(let i=0;i<data.length;i++){
      seriesData.push(
        {
          x: data[i].departamento,
          y: data[i].valor,
          indicador:  data[i].indicador,
          actividad: data[i].actividad
        }
      );
    }
    dataObj.series = [
      {
        data: seriesData
      }
    ];
  }
  return dataObj;
};

export default PibDistribucionSectorial;
