import React, { useEffect, useState } from "react";
import { Oval } from  "react-loader-spinner";
import axios from "../../api/axiosAuth";
import {  toast } from "react-toastify";
const CsvDataUpdateModal = (props) => {

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  const [dataArray,setDataArray] = useState([]);

  useEffect(()=>{
    let dataArrayTemp = [];
    for(let i=0;i<props.columns?.length;i++) {
      let dataValue = props.data[props.columns[i].value];
      if(props.columns[i].type == "date"){
        dataValue = dataValue.substr(0, 10);
      }
      dataArrayTemp[i] = {
        label: props.columns[i].label,
        key: props.columns[i].value,
        value: dataValue,
        nullable: props.columns[i].nullable ? true : false,
        error: null,
        type: props.columns[i].type
      };
    }
    setDataArray(dataArrayTemp);
  },[]);

  const handleSubmit = async () => {
    let isError = false;
    const dataArrayTemp = dataArray.slice();
    const uploadData = {};
    for(let i=0;i<dataArray.length;i++) {
      if(!dataArray[i].value && !dataArray[i].nullable) {
        dataArrayTemp[i].error = "Please enter "+dataArray[i].label;
        isError = true;
      } else if(dataArray[i].value && typeof(dataArray[i].value) === "string" && dataArray[i].value.trim().length == 0 && !dataArray[i].nullable) {
        dataArrayTemp[i].error = "Please enter "+dataArray[i].label;
        isError = true;
      } else {
        dataArrayTemp[i].error = null;
      }
      if(dataArray[i].type === "number"){
        uploadData[dataArrayTemp[i].key] = parseFloat(dataArrayTemp[i].value);
      }
      else if(dataArray[i].type === "date"){
        uploadData[dataArrayTemp[i].key] = new Date(dataArrayTemp[i].value).toISOString();
      }
      else{
        uploadData[dataArrayTemp[i].key] = dataArrayTemp[i].value;
      }
    }
    console.log(uploadData);
    setDataArray(dataArrayTemp);
    if(isError){
      return;
    }
    setLoading(true);
    try{
      const response = await axios.put((props.graphName ?  props.graphName.value : props.subCategory.value)+"/"+props.data.id,uploadData);
      toast.success(response.data.message);
      props.modalClose && props.modalClose();
    }
    catch(err) {
      if(!err?.response){
        setErrorMessage("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.errors) {
        const dataArrayTemp = dataArray.slice();
        for(let i=0;i<dataArrayTemp.length;i++){
          let errorMessage;
          for(let j=0;j<err.response.data.errors.length;j++){
            if(err.response.data.errors[j].property === dataArrayTemp[i].key){
              errorMessage = Object.values(err.response.data.errors[j].constraints).join();
              break;
            }
          }
          if(errorMessage){
            dataArrayTemp[i].error = errorMessage;
          }
        }
        setDataArray(dataArrayTemp);
      } else if(err.response.data?.message){
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage("Update failed, something went wrong. Please try again later.");
      }
      setLoading(false);
    }
  };
  
  return (
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Update Data</h3>
      </div>
      {
        dataArray.map((data,index)=>{
          return(
            <div key={index} className="w-full flex mt-1">
              <div className="flex-1">
                <label className="text-xs text-darkGray font-medium">{data.label}</label>
                <input type={data.type} className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder={data.label} value={data.value} onChange={(e)=>{
                  const dataArrayTemp = dataArray.slice();
                  dataArrayTemp[index].value = e.target.value;
                  setDataArray(dataArrayTemp);
                }}/>
                {
                  data.error && data.error.trim().length > 0 ? 
                    <p className="text-sm text-red mt-1 font-medium">{data.error}</p>
                    :
                    <></>
                }
              </div>
            </div>
          );
        })
      }
      {
        errorMessage && errorMessage.trim().length > 0 ? 
          <p className="text-tiny text-red font-medium mt-3">{errorMessage}</p>
          :
          <></>
      }
      <div className="border-gray pt-2 flex justify-end mt-5">
        {
          loading ? 
            <Oval
              height={35}
              width={35}
              color="#005AA9"
              wrapperClass="w-fit mr-6"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#B3B9D5"
              strokeWidth={4}
              strokeWidthSecondary={4} />
            :
            <div className="flex gap-3">
              <button className="text-tiny bg-gray px-3 py-1 rounded shadow" onClick={()=>{props.modalClose && props.modalClose();}}>Close</button>
              <button className="text-tiny bg-primary text-textColorSecondary px-3 py-1 rounded shadow" onClick={()=>{handleSubmit();}}>Update</button>
            </div>
        }
        
      </div>
    </div>
  );
};
export default CsvDataUpdateModal;