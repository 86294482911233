import React,{ useRef } from "react";

import StyledChart from "../../components/StyledChart";

const PobrezaPobrezaExtremayGni = () => {

  const chartRef = useRef();
  
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        chartRef={chartRef}
        serverId="pobreza-pobreza-extrema-y-gini-barranquilla-y-atlantico"
        dropdown1Items={[
          {value: "Barranquilla", label: "Barranquilla"},
          {value: "Atlántico", label: "Atlántico"},
        ]}
        dropdown1Key="territorio"
        chartType="line"
        title="Pobreza, Pobreza extrema y Gini: Barranquilla y Atlántico"
        hintTitle="Pobreza, Pobreza extrema y Gini: Barranquilla y Atlántico"
        hintDescription="Presenta información sobre la evolución del nivel de pobreza, pobreza extrema y desigualdad en la ciudad de Barranquilla y el departamento del Atlántico, permitiendo conocer la situación social de la población y el resultado del trabajo conjunto para mejorar la calidad de vida.
        Pobreza: Porcentaje de personas por debajo de la línea de pobreza.
        Pobreza Extrema: Porcentaje de personas por debajo de la línea de pobreza extrema.
        Gini: Valor entre 0 y 1, que representan igualdad absoluta y desigualdad absoluta respectivamente.
        "
        footerText="Fuente: DANE - GEIH"
        yearEnabled={false}
        transformData={transformData}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-4xl px-0 md:px-3 pt-3"
        strokeWidth={[0,0,4]}
        strokeColor={["transparent","transparent","#F1D37C"]}
        xAxisLabelVisible={true}
        chartColors={["#27388D","#8EBAD1","#F1D37C"]}
        legendEnable={true}
        yAxis={
          [
            {
              show: true,
              forceNiceScale: true,
              labels: {
                show: true,
                style:{
                  colors: "#27388D",
                  cssClass: "text-gray text-xxs md:text-xs lg:text-sm"
                },
                formatter: (val) => { return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val / 100)  : val ; },
              },
              title: {
                text: "Pobreza",
                style: {
                  color: "#27388D"
                }
              },
              axisBorder: {
                show: true,
                color: "#27388D",
              },
            },
            {
              show: true,
              opposite: true,
              labels: {
                show: true,
                style:{
                  colors: "#8EBAD1",
                  cssClass: "text-gray text-xxs md:text-xs lg:text-sm"
                },
                formatter: (val) => { return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val/100) : val; },
              },
              title: {
                text: "Pobreza Extrema",
                style: {
                  color: "#8EBAD1"
                }
              },
              axisBorder: {
                show: true,
                color: "#8EBAD1",
              },
            },
            {
              show: true,
              opposite: true,
              labels: {
                show: true,
                style:{
                  colors: "#F1D37C",
                  cssClass: "text-gray text-xxs md:text-xs lg:text-sm"
                },
                formatter: (val) => { return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; },
              },
              title: {
                text: "GINI",
                style: {
                  color: "#F1D37C"
                }
              },
              axisBorder: {
                show: true,
                color: "#F1D37C",
              },
              tickAmount: 6
            },
          ]
        }/>
      <StyledChart
        chartRef={chartRef}
        serverId="pobreza-pobreza-extrema-y-gini-principales-ciudades"
        dropdown1Items={[
          {value: "Pobreza", label: "Pobreza"},
          {value: "Pobreza Extrema", label: "Pobreza Extrema"},
          {value: "GINI", label: "GINI"},
        ]}
        dropdown1Key="indicador"
        chartType="line"
        title="Pobreza, Pobreza extrema y Gini: Ciudades Principales"
        hintTitle="Pobreza, Pobreza extrema y Gini: Ciudades Principales"
        hintDescription="Presenta información sobre el nivel de pobreza, pobreza extrema y desigualdad en las principales ciudades de Colombia, permitiendo conocer la situación social de la población y comparar los resultados en la gestión de brindar una mejor calidad de vida a sus territorios.
        Pobreza: Porcentaje de personas por debajo de la línea de pobreza.
        Pobreza Extrema: Porcentaje de personas por debajo de la línea de pobreza extrema.
        Gini: Valor entre 0 y 1, que representan igualdad absoluta y desigualdad absoluta respectivamente.
        "
        footerText="Fuente: DANE - GEIH"
        yearEnabled={false}
        transformData={transformData2}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-5 pt-3"
        yAxisTitleClassName="apexcharts-yaxis-title"
        height={450}
        yAxisTitleOffsetX={5}
        yAxisTitle="Porcentaje"
        strokeWidth={4}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(val/100) : val; }}
        chartColors={["#27388D","#8EBAD1","#F1D37C","#F9B68A","#97B3E5","#ACC7F6"]}
        legendEnable={true}
        onDataLoaded={(data)=>{
          if(data?.series && data.series.length > 1){
            for(let i=1;i<data.series.length;i++){
              chartRef?.current?.chart?.hideSeries(data.series[i].name);
            }
          }
        }}/>
    </div>
  );
};


const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let gini = [];
    let pobreza = [];
    let pobrezaExtrema = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
        pobreza.push(data[i].pobreza);
        pobrezaExtrema.push(data[i].pobrezaExtrema);
        gini.push(data[i].gini);
      }
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Pobreza",
        data: pobreza,
        type: "bar"
      },
      {
        name: "Pobreza Extrema",
        data: pobrezaExtrema,
        type: "bar"
      },
      {
        name: "GINI",
        data: gini,
        type: "line"
      }
    ];
  }
  return dataObj;
};

const transformData2 = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let barranquilla = [];
    let bogota = [];
    let bucaramanga = [];
    let cali = [];
    let cartagena = [];
    let medellin = [];
    let promNacional = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
        barranquilla.push(data[i].barranquilla);
        bogota.push(data[i].bogota);
        bucaramanga.push(data[i].bucaramanga);
        cali.push(data[i].cali);
        cartagena.push(data[i].cartagena);
        medellin.push(data[i].medellin);
        promNacional.push(data[i].promNacional);
      }
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Barranquilla",
        data: barranquilla,
      },
      {
        name: "Bogota",
        data: bogota,
      },
      {
        name: "Cali",
        data: cali,
      },
      {
        name: "Medellin",
        data: medellin,
      },
      {
        name: "Bucaramanga",
        data: bucaramanga,
      },
      {
        name: "Prom. Nacional",
        data: promNacional,
      },
      {
        name: "Cartagena",
        data: cartagena,
      }
    ];
  }
  return dataObj;
};

export default PobrezaPobrezaExtremayGni;
