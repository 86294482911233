
import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const EmailVerify = () => {

  const { auth } = useAuth();
  return (
    <>
      {
        auth?.user && auth.user.emailVerified ? 
          <Navigate to="/indicadores_sociales" replace/>
          :
          <div className="flex flex-1 relative">
            <img alt="" src="/images/Log in.jpg" className="w-[53.2%] object-cover hidden lg:block fixed" style={{ height: "calc(100vh - 4.1rem)"  }}/>
            <div className="w-[53.2%] hidden lg:block"></div>
            <div className="flex flex-1 items-center pt-24 flex-col h-full">
              <div className="w-full h-fit max-w-md pl-5 pr-5 md:pl-0 md:pr-0">
                <div className="w-100 bg-textColorSecondary shadow-md border border-lightGray rounded p-5 flex flex-col gap-3">
                  <h3 className="font-semibold text-base">Bienvenido a ProData</h3>
                  <p className="text-tiny">
                    Gracias por unirte! Acabamos de enviarte un mensaje
                    de confirmación a tu correo electrónico registrado.
                  </p>
                  <p className="text-tiny">
                    Haz clic en el enlace de confirmación del mensaje para completar tu registro.
                  </p>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default EmailVerify;