
import React,{ useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Oval } from  "react-loader-spinner"; 
import axios from "../api/axios";

const FORGOT_PASSWORD_URL = "/forget-password";

const ForgotPassword = () => {

  const { auth } = useAuth();

  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      setError();
      setProgress(true);
      const response = await axios.post(FORGOT_PASSWORD_URL,
        JSON.stringify({ email: email }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true
        }
      );
      setProgress(false);
      setEmail("");
      if(response.data.message){
        setSuccess(response.data.message);
      }
    }
    catch(err){
      if(!err?.response){
        setError("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.message) {
        setError(err.response.data.message);
      } else if (err.response?.status === 400){
        setError("Forgot password failed, Bad request");
      } else {
        setError("Forgot password failed, something went wrong. Please try again later.");
      }
      setProgress(false);
    }
  };

  return (
    <>
      {
        auth?.user ?
          <>
            {
              auth.user.emailVerified ? 
                <Navigate to="/indicadores_sociales" replace/>
                :
                <Navigate to="/auth/email_verify" replace/>
            }
          </> 
          :
          <form className="flex flex-1 relative justify-center pt-10" onSubmit={handleSubmit}>
            <div className="w-full px-5 max-w-lg flex flex-col">
              <h1 className="text-primary font-semibold text-lg">Ayuda con la contraseña</h1>
              <p className="mt-3">Escribe la dirección de correo electrónic asociado a tu cuenta de ProData.</p>
              <input value={email} onChange={(e)=>{setEmail(e.target.value);}} type="email" placeholder="Correo electrónico" className="text-tiny mt-5 px-3 py-3 bg-lightGray border border-gray rounded" required/>
              {
                error && error.trim().length > 0 ? 
                  <p className="text-small text-red mt-2">{error}</p>
                  :
                  <></>
              }
              {
                success && success.trim().length > 0 ? 
                  <p className="text-small text-[#4BB543] mt-2">{success}</p>
                  :
                  <></>
              }
              <p className=""></p>
              <div className="mt-7">
                { 
                  progress ? 
                    <Oval
                      height={35}
                      width={35}
                      color="#005AA9"
                      wrapperClass="w-fit mt-4 ml-6"
                      visible={true}
                      ariaLabel='oval-loading'
                      secondaryColor="#B3B9D5"
                      strokeWidth={4}
                      strokeWidthSecondary={4} />
                    :
                    <>
                      {
                        success && success.trim().length > 0 ? 
                          <button onClick={()=>{ navigate("/auth/login",{ replace: true });  }} className="bg-primary px-10 py-4 text-textColorSecondary font-semibold rounded text-tiny">Login</button>
                          :
                          <button className="bg-primary px-10 py-4 text-textColorSecondary font-semibold rounded text-tiny">Continuar</button>
                      }
                    </>
                }
              </div>
            </div>
          </form>
      }
    </>
  );
};

export default ForgotPassword;