import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { Oval } from "react-loader-spinner";
import axios from "../../api/axiosAuth";
import { toast } from "react-toastify";
const UserUpdateModal = (props) => {
  const [loading, setLoading] = useState(false);

  const [keyName, setKeyName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [parentKeyName, setParentKeyName] = useState("");
  const [index, setIndex] = useState("");

  const [keyNameError, setKeyNameError] = useState();
  const [statusError, setStatusError] = useState();
  const [parentKeyNameError, setParentKeyNameError] = useState();
  const [descriptionError, setDescriptionError] = useState();

  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setKeyName(props.data?.keyName);
    setStatus(props.data?.status);
    setParentKeyName(props.data?.parentKeyName);
    setIndex(props.data?.id);
    setDescription(props.data?.description);
  }, []);

  const handleSubmit = async () => {
    setKeyNameError();
    setStatusError();
    setParentKeyName();
    setDescriptionError();
    let isError = false;

    if (!status || status.trim() == "") {
      setStatusError("Please enter status");
      isError = true;
    }

    if (isError) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.put("indicadores-y-categorias/" + index, {
        status: status.trim(),
      });
      toast.success(response.data.message);
      props.modalClose && props.modalClose();
    } catch (err) {
      if (!err?.response) {
        setErrorMessage(
          "No server response, something went wrong. Please try again later."
        );
      } else if (err.response.data?.errors) {
        let isErrorSet = false;
        for (let i = 0; i < err.response.data.errors.length; i++) {
          let error = err.response.data.errors[i];
          if (error.property === "keyName") {
            setKeyNameError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "status") {
            setStatusError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "parent_id") {
            setParentKeyNameError(Object.values(error.constraints).join());
            isErrorSet = true;
          }
        }
        if (!isErrorSet && err.response.data?.message) {
          setErrorMessage(err.response.data.message);
        }
      } else if (err.response.data?.message) {
        setErrorMessage(err.response.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Update indicador o categoria </h3>
      </div>
      <div className="w-full flex mt-6 gap-2">
        <div className="flex-1">
          <input
            disabled
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            className="border border-gray rounded px-2 py-1 w-full text-tiny"
            placeholder="description"
          />
          {descriptionError && descriptionError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {descriptionError}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex mt-6 gap-2">
        <div className="flex-1">
          <input
            disabled
            value={keyName}
            onChange={(e) => {
              setKeyName(e.target.value);
            }}
            className="border border-gray rounded px-2 py-1 w-full text-tiny"
            placeholder="keyName"
          />
          {keyNameError && keyNameError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">{keyNameError}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex mt-6 gap-2">
        <div className="flex-1">
          <input
            disabled
            value={parentKeyName}
            onChange={(e) => {
              setKeyName(e.target.value);
            }}
            className="border border-gray rounded px-2 py-1 w-full text-tiny"
            placeholder="Id padre"
          />
          {parentKeyNameError && parentKeyNameError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {parentKeyNameError}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1 relative">
          <select
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            className="border border-gray rounded px-2 py-1 w-full text-tiny appearance-none cursor-pointer"
          >
            <option>status</option>
            <option value="active">activo</option>
            <option value="inactive">inactivo</option>
          </select>
          <BiChevronDown
            size={18}
            className="absolute right-2 top-1 text-darkGray"
          />
          {statusError && statusError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">{statusError}</p>
          ) : (
            <></>
          )}
        </div>
      </div>

      {errorMessage && errorMessage.trim().length > 0 ? (
        <p className="text-tiny text-red font-medium mt-3">{errorMessage}</p>
      ) : (
        <></>
      )}
      <div className="border-gray pt-2 flex justify-end mt-5">
        {loading ? (
          <Oval
            height={35}
            width={35}
            color="#005AA9"
            wrapperClass="w-fit mr-6"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#B3B9D5"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        ) : (
          <div className="flex gap-3">
            <button
              className="text-tiny bg-gray px-3 py-1 rounded shadow"
              onClick={() => {
                props.modalClose && props.modalClose();
              }}
            >
              Close
            </button>
            <button
              className="text-tiny bg-primary text-textColorSecondary px-3 py-1 rounded shadow"
              onClick={() => {
                handleSubmit();
              }}
            >
              Update
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default UserUpdateModal;
