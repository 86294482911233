import React, { useState, useEffect } from "react";
import axios from "../../api/axiosAuth";

const DocumentViewModal = (props) => {
  const [name, setName] = useState("");
  const [publishDate, setPublishDate] = useState();
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState();
  const [author, setAuthor] = useState();
  const [documentType, setDocumentType] = useState();
  const [fileType, setFileType] = useState();
  const [file, setFile] = useState();
  const [actionLink, setActionLink] = useState();

  const [thumbnailPreview, setThumbnailPreview] = useState();

  useEffect(() => {
    setName(props.data?.name);
    setDescription(props.data?.description);
    setPublishDate(new Date(props.data?.publishDate));
    if (props.data?.author?._id) setAuthor(props.data.author.name);
    if (props.data?.category?._id) setCategory(props.data.category.name);
    if (props.data?.documentType?._id)
      setDocumentType(props.data.documentType.name);
    if (props.data?.fileType) setFileType(props.data.fileType?.toUpperCase());
    //console.log(axios.defaults.baseURL +"/"+ props.data?.thumbnail);
    setFile(axios.defaults.baseURL + "/" + props.data?.file);
    setThumbnailPreview(axios.defaults.baseURL + "/" + props.data?.thumbnail);
    if (props.data?.action_link) {
      setActionLink(props.data.action_link);
    }
  }, []);

  return (
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Documento</h3>
      </div>
      <div className="w-full mt-3 flex justify-center items-center flex-col">
        <div className="flex justify-center items-center w-40 h-52 border border-gray border-dashed">
          <img
            className="w-100 h-100"
            src={thumbnailPreview}
            alt="Thumbnail preview"
          />
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <p className="text-tiny flex gap-2">
            <b>Nombre:</b>
            {name}
          </p>
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <p className="text-tiny flex gap-2">
            <b>Descripción:</b>
            {description}
          </p>
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <p className="text-tiny flex gap-2">
            <b>Categoría:</b>
            {category}
          </p>
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <p className="text-tiny flex gap-2">
            <b>Autor:</b>
            {author}
          </p>
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <p className="text-tiny flex gap-2">
            <b>Tipo de Documento:</b>
            {documentType}
          </p>
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <p className="text-tiny flex gap-2">
            <b>Fecha de Publicación:</b>
            {publishDate?.toLocaleDateString()}
          </p>
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <p className="text-tiny flex gap-2">
            <b>Tipo de archivo:</b>
            {fileType}
          </p>
        </div>
      </div>

      <div className="border-gray pt-2 flex justify-end mt-5">
        <div className="flex gap-3">
          <button
            className="text-tiny bg-gray px-3 py-1 rounded shadow"
            onClick={() => {
              props.modalClose && props.modalClose();
            }}
          >
            Cerrar
          </button>
          <a
            href={fileType !== "LINK" ? file : actionLink}
            className="text-tiny bg-primary text-textColorSecondary px-3 py-1 rounded shadow"
            target="_blank"
            rel="noreferrer"
            download
          >
            {fileType !== "LINK" ? "Descargar" : "Link"}
          </a>
        </div>
      </div>
    </div>
  );
};
export default DocumentViewModal;
