import React from "react";

import StyledChart from "../../components/StyledChart";

const PibPerCapita = () => {

  let chart1IsPercentage = false;
  let chart2IsPercentage = false;

  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="pib-per-capita-departamentos-region-caribe"
        chartType="bar"
        dropdown1Key="indicador"
        dropdown1Items={[
          {value: "PIB Per Cápita", label: "PIB Per Cápita"},
          {value: "% Crec. PIB pc", label: "% Crec. PIB pc"},
        ]}
        title="PIB per cápita departamentos región caribe"
        hintTitle="PIB per cápita departamentos región caribe"
        hintDescription="Análisis del PIB per cápita de los departamentos que componen la región Caribe colombiana, mostrando las diferencias entre los distintos departamentos y evaluando su evolución a lo largo del tiempo. Este indicador también se puede mostrar comparando las tasas de crecimiento del PIB per cápita."
        footerText="Fuente: DANE-Cuentas Nacionales"
        yearEnabled={false}
        transformData={transformData}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={0}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        height={400}
        yAxisTitle="Pesos colombianos"
        onFilterChange={(e,selectName)=>{
          if(selectName === "dropdown1" && e.value === "% Crec. PIB pc"){
            chart1IsPercentage = true;
          }
          else{
            chart1IsPercentage = false;
          }
        }}
        yAxisLabelFormatter={(val)=>{ return ( val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: (chart1IsPercentage ? "percent" : "currency" ),currency: "COP" , minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val/100) : val); }}
        chartColors={["#27388D", "#97B3E5", "#F5B6A0", "#8094F5", "#B8CAE9", "#ACC7F6", "#758DFF", "#8AABE3"]}
        legendEnable={true}
        YaxisTickAmount={6}
      />
      <StyledChart
        serverId="pib-per-capita-principales-territorios"
        chartType="bar"
        dropdown1Key="indicador"
        dropdown1Items={[
          {value: "PIB Per Cápita", label: "PIB Per Cápita"},
          {value: "% Crec. PIB pc", label: "% Crec. PIB pc"},
        ]}
        title="PIB per cápita: Principales Departamentos y Colombia"
        hintTitle="PIB per cápita: Principales Departamentos y Colombia"
        hintDescription="Presenta la evolución del PIB per cápita en los principales departamentos de Colombia, mostrando las diferencias entre ellos y evaluando su evolución a lo largo del tiempo. Este indicador también se puede mostrar comparando las tasas de crecimiento del PIB per cápita."
        footerText="Fuente: DANE-Cuentas Nacionales"
        yearEnabled={false}
        transformData={transformData2}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={0}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        height={400}
        YAxisBorderShow={false}
        yAxisTitle="Pesos colombianos"
        onFilterChange={(e,selectName)=>{
          if(selectName === "dropdown1" && e.value === "% Crec. PIB pc")
            chart2IsPercentage = true;
          else
            chart2IsPercentage = false;
        }}
        yAxisLabelFormatter={(val)=>{ return ( val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: (chart2IsPercentage ? "percent" : "currency" ), currency: "COP", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(chart2IsPercentage ? val/100 : val) : val); }}
        chartColors={["#27388D", "#97B3E5", "#F5B6A0", "#8094F5", "#B8CAE9", "#ACC7F6", "#758DFF", "#8AABE3"]}
        legendEnable={true}
        YaxisTickAmount={6}
      />
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let atlantico = [];
    let bolivar = [];
    let cesar = [];
    let cordoba = [];
    let laGuajira = [];
    let magdalena = [];
    let sanAndresProvidenciaYSantaCatalinaArchipielago = [];
    let sucre = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
        atlantico.push(data[i].atlantico);
        bolivar.push(data[i].bolivar);
        cesar.push(data[i].cesar);
        cordoba.push(data[i].cordoba);
        laGuajira.push(data[i].laGuajira);
        magdalena.push(data[i].magdalena);
        sanAndresProvidenciaYSantaCatalinaArchipielago.push(data[i].sanAndresProvidenciaYSantaCatalinaArchipielago);
        sucre.push(data[i].sucre);
      }
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Atlántico",
        data: atlantico
      },
      {
        name: "Bolívar",
        data: bolivar
      },
      {
        name: "Cesar",
        data: cesar
      },
      {
        name: "Córdoba",
        data: cordoba
      },
      {
        name: "La Guajira",
        data: laGuajira
      },
      {
        name: "Magdalena",
        data: magdalena
      },
      {
        name: "San Andrés, Providencia",
        data: sanAndresProvidenciaYSantaCatalinaArchipielago
      },
      {
        name: "Sucre",
        data: sucre
      },
    ];
  }
  return dataObj;
};

const transformData2 = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let colombia = [];
    let antioquia = [];
    let atlantico = [];
    let bogotaDC = [];
    let santander = [];
    let valleDelCauca = [];
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
        colombia.push(data[i].colombia);
        antioquia.push(data[i].antioquia);
        atlantico.push(data[i].atlantico);
        bogotaDC.push(data[i].bogotaDC);
        santander.push(data[i].santander);
        valleDelCauca.push(data[i].valleDelCauca);
      }
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Atlántico",
        data: atlantico
      },
      {
        name: "Antioquia",
        data: antioquia
      },
      {
        name: "Bogotá D.C.",
        data: bogotaDC
      },
      {
        name: "Santander",
        data: santander
      },
      {
        name: "Valle del Cauca",
        data: valleDelCauca
      },
      {
        name: "COLOMBIA",
        data: colombia
      }
    ];
  }
  return dataObj;
};

export default PibPerCapita;
