import React from "react";

import StyledChart from "../../components/StyledChart";
import useAuth from "../../hooks/useAuth";

const currentYear = 2022;
const yearsList = [];
for (let i = 4; i >= 0; i--) {
  yearsList.push({
    label: currentYear - i,
    value: currentYear - i,
  });
}
for (let i = 1; i < 5; i++) {
  yearsList.push({
    label: currentYear + i,
    value: currentYear + i,
  });
}

const PoblacionGeneroYEdad = () => {
  const {
    auth: { user },
  } = useAuth();

  return (
    <div className="w-full pt-5">
      <iframe
        src={`/api-dash/pandemo/${user?.dashChartsToken}`}
        title="Panorama demográfico"
        style={{ width: "100%", height: "1700px" }}
        scrolling="no"
      />
    </div>
  );
};

const PoblacionGeneroYEdadOld = () => {
  return (
    <div className="w-full pt-5">
      <StyledChart
        serverId="indicadores-sociales-poblacion-edad-y-genero-estructura-poblacional"
        dropdown1Key="territorio"
        dropdown1Items={[
          { value: "Barranquilla", label: "Barranquilla" },
          {
            value: "Barranquilla y Área Metropolitana",
            label: "Área metropolitana",
          },
          { value: "Atlántico", label: "Atlántico" },
        ]}
        chartType="bar"
        title="Estructura Poblacional"
        hintTitle="Estructura Poblacional"
        hintDescription="Representa la distribución de la población por edad y género en la ciudad de Barranquilla y el departamento del Atlántico a lo largo del tiempo, permitiendo conocer la estructura demográfica del territorio."
        footerText="Fuente: DANE Proyecciones población municipal"
        yearEnabled={true}
        yearKey="ano"
        sortBy="edadMinima"
        sortOrder="desc"
        transformData={transformData}
        legendPosition="top"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-2xl"
        yAxisTitle="Edades"
        yAxisTitleClassName="text-xxs md:text-sm"
        yAxisLabelVisible={true}
        strokeWidth={1}
        strokeColor={["#FFFFFF"]}
        tooltipYFormatter={(val) => {
          return Math.abs(val);
        }}
        stacked={true}
        horizontalBar={true}
        legendEnable={true}
        years={yearsList}
        customTooltip={function ({ dataPointIndex, w }) {
          var data1 = w.globals.initialSeries[0].data[dataPointIndex];
          var data2 = w.globals.initialSeries[1].data[dataPointIndex];
          return (
            "<div class='text-sm w-56 overflow-hidden break-all whitespace-normal flex flex-col'>" +
            "<div class='px-2 py-1 inline-block w-full bg-darkGray'><h5 class='font-semibold'>" +
            data1.x +
            "</h5></div>" +
            "<div class='h-px bg-gray border-0'></div>" +
            "<span class='px-3 mt-2 inline-block'><div style='background: " +
            w.config.colors[0] +
            "' class='w-5 h-3 inline-block mr-2'></div><b>" +
            w.globals.initialSeries[0].name +
            ":</b> " +
            new Intl.NumberFormat("es-CO", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }).format(Math.abs(data1.y)) +
            "</span>" +
            "<span class='px-3 mt-2 inline-block'><div style='background: " +
            w.config.colors[1] +
            "' class='w-5 h-3 inline-block mr-2'></div><b>" +
            w.globals.initialSeries[1].name +
            ":</b> " +
            new Intl.NumberFormat("es-CO", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }).format(Math.abs(data2.y)) +
            "</span>" +
            "<span class='px-3 mt-2 mb-2 inline-block'><b>Total:</b> " +
            new Intl.NumberFormat("es-CO", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }).format(Math.abs(data1.total)) +
            "</span>" +
            "</div>"
          );
        }}
      />
      <div className="w-full flex flex-col lg:flex-row gap-5 pt-5">
        <div className="flex-1">
          <StyledChart
            serverId="indicadores-sociales-poblacion-edad-y-genero-poblacion-por-genero"
            dropdown1Key="territorio"
            dropdown1Items={[
              { value: "Barranquilla", label: "Barranquilla" },
              {
                value: "Barranquilla y Área Metropolitana",
                label: "Área metropolitana",
              },
              { value: "Atlántico", label: "Atlántico" },
            ]}
            chartType="pie"
            title="Población por género"
            hintTitle="Población por género"
            hintDescription="Indica el número y la participación de habitantes y según su sexo en la ciudad de Barranquilla y el departamento del Atlántico a lo largo del tiempo."
            footerText="Fuente: DANE Proyecciones población municipal"
            yearEnabled={true}
            yearKey="ano"
            transformData={transformDataPiChart}
            labels={["Mujeres", "Hombres"]}
            legendPosition="right"
            legendHorizontalAlign="right"
            dataLabelsEnabled={true}
            wrapperClassName=""
            chartClassName="max-w-sm pt-3"
            legendEnable={true}
            years={yearsList}
          />
        </div>
        <div className="flex-1">
          <StyledChart
            serverId="indicadores-sociales-poblacion-edad-y-genero-poblacion-por-estratos-barranquilla"
            dropdown1Key="estrato"
            dropdown1Items={[
              { value: "Estrato 1", label: "Estrato 1" },
              { value: "Estrato 2", label: "Estrato 2" },
              { value: "Estrato 3", label: "Estrato 3" },
              { value: "Estrato 4", label: "Estrato 4" },
              { value: "Estrato 5", label: "Estrato 5" },
              { value: "Estrato 6", label: "Estrato 6" },
              { value: "Sin estrato", label: "Sin estrato" },
            ]}
            chartType="bar"
            title="Población por estratos Barranquilla"
            hintTitle="Población por estratos Barranquilla"
            hintDescription="Muestra la distribución de la población por estratos socioeconómicos en la ciudad de Barranquilla, permitiendo conocer la composición de la población en grupos de edades por nivel de ingresos."
            footerText="Fuente: DANE GEIH Abril 2022"
            yearEnabled={false}
            sortBy="edadMinima"
            sortOrder="desc"
            transformData={transformDataPositiveBarChart}
            chartClassName="max-w-md pt-3"
            wrapperClassName=""
            chartColors={["#27388D"]}
            yAxisTitle="Edades"
            yAxisLabelVisible={true}
            barColumnHeight={"50%"}
            horizontalBar={true}
          />
        </div>
      </div>
    </div>
  );
};

const transformDataPiChart = (data) => {
  const dataObj = {
    series: [],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    dataObj.series = [data[0].mujeres, data[0].hombres];
  }
  return dataObj;
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: [
      "85+",
      "80-84",
      "75-79",
      "70-74",
      "65-69",
      "60-64",
      "55-59",
      "50-54",
      "45-49",
      "40-44",
      "35-39",
      "30-34",
      "25-29",
      "20-24",
      "15-19",
      "10-14",
      "5-9",
      "0-4",
    ],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    let categories = [];
    let males = [];
    let females = [];
    for (let i = 0; i < data.length; i++) {
      let category = data[i].edadMinima;
      if (data[i].edadMaxima && data[i].edadMaxima != "") {
        category = category + "-" + data[i].edadMaxima;
      } else {
        category = category + "+";
      }
      if (!categories.includes(category)) {
        categories.push(category);
        males.push({
          x: category,
          y: data[i].hombre,
          total: data[i].total,
        });
        females.push({
          x: category,
          y: data[i].mujer * -1,
          total: data[i].total,
        });
      }
    }
    if (categories.length > 0) {
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Mujeres",
        data: females,
      },
      {
        name: "Hombres",
        data: males,
      },
    ];
  }
  return dataObj;
};

const transformDataPositiveBarChart = (data) => {
  const dataObj = {
    series: [],
    categories: ["75+", "60-74", "45-59", "30-44", "15-29", "0-14"],
  };
  data = data?.data?.data;
  let labelName = "Población";
  if (data && data.length > 0) {
    let categories = [];
    let totalPopulation = [];
    for (let i = 0; i < data.length; i++) {
      let category = data[i].edadMinima;
      if (data[i].edadMaxima && data[i].edadMaxima != "") {
        category = category + "-" + data[i].edadMaxima;
      } else {
        category = category + "+";
      }
      if (!categories.includes(category)) {
        categories.push(category);
        totalPopulation.push(data[i].poblacion);
      }
    }
    if (categories.length > 0) {
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: labelName,
        data: totalPopulation,
      },
    ];
  }
  return dataObj;
};

export default PoblacionGeneroYEdad;
