import React,{ useRef } from "react";
import useAuth from "../../hooks/useAuth";

const PanoramaPobrezaDesigualdad = () => {

  const chartRef = useRef();
  const {
    auth: { user },
  } = useAuth();
  
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <div className="w-full pt-5 flex flex-col">
        <div className="w-full h-fit">
          <iframe
            src={`/api-dash/pobreza/${user?.dashChartsToken}`}
            title="Conectividad a Internet"
            style={{ width: "100%", height: "2600px" }}
            scrolling="no"
          />
        </div>
      </div>
    </div>
  );
};

export default PanoramaPobrezaDesigualdad;
