import React from "react";
import { FcLock } from "react-icons/fc";

const PremiumAcessRequire = () => {
  return (
    <div className="flex flex-1 justify-center items-center">
      <div className="h-fit w-fit flex flex-col items-center gap-4">
        <FcLock size={60} className=""/>
        <h1 className="font-semibold text-xl text-center">Esta es una función es exclusiva para las<br />empresas miembro de ProBarranquilla.</h1>
        <a href="https://wa.link/2uacc1" target="_blank" rel="noreferrer" className="bg-secondary text-textColorSecondary px-2 py-2 rounded shadow hover:shadow-none">
                          Solicitar usuario PRO
        </a>
      </div>
    </div>
  );
};

export default PremiumAcessRequire;