
import React,{ useState } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Oval } from  "react-loader-spinner"; 
import axios from "../api/axios";
import {  toast } from "react-toastify";

const RESET_PASSWORD_URL = "/reset-password";

const ResetPassword = () => {

  const { auth } = useAuth();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState();
  const [error, setError] = useState();

  const [progress, setProgress] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      setPasswordError();
      setConfirmPasswordError();
      let isError = false;
      if(!password || password.length == 0){
        isError = true;
        setPasswordError("Please enter password");
      }
      if(!confirmPassword || confirmPassword.length == 0){
        isError = true;
        setConfirmPasswordError("Please enter confirm password");
      }
      if(confirmPassword !== password){
        isError = true;
        setConfirmPasswordError("Confirm password doesn't match with password");
      }
      if(isError){
        return;
      }
      setProgress(true);
      const response = await axios.post(RESET_PASSWORD_URL+"/"+id,
        JSON.stringify({ password: password, confirmPassword: confirmPassword }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true
        }
      );
      setProgress(false);
      setPassword("");
      setConfirmPassword("");
      if(response.data.message){
        toast.success(response.data.message);
        navigate("/auth/login",{ replace: true });
      }
    }
    catch(err){
      if(!err?.response){
        setError("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.message) {
        setError(err.response.data.message);
      } else if (err.response?.status === 400){
        setError("Forgot password failed, Bad request");
      } else {
        setError("Forgot password failed, something went wrong. Please try again later.");
      }
      setProgress(false);
    }
  };

  return (
    <>
      {
        auth?.user ?
          <>
            {
              auth.user.emailVerified ? 
                <Navigate to="/indicadores_sociales" replace/>
                :
                <Navigate to="/auth/email_verify" replace/>
            }
          </> 
          :
          <form className="flex flex-1 relative justify-center pt-10" onSubmit={handleSubmit}>
            <div className="w-full px-5 max-w-lg flex flex-col">
              <h1 className="text-primary font-semibold text-lg">Crear nueva contraseña</h1>
              <p className="mt-3 text-tiny">Solicitaremos esta contrasena cada vez que inicies sesión.</p>
              <input value={password} onChange={(e)=>{setPassword(e.target.value);}} type="password" placeholder="Nueva contraseña" className="text-tiny mt-5 px-3 py-3 bg-lightGray border border-gray rounded" required/>
              {
                passwordError && passwordError.trim().length > 0 ? 
                  <p className="text-small text-red mt-1">{passwordError}</p>
                  :
                  <></>
              }
              <input value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value);}} type="password" placeholder="Confirmar contrasena" className="text-tiny mt-5 px-3 py-3 bg-lightGray border border-gray rounded" required/>
              {
                confirmPasswordError && confirmPasswordError.trim().length > 0 ? 
                  <p className="text-small text-red mt-1">{confirmPasswordError}</p>
                  :
                  <></>
              }
              {
                error && error.trim().length > 0 ? 
                  <p className="text-small text-red mt-3">{error}</p>
                  :
                  <></>
              }
              <p className=""></p>
              <div className="mt-7">
                { 
                  progress ? 
                    <Oval
                      height={35}
                      width={35}
                      color="#005AA9"
                      wrapperClass="w-fit mt-4 ml-6"
                      visible={true}
                      ariaLabel='oval-loading'
                      secondaryColor="#B3B9D5"
                      strokeWidth={4}
                      strokeWidthSecondary={4} />
                    :
                    <button className="bg-primary px-10 py-4 text-textColorSecondary font-semibold rounded text-tiny">Guardar los cambios e iniciar sesión</button>
                }
              </div>
              <h2 className="text-primary font-semibold text-lg mt-5">Consejos para una contraseña segura:</h2>
              <ul className="text-tiny mt-3 flex flex-col gap-2">
                <li>* Utiliza al menos 8 caracteres, es mejor si es una combinación de cifras y letras.</li>
                <li>* No utilices la misma contraseña que ya has utilizado con nosotros antes.</li>
                <li>* No utilice palabras del diccionario, su nombre, dirección de correo electrónico, número de teléfono móvil u otra información personal que puede obtener fácilmente.</li>
                <li>* No utilices la misma contraseña para varias cuentas online.</li>
              </ul>
            </div>
          </form>
      }
    </>
  );
};

export default ResetPassword;