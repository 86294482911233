
import React,{ useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { IoSearch } from "react-icons/io5";
import { Oval } from "react-loader-spinner";
import axios, { BASE_URL } from "../../api/axiosAuth";
import UserCreateModal from "./UserCreateModal";
import UserUpdateModal from "./UserUpdateModal";
import useModal from "../../hooks/useModal";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

const Users = () => {

  const columns = [
    {
      name: "Nombre",
      selector: row => row.firstName + ( row.lastName ? " "+row.lastName : ""),
      sortable: true,
    },
    {
      name: "Correo electrónico",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "Teléfono",
      selector: row => row.telephone,
      sortable: true,
    },
    {
      name: "Sector",
      selector: row => row.sector,
      sortable: true,
    },
    {
      name: "Organización",
      selector: row => row.organization,
      sortable: true,
    },
    {
      name: "Tipo de usuario",
      selector: row => row.subscription,
      sortable: true,
    },
    {
      name: "Acción",
      cell:(row)=>(<div className="flex gap-2">
        <button className="bg-primary p-2 rounded" onClick={()=>{
          setModalView(<UserUpdateModal data={row} modalClose={()=>{setModalVisibility(false);setModalCancelable(true);}} />);
          setModalVisibility(true);
          setModalCancelable(false);
        }}><BsPencil size={17} color={"#ffffff"}/></button>
        <button className="bg-secondary p-2 rounded" onClick={()=>{
          setModalView(<DeleteConfirmationModal deleteUrl={"users/"+row.id} modalClose={()=>{setModalVisibility(false);setModalCancelable(true);}} />);
          setModalVisibility(true);
          setModalCancelable(false);
        }}><RiDeleteBin6Line size={17} color={"#ffffff"}/></button>
      </div>),
      sortable: false,
    },
  ];

  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [sortBy, setSortBy] = useState();
  const [sortDirection, setSortDirection] = useState();

  const [ searchQuery, setSearchQuery ] = useState("");
  const [ searchTerm, setSearchTerm ] = useState(searchQuery);

  const { setModalView ,setModalVisibility, setModalCancelable } = useModal();

  const fetchData = async (page, per_page, sort_by, sort_direction,search) => {
    setTableLoading(true);
    axios.get("/users/all",{
      params: {
        pagination: true,
        page: page,
        limit: per_page,
        ...(sort_by && { sortBy: sort_by }),
        ...(sort_direction && { sortOrder: sort_direction }),
        filterQuery:{
          ...( ( search && search.trim().length !=0 ) && { filter: search } ),
        }
      }
    }).then((data)=>{
      data = data?.data;
      setTableLoading(false);
      setTableData(data.data);
      setTotalRows(data.paginationDetails.totalDocs);
      //setTotalRows(result.total);
    }).catch(()=>{
      setTableLoading(false);
      //setError(err);
    });
  };


const getDownload = async () => {
  try {
    const response = await axios.post('/users/export-to-excel', {}, {
      responseType: 'arraybuffer' // Indica que esperamos un array de bytes como respuesta
    });

    // Crear un Blob a partir de los datos recibidos
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Crear un enlace temporal (anchor) en el DOM y hacer clic en él para iniciar la descarga
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'usuarios.xlsx'); // Nombre del archivo
    document.body.appendChild(link);
    link.click();

    // Limpiar el enlace y la URL creada
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error al descargar el archivo:', error);
  }
};


  useEffect(() => {
    fetchData(1,perPage,sortBy,sortDirection,searchTerm);
    return () => {};
  }, []);

  const handleSort = async (column, sort_direction) => {
    /// reach out to some API and get new data using or sortField and sortDirection
    // e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}
    setSortBy(column);
    setSortDirection(sort_direction);
    fetchData(1,perPage,column,sort_direction,searchTerm);
  };

  const handlePageChange = page => {
    fetchData(page, perPage,sortBy,sortDirection,searchTerm);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPerPage(newPerPage);
    fetchData(1, newPerPage,sortBy,sortDirection,searchTerm);
  };

  // update 'term' value after 1 second from the last update of 'debouncedTerm'
  useEffect(() => {
    const timer = setTimeout(() => setSearchTerm(searchQuery), 1000);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  // submit a new search
  useEffect(() => {
    if(searchTerm !== ""){
      fetchData(1,perPage,sortBy,sortDirection,searchTerm);
    }
    else{
      fetchData(1,perPage,sortBy,sortDirection);
    }
  }, [searchTerm]);

  const handleSearchQueryChange = (val) => {
    setSearchQuery(val);
  };

  return (
    <div className="flex flex-1 relative pt-3 border-collapse">
      <div className="border border-gray w-full rounded">
        <div className="w-full border-b border-gray p-5 flex justify-between flex-wrap">
          <h3 className="text-tiny font-semibold text-black">Registro de usuarios</h3>
          <div className="self-end flex gap-3">
            <div className="border border-gray p-1 flex rounded">
              <input value={searchQuery} onChange={(e)=>{handleSearchQueryChange(e.target.value);}} className="text-tiny outline-none" placeholder="Buscar"/>
              <IoSearch className=""/>
            </div>
            <button onClick={()=>{
              setModalView(<UserCreateModal modalClose={()=>{setModalVisibility(false);setModalCancelable(true);}} />);
              setModalVisibility(true);
              setModalCancelable(false);
            }} className="text-sm bg-primary text-textColorSecondary px-3 rounded">
              Crear Usuario
            </button>
            <button onClick={()=>{
              getDownload()
            }} className="text-sm bg-primary text-textColorSecondary px-3 rounded">
              Descargar Usuarios
            </button>
          </div>
          
        </div>
        <DataTable
          className=""
          columns={columns}
          data={tableData}
          striped
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          persistTableHead={true}
          paginationRowsPerPageOptions={[10,25,50,75,100]}
          responsive
          progressComponent={<div className="h-36 flex justify-center items-center"><Oval height={40} color="#005AA9" secondaryColor="#B3B9D5"/></div>}
          progressPending={tableLoading}
          onSort={handleSort}
          customStyles={{ rows: { style:{ backgroundColor: "#FFFFFF" }, stripedStyle: { ":nth-child(odd)": { backgroundColor: "#F7F7F7" }} } }}
        />
      </div>
      
    </div>
  );
};

export default Users;