import React, { useState } from "react";
import { Oval } from  "react-loader-spinner";
import axios from "../api/axiosAuth";
import {  toast } from "react-toastify";

const ChangePasswordModal = (props) => {

  const [loading, setLoading] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [oldPasswordError, setOldPasswordError] = useState( );
  const [newPasswordError, setNewPasswordError] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState();

  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async () => {
    setOldPasswordError();
    setNewPasswordError();
    setConfirmPasswordError();
    let isError = false;
    if(!oldPassword || oldPassword == ""){
      setOldPasswordError("Please enter old password");
      isError = true;
    }
    if(!newPassword || newPassword == ""){
      setNewPasswordError("Please enter new password");
      isError = true;
    }
    if(!confirmPassword || confirmPassword == ""){
      setConfirmPasswordError("Please enter confirm password");
      isError = true;
    }
    if(newPassword !== confirmPassword){
      setConfirmPasswordError("Confirm password doesn't match with new password");
      isError = true;
    }
    if(isError){
      return;
    }
    setLoading(true);
    try{
      const response = await axios.put("/users/change-password",{
        oldPassword: oldPassword,
        password: newPassword,
        confirmPassword: confirmPassword,
      });
      toast.success(response.data.message);
      props.modalClose && props.modalClose();
    }
    catch(err) {
      if(!err?.response){
        setErrorMessage("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.errors) {
        let isErrorSet = false;
        for(let i = 0;i<err.response.data.errors.length;i++){
          let error = err.response.data.errors[i];
          if(error.property === "oldPassword"){
            setOldPasswordError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "newPassword"){
            setNewPasswordError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "confirmPassword"){
            setConfirmPasswordError(Object.values(error.constraints).join());
            isErrorSet = true;
          }
        }
        if(!isErrorSet && err.response.data?.message){
          setErrorMessage(err.response.data.message);
        }
      } else if(err.response.data?.message){
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage("Password change failed, something went wrong. Please try again later.");
      }
      setLoading(false);
    }
  };
  
  return (
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Change Password</h3>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input value={oldPassword} onChange={(e)=>{setOldPassword(e.target.value);}} type="password" className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="Current Password" />
          {
            oldPasswordError && oldPasswordError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{oldPasswordError}</p>
              :
              <></>
          }
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input value={newPassword} onChange={(e)=>{setNewPassword(e.target.value);}} type="password" className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="New Password" />
          {
            newPasswordError && newPasswordError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{newPasswordError}</p>
              :
              <></>
          }
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value);}} type="password" className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="Confirm Password" />
          {
            confirmPasswordError && confirmPasswordError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{confirmPasswordError}</p>
              :
              <></>
          }
        </div>
      </div>
      {
        errorMessage && errorMessage.trim().length > 0 ? 
          <p className="text-tiny text-red font-medium mt-3">{errorMessage}</p>
          :
          <></>
      }
      <div className="border-gray pt-2 flex justify-end mt-5">
        {
          loading ? 
            <Oval
              height={35}
              width={35}
              color="#005AA9"
              wrapperClass="w-fit mr-6"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#B3B9D5"
              strokeWidth={4}
              strokeWidthSecondary={4} />
            :
            <div className="flex gap-3">
              <button className="text-tiny bg-gray px-3 py-1 rounded shadow" onClick={()=>{props.modalClose && props.modalClose();}}>Close</button>
              <button className="text-tiny bg-primary text-textColorSecondary px-3 py-1 rounded shadow" onClick={()=>{handleSubmit();}}>Change</button>
            </div>
        }
        
      </div>
    </div>
  );
};
export default ChangePasswordModal;