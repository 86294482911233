import React from "react";

import StyledChart from "../../components/StyledChart";

const CostosDeArriendoYVentaDeInmuebles = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="costos-de-arriendo-y-ventas"
        chartType="bar"
        dropdown1Key="operacion"
        dropdown1Items={[
          {value: "VENTA", label: "Venta"},
          {value: "RENTA", label: "Renta"},
        ]}
        dropdown2Key="mercado"
        dropdown2Items={[
          {value: "TERRENOS", label: "Terrenos"},
          {value: "RETAIL", label: "Retail"},
          {value: "OFICINAS", label: "Oficinas"},
          {value: "INDUSTRIAL", label: "Industrial"},
        ]}
        title="Costos de arriendo y venta de inmuebles"
        hintTitle="Costos de arriendo y venta de inmuebles"
        hintDescription="Comparación de los precios por metro cuadrado de inmuebles o terrenos en el departamento del Atlántico. Incluye una detalle de los precios según el tipo de operación: venta o arriendo, así como también según el tipo de actividad comercial del inmueble."
        footerText="Fuente: COLLIERS"
        transformData={transformData}
        legendPosition="bottom"
        legendEnable={true}
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-4xl px-0 md:px-3 pt-3"
        strokeWidth={0}
        sortBy="periodo"
        sortOrder="asc"
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        yAxisTitle="($) Pesos Colombianos por m²"
        YAxisBorderShow={true}
        yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
        chartColors={["#27388D","#FACAAA","#F5DFA1","#ADCDDE","#97B3E5"]}
        barDistributed={false}
        YaxisTickAmount={7}
        barColumnWidth={45}
      />
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let series = {};
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].periodo)){
        categories.push(data[i].periodo);
      }
      if(series[data[i].detalle]){
        series[data[i].detalle].push(data[i].promedioCopM2);
      }
      else{
        series[data[i].detalle] = [ data[i].promedioCopM2 ];
      }
    }
    let seriesData = [];
    let seriesKeys = Object.keys(series);
    for(let i=0;i<seriesKeys.length;i++){
      seriesData[i] = {
        name: seriesKeys[i],
        data: series[seriesKeys[i]]
      };
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = seriesData;
  }
  return dataObj;
};


export default CostosDeArriendoYVentaDeInmuebles;