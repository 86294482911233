import React,{ useEffect, useRef } from "react";

// import StyledChart from "../../components/StyledChart";
let viz; 

const InversionPublicaPerCapita = () => {

  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/InversinPblica-ProData/InversinPblica?:language=es-ES&:display_count=n&:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);

  // const chartRef = useRef();
  
  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
    // <div className="w-full pt-5">
    //   <StyledChart
    //     chartRef={chartRef}
    //     serverId="indicadores-sociales-inversion-publica-per-capita-inversion-publica-per-capita"
    //     chartType="line"
    //     title="Inversión Pública per cápita"
    //     hintTitle="Inversión Pública per cápita"
    //     hintDescription="Presenta el monto total de inversión pública en diferentes áreas en las principales ciudades de Colombia, dividido entre la cantidad de habitantes, ofreciendo información relevante para conocer la distribución de recursos y la calidad de vida de la población."
    //     footerText="Fuente: CHIP (Contaduría), DANE"
    //     yearEnabled={false}
    //     transformData={transformData}
    //     legendPosition="bottom"
    //     legendHorizontalAlign="center"
    //     dataLabelsEnabled={false}
    //     chartClassName="max-w-5xl px-0 md:px-5 pt-5"
    //     yAxisTitle="Pesos colombianos"
    //     height={450}
    //     strokeWidth={4}
    //     xAxisLabelVisible={true}
    //     yAxisLabelVisible={true}
    //     yAxisLabelFormatter={(val)=>{ return new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP",minimumFractionDigits: 0, maximumFractionDigits: 1}).format(val); }}
    //     chartColors={["#27388D","#8EBAD1","#F1D37C","#F9B68A","#97B3E5","#ACC7F6"]}
    //     onDataLoaded={(data)=>{
    //       if(data?.series && data.series.length > 1){
    //         for(let i=1;i<data.series.length;i++){
    //           chartRef?.current?.chart?.hideSeries(data.series[i].name);
    //         }
    //       }
    //     }}
    //     legendEnable={true}/>
    // </div>
  );
};


// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let categories = [];
//     let barranquilla = [];
//     let bogota = [];
//     let cali = [];
//     let medellin = [];
//     let cartagena = [];
//     let santaMarta = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].ano)){
//         categories.push(data[i].ano);
//         barranquilla.push(data[i].barranquilla);
//         bogota.push(data[i].bogota);
//         cali.push(data[i].cali);
//         medellin.push(data[i].medellin);
//         cartagena.push(data[i].cartagena);
//         santaMarta.push(data[i].santaMarta);
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = [
//       {
//         name: "Barranquilla",
//         data: barranquilla,
//       },
//       {
//         name: "Cali",
//         data: cali,
//       },
//       {
//         name: "Bogotá",
//         data: bogota,
//       },
//       {
//         name: "Medellin",
//         data: medellin,
//       },
//       {
//         name: "Cartagena",
//         data: cartagena,
//       },
//       {
//         name: "Santa Marta",
//         data: santaMarta,
//       }
//     ];
//   }
//   return dataObj;
// };

export default InversionPublicaPerCapita;