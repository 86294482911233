import React from "react";
import Modal from "react-modal";
import useModal from "../hooks/useModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "91%",
    maxWidth: "30rem"
  },
  overlay: {
    background: "rgba(166,166,166,0.65)",
    zIndex: 999
  }
};

const ModalCustom = () => {
  const { modalVisible, setModalVisibility, modalView, modalCancelable } = useModal();

  return(
    <Modal
      
      isOpen={modalVisible}
      onRequestClose={()=>{
        if(modalCancelable){
          setModalVisibility(false);
        }
      }}
      style={customStyles}>
      {modalView}
    </Modal>
  );
};

export default ModalCustom;