import React from "react";

import StyledChart from "../../components/StyledChart";

const MatriculadosYCertificadosEnFormacionParaElTrabajo = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="matriculados-y-certificados-en-ifl-region-caribe"
        chartType="bar"
        title="Histórico de inscritos y certificados de la Región Caribe"
        hintTitle="Histórico de inscritos y certificados de la Región Caribe"
        hintDescription="Datos sobre la cantidad de personas matriculadas y certificadas en los institutos de formación para el trabajo en las principales ciudades de la región Caribe de Colombia durante los últimos años."
        footerText="Fuente: SIET"
        yearEnabled={false}
        tabKey="abc"
        tabItems={[
          {value: "matriculados", label: "Matriculados"},
          {value: "certificados", label: "Certificados"}
        ]}
        transformData={transformData}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={0}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        height={400}
        yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
        barColumnWidth={50}
        chartColors={["#27388D", "#F9B68A", "#F1D37C", "#8EBAD1", "#F19A7C", "#8094F5", "#B8CAE9", "#97B3E5"]}
        legendEnable={true}
        YaxisTickAmount={6}
      />
      <StyledChart
        serverId="matriculados-y-certificados-en-ifl-principales-ciudades"
        chartType="bar"
        title="Histórico de inscritos y Certificados de Principales Ciudades"
        hintTitle="Histórico de inscritos y Certificados de Principales Ciudades"
        hintDescription="Información sobre la cantidad de personas matriculadas y certificadas en los institutos de formación para el trabajo en las principales ciudades de Colombia durante los últimos años."
        footerText="Fuente: SIET"
        yearEnabled={false}
        tabKey="abc"
        tabItems={[
          {value: "matriculados", label: "Matriculados"},
          {value: "certificados", label: "Certificados"}
        ]}
        transformData={transformData2}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={0}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        height={400}
        barColumnWidth={50}
        yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
        chartColors={["#27388D", "#97B3E5", "#F5B6A0", "#8094F5", "#B8CAE9", "#ACC7F6", "#758DFF", "#8AABE3"]}
        legendEnable={true}
        YaxisTickAmount={6}
      />
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let series = {};
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
      }
      if(!series[data[i].etiquetasDeFila]){
        series[data[i].etiquetasDeFila] = [];
      }
      series[data[i].etiquetasDeFila].push({
        matriculados: data[i].matriculados,
        certificados: data[i].certificados,
      });
    }
    let matriculadosData = [];
    let certificadosData = [];
    let seriesKeys = Object.keys(series);
    for(let i=0;i<seriesKeys.length;i++){
      let matriculadosTempData = [];
      let certificadosTempData = [];
      if(series[seriesKeys[i]]){
        for(let j=0;j<series[seriesKeys[i]].length;j++){
          matriculadosTempData.push(series[seriesKeys[i]][j].matriculados);
          certificadosTempData.push(series[seriesKeys[i]][j].certificados);
        }
      }
      matriculadosData[i] = {
        name: seriesKeys[i],
        data: matriculadosTempData
      };
      certificadosData[i] = {
        name: seriesKeys[i],
        data: certificadosTempData
      };
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = {
      matriculados: matriculadosData,
      certificados: certificadosData
    };
  }
  else{
    dataObj.series = {
      matriculados: { data: [] },
      certificados: { data: [] }
    };
  }
  return dataObj;
};

const transformData2 = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let series = {};
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
      }
      if(!series[data[i].municipio]){
        series[data[i].municipio] = [];
      }
      series[data[i].municipio].push({
        matriculados: data[i].matriculados,
        certificados: data[i].certificados,
      });
    }
    let matriculadosData = [];
    let certificadosData = [];
    let seriesKeys = Object.keys(series);
    for(let i=0;i<seriesKeys.length;i++){
      let matriculadosTempData = [];
      let certificadosTempData = [];
      if(series[seriesKeys[i]]){
        for(let j=0;j<series[seriesKeys[i]].length;j++){
          matriculadosTempData.push(series[seriesKeys[i]][j].matriculados);
          certificadosTempData.push(series[seriesKeys[i]][j].certificados);
        }
      }
      matriculadosData[i] = {
        name: seriesKeys[i],
        data: matriculadosTempData
      };
      certificadosData[i] = {
        name: seriesKeys[i],
        data: certificadosTempData
      };
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = {
      matriculados: matriculadosData,
      certificados: certificadosData
    };
  }
  else{
    dataObj.series = {
      matriculados: { data: [] },
      certificados: { data: [] }
    };
  }
  return dataObj;
};

export default MatriculadosYCertificadosEnFormacionParaElTrabajo;