import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoMdArrowDropdown, IoIosMenu, IoMdClose, IoIosArrowForward } from "react-icons/io";
import { RiQuestionMark } from "react-icons/ri";
import { AiOutlinePoweroff } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import axios from "../api/axiosAuth";
import {  toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import { AiFillLock } from "react-icons/ai";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";

const ProfileMenu = ({ profileImageError, user, logout, setProfileImageError }) => {
  return (
    <div className="flex text-black font-medium flex-col items-centers pt-6 w-full items-center">
      <>
        {
          profileImageError || !user.profilePicture ? 
            <FaUser size={35} className="border rounded-full pt-1 text-primary"/>
            :
            <img src={user.profilePicture} alt="User profile" className="text-primary border w-10 h-10 rounded-full object-scale-down" onError={()=>{setProfileImageError(true);}}/>
        }
      </>
      <span className="mt-3 mb-6 text-xs w-full break-words pl-4 pr-4 text-center">{user.email}</span>
      {
        user.role === "USER" && user.subscription !== "PREMIUM" ? 
          <div className="border-t border-gray w-full pt-4 pb-4 flex justify-center items-center">
            <a href="https://wa.link/2uacc1" target="_blank" rel="noreferrer" className="text-sm bg-secondary text-textColorSecondary pl-2 pr-2 pt-1 pb-1 rounded shadow hover:shadow-none">
                          Solicitar usuario PRO
            </a>
          </div>
          :
          <></>
      }
      {
        user.role === "ADMIN" ? 
          <MenuItem className="border-t border-gray w-full pt-4 pb-4 flex justify-center items-center">
            <NavLink to="admin" className="text-sm bg-secondary text-textColorSecondary pl-2 pr-2 pt-1 pb-1 rounded shadow hover:shadow-none">
                          ADMIN PANEL
            </NavLink>
          </MenuItem>
          :
          <></>
      }
      <MenuItem className="w-full">
        <NavLink to="user/profile" className="border-t border-gray w-full pl-7 pr-7 pt-3 pb-3 text-xs font-medium flex flex-row items-center hover:bg-primary hover:text-textColorSecondary">
          <FaUser className="inline-block mr-1"/>
          <span className="inline-block text-left w-fit flex-grow">Mi cuenta</span>
          <IoIosArrowForward className="inline-block ml-1 text-gray"/>
        </NavLink>
      </MenuItem>
      <MenuItem className="w-full">
        <a href="https://wa.link/2uacc1" className="border-t border-gray w-full pl-7 pr-7 pt-3 pb-3 text-xs font-medium flex flex-row items-center hover:bg-primary hover:text-textColorSecondary">
          <RiQuestionMark className="inline-block mr-1"/>
          <span className="inline-block text-left w-fit flex-grow">Ayuda/Comentarios</span>
          <IoIosArrowForward className="inline-block ml-1 text-gray"/>
        </a>
      </MenuItem>
      <MenuItem className="w-full">
        <button onClick={logout} className="border-t border-gray w-full pl-7 pr-4 pt-3 pb-3 text-xs font-medium flex flex-row items-center text-red hover:bg-gray rounded-b">
          <AiOutlinePoweroff className="inline-block mr-1"/>
          <span className="inline-block text-left w-fit">Cerrar sesión</span>
        </button>
      </MenuItem>
    </div>
  );
};

const Navbar = (props) => {

  const { setAuth } = useAuth();

  const logout = () => {
    const loader = document.querySelector(".loader");
    loader.classList.remove("loader--hide");
     
    axios.post("logout").then(() => {
      setAuth();
      loader.classList.add("loader--hide");
    }).catch((err)=>{
      if(err.response?.status === 401) {
        setAuth();
      } else if(err.response?.message){
        toast.error(err.response.message);
      } else if(err.message){
        toast.error(err.message);
      } else {
        toast.error("Coudn't logout! Please try again.");
      }
      loader.classList.add("loader--hide");
    });
  };

  const [ profileImageError, setProfileImageError] = useState(false);
  const [ isMobileMenuOpen, openMobileMenu ] = useState(false);

  const user = props.user;
  const isSticky = props.sticky ? true : false;

  return (
    <nav className={"flex items-center justify-between lg:justify-start flex-wrap bg-primary pr-3 shadow-md" + ( isSticky ? " sticky top-0 z-50" : "" ) }>
      <span className="pl-2 pr-2 lg:hidden text-textColorSecondary" onClick={()=>{openMobileMenu(true);}} onKeyPress={()=>{}} role="button" tabIndex={0}>
        <IoIosMenu size={30}/>
      </span>
      <a href="https://probarranquilla.org" className="flex items-center flex-shrink-0 lg:ml-[16.3rem] pt-3 pb-4">
        <img alt="ProBarranquilla Logo" src="/images/LOGO_PROBARRANQUILLA.png" className="mr-[16px]" style={{ width: "5.5rem" }}/>
        <img alt="ProData Logo" src="/images/logo-prodata.png" style={{ width: "8rem" }}/>
      </a>
      <div className="hidden lg:flex gap-7 text-textColorSecondary justify-center items-center ml-auto text-tiny pr-11" style={{ letterSpacing: "0.005rem" }}>
        {
          user ? 
            <>
              <NavLink to="/charts" className={({ isActive }) => ("flex items-center gap-2 hover:text-secondary") + (isActive ? " text-secondary" : "")}>Indicadores { user?.subscription !== "PREMIUM" ? <AiFillLock size={15}/> : <></> }</NavLink>
              <NavLink to="/maps" className={({ isActive }) => ("flex items-center gap-2 hover:text-secondary") + (isActive ? " text-secondary" : "")}>Mapas</NavLink>
              <NavLink to="/repository" className={({ isActive }) => ("flex items-center gap-2 hover:text-secondary") + (isActive ? " text-secondary" : "")}>Repositorio</NavLink>
            </>
            :
            <></>
        }
        {
          user ?
            <div>
              <Menu
                transition={true}
                align="end"
                menuClassName={"bg-textColorSecondary shadow rounded w-56 z-50 block"}
                menuButton={
                  <MenuButton className="flex justify-center items-center gap-1" style={{ marginLeft: "3.2rem" }}>
                    {
                      profileImageError || !user.profilePicture ? 
                        <FaUser size={35} className="border rounded-full pt-1"/>
                        :
                        <img src={user.profilePicture} alt="User profile" className="border w-10 h-10 rounded-full object-scale-down" onError={()=>{setProfileImageError(true);}}/>
                    }
                    <IoMdArrowDropdown className="text-textColorSecondary"/>
                  </MenuButton>
                }>
                <ProfileMenu profileImageError={profileImageError} user={user} logout={logout} setProfileImageError={setProfileImageError}/>
              </Menu>
            </div>
            :
            <div className="ml-12 w-14">

            </div>
        }
                
      </div>
      {
        user ?
          <div>
            <Menu
              transition={true}
              align="end"
              menuClassName={"bg-textColorSecondary shadow rounded w-56 z-50 block"}
              menuButton={
                <MenuButton className="flex lg:hidden justify-center items-center gap-1 text-textColorSecondary text-tiny">
                  {
                    profileImageError || !user.profilePicture ? 
                      <FaUser size={35} className="border rounded-full pt-1"/>
                      :
                      <img src={user.profilePicture} alt="User profile" className="border w-10 h-10 rounded-full object-scale-down" onError={()=>{setProfileImageError(true);}}/>
                  }
                  <IoMdArrowDropdown className="text-textColorSecondary"/>
                </MenuButton>
              }>
              <ProfileMenu profileImageError={profileImageError} user={user} logout={logout} setProfileImageError={setProfileImageError}/>
            </Menu>
          </div>
          :
          <div className="lg:hidden">

          </div>
      }
      <div className={ (isMobileMenuOpen ? "flex " : "hidden ") + "text-textColorSecondary transition-all flex-col pt-2 fixed bg-primary shadow-lg h-screen top-0 left-0 items-start z-50"}>
        <IoMdClose size={20} className='text-textColorSecondary mb-2 self-end mr-3' onClick={()=>{openMobileMenu(false);}}/>
        {
          user ?
            <>
              <NavLink to="/charts" className={({ isActive }) => ("pt-2 pb-2 pl-4 pr-10 flex gap-2") + (isActive ? " text-secondary" : "")}>Indicadores{ user?.subscription !== "PREMIUM" ? <AiFillLock size={15}/> : <></> }</NavLink>
              <NavLink to="/maps" className={({ isActive }) => ("pt-2 pb-2 pl-4 pr-10 flex gap-2") + (isActive ? " text-secondary" : "")}>Mapas</NavLink>
              <NavLink to="/repository" className={({ isActive }) => ("pt-2 pb-2 pl-4 pr-10 flex gap-2") + (isActive ? " text-secondary" : "")}>Repositorio</NavLink>
            </>
            :
            <></>
        }
      </div>
    </nav>
  );
};

export default Navbar;