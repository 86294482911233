import React, { useEffect, useRef } from "react";

// import StyledChart from "../../components/StyledChart";

let viz;

const InstitucionesYProgramasDeFormacionParaElTrabajo = () => {
  const exportaRef = useRef();

  useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/Formacinparaeltrabajo-ProData/Formacinlaboral?:language=es-ES&amp;:display_count=n&amp;:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);
  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
    // <div className="w-full pt-5 flex flex-col gap-5">
    //   <StyledChart
    //     serverId="instituciones-de-formacion-para-el-trabajo-principales-ciudades"
    //     chartType="bar"
    //     dropdown1Items={[
    //       {value: "institucionesDeFormacionLaboralIfl", label: "Instituciones de Formación Laboral (IFL)"},
    //       {value: "programasDeFormacion", label: "Programas de formación"},
    //       {value: "programasInstituciones", label: "Programas / Instituciones"}
    //     ]}
    //     dropdown1Select={true}
    //     title="Instituciones y programas de formación para el trabajo Principales Ciudades"
    //     hintTitle="Instituciones y programas de formación para el trabajo Principales Ciudades"
    //     hintDescription="Indica la cantidad de institutos y programas de formación para el trabajo en las principales ciudades de Colombia. Además de presentar una estadística sobre el número de programas promedio por cada institución."
    //     footerText="Fuente: SIET"
    //     transformData={transformData}
    //     dataLabelsEnabled={false}
    //     chartClassName="max-w-5xl px-0 md:px-3 pt-3"
    //     barColumnHeight={30}
    //     barColumnWidth={30}
    //     height={300}
    //     strokeWidth={2}
    //     strokeColor={"transparent"}
    //     xAxisLabelVisible={true}
    //     yAxisLabelVisible={true}
    //     YAxisBorderShow={true}
    //     horizontalBar={true}
    //     barDistributed={true}
    //     chartColors={["#27388D", "#F1D37C", "#8EBAD1", "#F9B68A", "#F2B566", "#6F96DA", "#97B3E5", "#ACC7F6"]}
    //   />
    //   <StyledChart
    //     serverId="instituciones-de-formacion-para-el-trabajo-region-caribe"
    //     chartType="bar"
    //     dropdown1Items={[
    //       {value: "instituciones", label: "Instituciones"},
    //       {value: "programas", label: "Programas"}
    //     ]}
    //     dropdown1Select={true}
    //     title="Instituciones de formación para el trabajo - Región Caribe"
    //     hintTitle="Instituciones de formación para el trabajo - Región Caribe"
    //     hintDescription="Indica la cantidad de institutos y programas de formación para el trabajo en las principales ciudades de la región Caribe de Colombia."
    //     footerText="Fuente: SIET"
    //     transformData={transformData2}
    //     dataLabelsEnabled={false}
    //     chartClassName="max-w-5xl px-0 md:px-3 pt-3"
    //     barColumnHeight={30}
    //     barColumnWidth={30}
    //     strokeWidth={2}
    //     strokeColor={"transparent"}
    //     xAxisLabelVisible={true}
    //     yAxisLabelVisible={true}
    //     YAxisBorderShow={true}
    //     horizontalBar={true}
    //     barDistributed={true}
    //     chartColors={["#27388D", "#F1D37C", "#8EBAD1", "#F9B68A", "#F2B566", "#6F96DA", "#97B3E5", "#ACC7F6"]}
    //   />
    // </div>
  );
};

// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let categories = [];
//     let institucionesDeFormacionLaboralIfl = [];
//     let programasDeFormacion = [];
//     let programasInstituciones = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].municipio)){
//         categories.push(data[i].municipio);
//         institucionesDeFormacionLaboralIfl.push(data[i].institucionesDeFormacionLaboralIfl);
//         programasDeFormacion.push(data[i].programasDeFormacion);
//         programasInstituciones.push(data[i].programasInstituciones);
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = {
//       institucionesDeFormacionLaboralIfl: [
//         { name: "Instituciones de Formación Laboral (IFL)" ,data: institucionesDeFormacionLaboralIfl }
//       ],
//       programasDeFormacion: [
//         { name: "Programas de formación" ,data: programasDeFormacion }
//       ],
//       programasInstituciones: [
//         { name: "Programas/Instituciones" ,data: programasInstituciones }
//       ],
//     };
//   }
//   else{
//     dataObj.series = {
//       institucionesDeFormacionLaboralIfl: [
//         { name: "Instituciones de Formación Laboral (IFL)" ,data: [] }
//       ],
//       programasDeFormacion: [
//         { name: "Programas de formación" ,data: [] }
//       ],
//       programasInstituciones: [
//         { name: "Programas/Instituciones" ,data: [] }
//       ],
//     };
//   }
//   return dataObj;
// };

// const transformData2 = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   if(data && data.length > 0) {
//     let categories = [];
//     let instituciones = [];
//     let programas = [];
//     for(let i=0;i<data.length;i++){
//       if(!categories.includes(data[i].etiquetasDeFila)){
//         categories.push(data[i].etiquetasDeFila);
//         instituciones.push(data[i].instituciones);
//         programas.push(data[i].programas);
//       }
//     }
//     if(categories.length > 0){
//       dataObj.categories = categories;
//     }
//     dataObj.series = {
//       instituciones: [
//         { name: "Instituciones" ,data: instituciones }
//       ],
//       programas: [
//         { name: "Programas" ,data: programas }
//       ]
//     };
//   }
//   else{
//     dataObj.series = {
//       instituciones: [
//         { name: "Instituciones" ,data: [] }
//       ],
//       programas: [
//         { name: "Programas" ,data: [] }
//       ]
//     };
//   }
//   return dataObj;
// };

export default InstitucionesYProgramasDeFormacionParaElTrabajo;