import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaBook } from "react-icons/fa";
import { Collapse } from "react-collapse";
import { IoMapSharp } from "react-icons/io5";

const AdminMenu = () => {
  const [adminstrationMenuOpen, setAdminstrationMenuOpen] = useState(false);
  const [repositoryMenuOpen, setRepositoryMenuOpen] = useState(false);

  return (
    <div className="w-fit shrink-0 bg-lightGray h-full pt-4 pl-1 pr-1 md:pt-10 md:pl-3 md:pr-3 md:w-64 overflow-y-scroll no_scroll">
      <ul className="flex flex-col gap-1 text-tiny text-primary font-medium pb-5">
        <li>
          <NavLink
            to={"/admin/users"}
            className={({ isActive }) => [
              "flex flex-row gap-2 p-2 md:p-4 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
              isActive
                ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                : "",
            ]}
          >
            {() => (
              <>
                <FaUser size={17} />
                <span className="hidden md:block cursor-pointer">Usuarios</span>
              </>
            )}
          </NavLink>
        </li>
        <li>
          <button
            onClick={() => {
              setAdminstrationMenuOpen(!adminstrationMenuOpen);
            }}
            className={
              "flex w-full flex-row gap-2 p-2 md:p-4 items-center rounded-xl hover:bg-primary hover:bg-opacity-5"
            }
          >
            <HiOutlineChartSquareBar size={17} />
            <span className="hidden md:block cursor-pointer">
              Administrador
            </span>
            <RiArrowDropDownLine
              size={22}
              className={
                "ml-2 transition-transform" +
                (adminstrationMenuOpen ? " rotate-180" : "")
              }
            />
          </button>
        </li>
        <Collapse isOpened={adminstrationMenuOpen}>
          <ul className="pl-8">
            <li>
              <NavLink
                to={"/admin/indicadores_sociales"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Indicadores sociales
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/desempeno_economico"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Desempeno ecónomico
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/talento_humano"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Talento humano
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/costos_de_instalacion_y_operacion"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Costos de instalación y operación
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/conectividad_y_acceso_a_mercados"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Conectividad y acceso a mercados
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/tejido_empresarial_y_desempeno_sectorial"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Tejido empresarial y desempeño sectorial
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/entorno_de_negocios_y_competitividad"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Entorno de negocios y competitividad
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/gestion_de_atraccion_y_retencion_de_inversiones"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Gestión de atracción y retención de inversiones
                    </span>
                  </>
                )}
              </NavLink>
            </li>
          </ul>
        </Collapse>
        <li>
          <button
            onClick={() => {
              setRepositoryMenuOpen(!repositoryMenuOpen);
            }}
            className={
              "flex w-full flex-row gap-2 p-2 md:p-4 items-center rounded-xl hover:bg-primary hover:bg-opacity-5"
            }
          >
            <FaBook size={17} />
            <span className="hidden md:block cursor-pointer">Repositorio</span>
            <RiArrowDropDownLine
              size={22}
              className={
                "ml-2 transition-transform" +
                (repositoryMenuOpen ? " rotate-180" : "")
              }
            />
          </button>
        </li>
        <Collapse isOpened={repositoryMenuOpen}>
          <ul className="pl-8">
            <li>
              <NavLink
                to={"/admin/repository/categories"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Categoria tematica
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/repository/authors"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Autors
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin/repository/document_types"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Tipo de documento
                    </span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                end
                to={"/admin/repository"}
                className={({ isActive }) => [
                  "flex flex-row gap-2 p-2 md:p-2 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                  isActive
                    ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                    : "",
                ]}
              >
                {() => (
                  <>
                    <span className="hidden md:block cursor-pointer">
                      Documentos
                    </span>
                  </>
                )}
              </NavLink>
            </li>
          </ul>
        </Collapse>
        <li>
          <NavLink
            to={"/admin/maps"}
            className={({ isActive }) => [
              "flex flex-row gap-2 p-2 md:p-4 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
              isActive
                ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                : "",
            ]}
          >
            {() => (
              <>
                <IoMapSharp size={17} />
                <span className="hidden md:block cursor-pointer">Mapas</span>
              </>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"/admin/indicadoresYcategorias"}
            className={({ isActive }) => [
              "flex flex-row gap-2 p-2 md:p-4 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
              isActive
                ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                : "",
            ]}
          >
            {() => (
              <>
                <FaUser size={17} />
                <span className="hidden md:block cursor-pointer">
                  Indicadores y categorías
                </span>
              </>
            )}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default AdminMenu;
