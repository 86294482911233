import React from "react";

import StyledChart from "../../components/StyledChart";

const MovilizacionDePasajeros = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="historico-de-entradas-y-salidas-nacionales-e-internacionales-de-pasajeros-atlantico"
        chartType="area"
        dropdown1Key="ciudad"
        dropdown1Items={[
          {value: "Barranquilla", label: "Barranquilla"},
          {value: "Armenia", label: "Armenia"},
          {value: "Base Aérea Tolemaida", label: "Base Aérea Tolemaida"},
          {value: "Bogotá", label: "Bogotá"},
          {value: "Bucaramanga", label: "Bucaramanga"},
          {value: "Cali", label: "Cali"}
        ]}
        dropdown2Key="tipoDePasajero"
        dropdown2Items={[
          {value: "Nacional", label: "Nacional"},
          {value: "Internacional", label: "Internacional"},
        ]}
        title="Histórico de entradas y salídas nacionales e internacionales de pasajeros - Atlántico"
        hintTitle="Histórico de entradas y salídas nacionales e internacionales de pasajeros - Atlántico"
        hintDescription="Histórico de datos sobre el número de pasajeros nacionales e internacionales que entraron y salieron del departamento del Atlántico. Los datos se pueden desglosar por ciudad y tipo de pasajero."
        footerText="Fuente: Aeronáutica civil, aeropuerto Ernesto Cortissoz"
        yearEnabled={false}
        sortBy="fecha"
        sortOrder="asc"
        transformData={transformData}
        legendPosition="bottom"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-5xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        YAxisBorderShow={true}
        yAxisLabelFormatter={(val)=>{ return val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val) : val; }}
        XAxisType="datetime"
        fill={{
          type: "gradient",
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          } 
        }}
        YaxisTickAmount={5}
        EnableBrushChart={true}
        chartColors={["#27388D","#ADCDDE"]}
        brushTargetType={"line"}
        height={250}
      />
    </div>
  );
};
// function generateDayWiseTimeSeries(baseval, count, yrange) {
//   var i = 0;
//   var series = [];
//   while (i < count) {
//     var x = baseval;
//     var y =
//       Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

//     series.push([x, y]);
//     baseval += 2.592e+9;
//     i++;
//   }
//   return series;
// }

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let entradas = [];
    let salidas = [];
    for(let i=0;i<data.length;i++){
      entradas.push({
        x: new Date(data[i].fecha),
        y: data[i].entradas
      });
      salidas.push({
        x: new Date(data[i].fecha),
        y: data[i].salidas
      });
    }
    dataObj.series = [
      {
        name: "Entradas",
        data: entradas
      },
      {
        name: "Salidas",
        data: salidas
      }
    ];
  }
  return dataObj;
};

export default MovilizacionDePasajeros;