import React from "react";

import StyledChart from "../../components/StyledChart";
import useAuth from "../../hooks/useAuth";
import RetencionInversiones from "./retencion_inversiones";

const currentYear = 2022;
const yearsList1 = [];
for (let i = 1; i <= 14; i++) {
  yearsList1.push({
    label: currentYear - i,
    value: currentYear - i,
  });
}

const AtraccionYRetencionDeInversiones = () => {
  const {
    auth: { user },
  } = useAuth();

  return (
    <RetencionInversiones />
    // <div className="w-full pt-5 flex flex-col gap-5">
    //   <iframe
    //     src={`/api-dash/inversiones/${user?.dashChartsToken}`}
    //     title="Atracción y retención de inversiones"
    //     style={{ width: "100%", height: "1700px" }}
    //     scrolling="no"
    //   />
    // </div>
  );
};

const AtraccionYRetencionDeInversionesOld = () => {
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        serverId="historico-de-inversiones"
        chartType="bar"
        title="Histórico de Inversión Directa para el Atlántico"
        hintTitle="Histórico de Inversión Directa para el Atlántico"
        hintDescription="Presenta los datos históricos de inversión privada directa en el territorio del Atlántico registrado a través de la gestión de atracción y retención de inversión de ProBarranquilla."
        footerText="Fuente: ProBarranquilla"
        transformData={transformData}
        dataLabelsEnabled={true}
        chartClassName="max-w-6xl px-0 md:px-3 pt-3"
        strokeWidth={2}
        strokeColor="transparent"
        height={450}
        xAxisLabelVisible={true}
        yAxisLabelVisible={false}
        roundedBar={true}
        XAxisPosition="top"
        dataLabelPosition="top"
        dataLabelYOffset={-20}
        dataLabelFontSize="9px"
        barColumnWidth={50}
        dataLabelColor={["#000000"]}
        yAxisLabelFormatter={(val) => {
          return val && typeof val !== "string" && !isNaN(val)
            ? new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              }).format(val)
            : val;
        }}
        dataLabelFormatter={(val) => {
          return val && typeof val !== "string" && !isNaN(val)
            ? new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              }).format(val)
            : val;
        }}
        tooltipYFormatter={(val, { seriesIndex, dataPointIndex, w }) => {
          return w.globals.initialSeries[seriesIndex].data[dataPointIndex]
            .noProyectos;
        }}
        chartColors={[
          "#27388D",
          "#F1D37C",
          "#8EBAD1",
          "#F9B68A",
          "#F2B566",
          "#6F96DA",
          "#97B3E5",
          "#ACC7F6",
        ]}
      />
      <div className="w-full flex flex-col lg:flex-row gap-5 pt-5">
        <div className="flex-1">
          <StyledChart
            serverId="participacion-de-sectores-segun-monto-de-inversion"
            chartType="treemap"
            title="Participación de sectores según monto de inversión"
            hintTitle="Participación de sectores según monto de inversión"
            hintDescription="Indica la distribución de la inversión privada percibida por el departamento del Atlántico según el sector económico de los proyectos y/o empresas instaladas o ampliadas en el departamento a través de la gestión de ProBarranquilla."
            footerText="Fuente: ProBarranquilla"
            yearEnabled={true}
            years={yearsList1}
            yearKey="ano"
            sortBy="inversionUsd"
            sortOrder="desc"
            transformData={transformData2}
            dataLabelsEnabled={true}
            wrapperClassName=""
            chartClassName="max-w-full pt-3 px-3"
            strokeColor={["#FFFFFF"]}
            xAxisLabelVisible={true}
            yAxisLabelVisible={true}
            treemapDistributed={true}
            treemapEnableShades={false}
            yAxisLabelFormatter={(val) => {
              return val && typeof val !== "string" && !isNaN(val)
                ? new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }).format(val)
                : val;
            }}
            chartColors={[
              "#27388D",
              "#F1D37C",
              "#97B3E5",
              "#F5B6A0",
              "#E3A3A3",
              "#758DFF",
              "#ADCDDE",
              "#FACAAA",
              "#8AABE3",
              "#B8CAE9",
              "#ACEEF6",
              "#FE6A6A",
            ]}
            customTooltip={function ({ seriesIndex, dataPointIndex, w }) {
              var data =
                w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              return (
                "<div class='text-sm w-80 overflow-hidden break-all whitespace-normal'>" +
                "<span class='px-3 mt-2 inline-block'><b>Inversión USD:</b> " +
                (data.y && typeof data.y !== "string" && !isNaN(data.y)
                  ? new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                    }).format(data.y)
                  : data.y) +
                "</span><br/>" +
                "<span class='px-3 mt-2 inline-block'><b>No proyectos:</b> " +
                data.noProyectos +
                "</span><br/>" +
                "<span class='px-3 mt-2 mb-2 inline-block'><b>Empleos:</b> " +
                data.empleos +
                "</span>" +
                "</div>"
              );
            }}
          />
        </div>
        <div className="flex-1">
          <StyledChart
            serverId="tipo-de-proyectos"
            dropdown1Key="estrato"
            chartType="pie"
            title="Tipos de proyectos"
            hintTitle="Tipos de proyectos"
            hintDescription="Indica el porcentaje de proyectos que corresponden a instalaciones o ampliaciones de las actividades económicas en el departamento del Atlántico."
            footerText="Fuente: ProBarranquilla"
            yearEnabled={true}
            years={yearsList1}
            yearKey="ano"
            sortBy="edadMinima"
            sortOrder="desc"
            dataLabelsEnabled={true}
            transformData={transformData3}
            chartClassName="max-w-sm pt-3"
            wrapperClassName=""
            yAxisLabelFormatter={(val) => {
              return val && typeof val !== "string" && !isNaN(val)
                ? Intl.NumberFormat("es-CO", {
                    style: "decimal",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }).format(val)
                : val;
            }}
            dataLabelFormatter={(val) => {
              return val && typeof val !== "string" && !isNaN(val)
                ? Intl.NumberFormat("es-CO", {
                    style: "decimal",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }).format(val)
                : val;
            }}
            chartColors={["#F1D37C", "#27388D"]}
            yAxisTitle="Edades"
            yAxisLabelVisible={true}
            horizontalBar={true}
            legendEnable={true}
          />
        </div>
      </div>
      <StyledChart
        serverId="pais-de-origen-de-la-inversion"
        chartType="treemap"
        title="País de origen de la inversión"
        hintTitle="País de origen de la inversión"
        hintDescription="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        footerText="Fuente: ProBarranquilla"
        yearEnabled={true}
        years={yearsList1}
        yearKey="ano"
        transformData={transformData4}
        dataLabelsEnabled={true}
        wrapperClassName=""
        chartClassName="max-w-5xl pt-3 px-3"
        height={350}
        strokeColor={["#FFFFFF"]}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        treemapDistributed={true}
        treemapEnableShades={false}
        sortBy="inversionUsd"
        sortOrder="desc"
        chartColors={[
          "#27388D",
          "#F1D37C",
          "#97B3E5",
          "#F5B6A0",
          "#E3A3A3",
          "#758DFF",
          "#ADCDDE",
          "#FACAAA",
          "#8AABE3",
          "#B8CAE9",
          "#ACEEF6",
          "#FE6A6A",
        ]}
        customTooltip={function ({ seriesIndex, dataPointIndex, w }) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return (
            "<div class='text-sm w-80 overflow-hidden break-all whitespace-normal'>" +
            "<span class='px-3 mt-2 inline-block'><b>Inversión USD:</b> " +
            (data.y && typeof data.y !== "string" && !isNaN(data.y)
              ? new Intl.NumberFormat("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 1,
                }).format(data.y)
              : data.y) +
            "</span><br/>" +
            "<span class='px-3 mb-2 inline-block'><b>No proyectos:</b> " +
            data.noProyectos +
            "</span>" +
            "</div>"
          );
        }}
      />
    </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: [],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    let categories = [];
    let finalData = [];
    for (let i = 0; i < data.length; i++) {
      if (!categories.includes(data[i].ano)) {
        categories.push(data[i].ano);
        finalData.push({
          x: data[i].ano.toString(),
          y: data[i].inversionUsd,
          noProyectos: data[i].noProyectos,
        });
      }
    }
    if (categories.length > 0) {
      dataObj.categories = categories;
    }
    dataObj.series = [
      {
        name: "Proyectos",
        data: finalData,
      },
    ];
  }
  return dataObj;
};

const transformData2 = (data) => {
  const dataObj = {
    series: [],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    let finalData = [];
    for (let i = 0; i < data.length; i++) {
      finalData.push({
        x: data[i].sector,
        y: data[i].inversionUsd ? data[i].inversionUsd : 0,
        noProyectos: data[i].noProyectos,
        empleos: data[i].empleos,
      });
    }
    dataObj.series = [
      {
        data: finalData,
      },
    ];
  }
  return dataObj;
};

const transformData3 = (data) => {
  const dataObj = {
    series: [],
    labels: [],
    categories: [],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    let finalData = [];
    let labels = [];
    let categories = [];
    for (let i = 0; i < data.length; i++) {
      if (!categories.includes(data[i].tipoDeProyecto)) {
        categories.push(data[i].tipoDeProyecto);
        labels.push(data[i].tipoDeProyecto);
        finalData.push(data[i].inversionUsd);
      }
    }
    if (categories.length > 0) {
      dataObj.categories = categories;
      dataObj.labels = labels;
    }
    dataObj.series = finalData;
  }
  return dataObj;
};

const transformData4 = (data) => {
  const dataObj = {
    series: [],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    let finalData = [];
    for (let i = 0; i < data.length; i++) {
      finalData.push({
        x: data[i].pais,
        y: data[i].inversionUsd ? data[i].inversionUsd : 0,
        noProyectos: data[i].noProyectos,
      });
    }
    dataObj.series = [
      {
        data: finalData,
      },
    ];
  }
  return dataObj;
};

export default AtraccionYRetencionDeInversiones;
