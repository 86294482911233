import React,{ useState } from "react";

// import StyledChart from "../../components/StyledChart";
// import useAuth from "../../hooks/useAuth";

let viz;

const TasaGlobalDeParticipacion = () => {

  const exportaRef = React.useRef();

  React.useEffect(()=>{
    if(viz){
      viz.dispose();
    }
    const vizUrl = "https://public.tableau.com/views/MercadoLaboral_17176030265900/MercadoLaboral?:language=es-ES&:sid=&:display_count=n&:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current,vizUrl);
  },[]);

  // const [ dropdown1Items, setDropdown1Items ] = useState([
  //   {value: "18 a 28", label: "18 a 28"},
  //   {value: "29 a 40", label: "29 a 40"},
  //   {value: "41 a 60", label: "41 a 60"}
  // ]);

  // const {
  //   auth: { user },
  // } = useAuth();

  return (
    // <div className="w-full pt-5 flex flex-col gap-5">
    //   <StyledChart
    //     serverId="tgp-comparativo-de-ciudades-y-areas-metropolitanas"
    //     chartType="area"
    //     dropdown1Key="item"
    //     dropdown1Items={dropdown1Items}
    //     dropdown2Key="indicador"
    //     dropdown2Items={[
    //       {value: "Rango de edad", label: "Rango de edad"},
    //       {value: "Sexo", label: "Sexo"},
    //       {value: "Total", label: "Total"}
    //     ]}
    //     title="Tasa Global de Participación"
    //     hintTitle="Tasa Global de Participaciónr"
    //     hintDescription="Análisis del desempeño de la tasa global de participación trimestral en las principales ciudades de Colombia, desglosado por edad y género para identificar las brechas y desigualdades en el mercado laboral.
    //     TGP = Representa el porcentaje de población que se encuentra económicamente activa para trabajar, lo que significa que además de tener la edad necesaria, están interesados en ocuparse.
    //     "
    //     footerText="Fuente: DANE - GEIH"
    //     yearEnabled={false}
    //     sortBy="periodo"
    //     sortOrder="asc"
    //     transformData={transformData}
    //     legendPosition="bottom"
    //     legendHorizontalAlign="center"
    //     dataLabelsEnabled={false}
    //     chartClassName="max-w-5xl px-0 md:px-3 pt-3"
    //     strokeWidth={2}
    //     xAxisLabelVisible={true}
    //     yAxisLabelVisible={true}
    //     YAxisBorderShow={true}
    //     XAxisType="datetime"
    //     fill={{
    //       type: "gradient",
    //       gradient: {
    //         opacityFrom: 0.91,
    //         opacityTo: 0.1,
    //       } 
    //     }}
    //     YaxisTickAmount={2}
    //     EnableBrushChart={true}
    //     chartColors={["#27388D"]}
    //     brushTargetType={"line"}
    //     height={250}
    //     onFilterChange={(e,selectName)=>{
    //       if(selectName === "dropdown2"){
    //         if(e.value==="Rango de edad"){
    //           setDropdown1Items([
    //             {value: "18 a 28", label: "18 a 28"},
    //             {value: "29 a 40", label: "29 a 40"},
    //             {value: "41 a 60", label: "41 a 60"}
    //           ]);
    //         } else if(e.value==="Sexo"){
    //           setDropdown1Items([
    //             {value: "Hombres", label: "Hombres"},
    //             {value: "Mujeres", label: "Mujeres"}
    //           ]);
    //         } else if(e.value==="Total"){
    //           setDropdown1Items([
    //             {value: "Total", label: "Total"}
    //           ]);
    //         }
    //       }
    //     }}
    //   />
    // </div>
    // <div className="w-full pt-5 flex flex-col">
    //   <div className="w-full h-fit">
    //     <iframe
    //       src={`/api-dash/mercado_laboral/${user?.dashChartsToken}`}
    //       title="Conectividad a Internet"
    //       style={{ width: "100%", height: "2600px" }}
    //       scrolling="no"
    //     />
    //   </div>
    // </div>

    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{width:"100%", margin:"auto"}}>

        </div>
      </div>
    </div>
  );
};
// const transformData = (data) => {
//   const dataObj = {
//     series: [],
//     categories: []
//   };
//   data = data?.data?.data;
//   let seriesName;
//   if(data && data.length > 0) {
//     let finalData = [];
//     for(let i=0;i<data.length;i++){
//       finalData.push({
//         x: data[i].periodo,
//         y: data[i].tgp
//       });
//       seriesName = data[i].item;
//     }
//     dataObj.series = [{
//       name: seriesName,
//       data: finalData
//     }];
//   }
//   return dataObj;
// };

export default TasaGlobalDeParticipacion;
