import * as d3 from "d3";
import React, { useRef, useEffect } from "react";
import uuid from "react-uuid";
import {HiOutlineArrowSmUp} from "react-icons/hi";


let currentRoot;
let groupG;
let svg;

const Treemap = (props) => {

  const data = useRef(props.data);

  const ref = useRef();
  const backButtonRef = useRef();
  const container = useRef();
  
  const width = props.width ? props.width : 300;
  const height = props.height ? props.height : 200;

  const x = d3.scaleLinear().rangeRound([0, width]);
  const y = d3.scaleLinear().rangeRound([0, height]);

  const chartColors = props.chartColors ? props.chartColors : ["#27388D"];


  useEffect(() => {
    if(data.current && typeof data.current === "object" && data.current.children && data.current.children.length > 0){
      draw();
    }
    else{
      data.current = [];
      svg = d3.select(ref.current);
      svg.selectAll("*").remove();
    }
  }, [props.width,props.height]);


  useEffect(() => {
    data.current = props.data;
    if(data.current && typeof data.current === "object" && data.current.children && data.current.children.length > 0){
      draw();
    }
    else{
      data.current = [];
      svg = d3.select(ref.current);
      svg.selectAll("*").remove();
    }
  }, [props.data]);

  useEffect(() => {
    if(data.current && typeof data.current === "object" && data.current.children && data.current.children.length > 0){
      draw();
    }
    else{
      data.current = [];
      svg = d3.select(ref.current);
      svg.selectAll("*").remove();
    }
    return () => {
      currentRoot = undefined;
      groupG = undefined;
      svg = undefined;
    };
  }, []);


  function position(group, root) {
    group.selectAll("g")
      .attr("transform", d => d === root ? "translate(0,-30)" : `translate(${x(d.x0)},${y(d.y0)})`)
      .select("rect")
      .attr("width", d => d === root ? width : x(d.x1) - x(d.x0))
      .attr("height", d => d === root ? 30 : y(d.y1) - y(d.y0));
  }

  function getColor(d,chartColors) {
    //d === root ? "#fff" : d.children ? "#27388D" : "#27388D";
    let color = chartColors[0];
    let cellIndex = d.parent.children.indexOf(d);
    if(cellIndex > 0 && d.depth <= 1){
      color = chartColors[ cellIndex % chartColors.length ];
    } else if(d.depth > 1){
      cellIndex = d.parent.parent.children.indexOf(d.parent);
      if(cellIndex > 0)
        color = chartColors[ cellIndex % chartColors.length ];
    }
    return color;
  }

  const render = (group, root) => {
    const node = group
      .selectAll("g")
      .data(root.children)
      .join("g");
    
    
    node.filter(d => d === root ? d.parent : d.children)
      .attr("cursor", "pointer")
      .on("click", (event, d) => d === root ? zoomout(root) : zoomin(d));

    const toolTip = d3.select(container.current)
      .append("div")
      .style("visibility","hidden")
      .style("position","absolute")
      .style("background-color","white")
      .style("display","flex")
      .style("flex-direction","column")
      .style("width","250px");

    node.append("rect")
      .attr("id", d => (d.leafUid = uuid()))
      .attr("fill", d =>{
        return getColor(d,chartColors);
      })
      .attr("stroke", "#fff")
      .style("overflow","hidden")
      .on("mouseover",function(e,d){
        d3.select(this).style("filter", "opacity(90%)");
        toolTip.html(null);
        let tempNode = d;
        let rootName = "";
        while(tempNode != null){
          tempNode = tempNode.parent;
          if(tempNode){
            rootName = tempNode.data.name;
          }
        }
        toolTip.style("visibility","visible")
          .append("text")
          .text(`${d.data.name}`)
          .style("padding","5px")
          .style("font-size","0.8rem")
          .style("background-color","#E9E9E9")
          .style("font-weight","bold");
        toolTip.append("text")
          .text(`${rootName}:  ${d.value ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(d.value) : d.value}`)
          .style("padding","5px")
          .style("font-size","0.8rem")
          .style("font-weight","semi-bold");
        if(d.data.toolTip){
          for(let k=0;k<d.data.toolTip.length;k++){
            toolTip.append("text")
              .text(`${d.data.toolTip[k].label}:  ${d.data.toolTip[k].value ? Intl.NumberFormat("es-CO", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(d.data.toolTip[k].value) : d.data.toolTip[k].value}`)
              .style("padding","5px")
              .style("font-size","0.8rem")
              .style("font-weight","semi-bold");
          }
        }
      })
      .on("mousemove",(e)=>{
        toolTip.style("top",(e.layerY+10)+"px")
          .style("left",(e.layerX+10)+"px");
      })
      .on("mouseout",function(){
        d3.select(this).style("filter", "opacity(100%)");
        toolTip.style("visibility","hidden");
      });

    node.append("clipPath")
      .attr("id", d => (d.clipUid = uuid()))
      .append("use")
      .attr("xlink:href", d => d.leafUid.href);

    node.append("text")
      .attr("clip-path", d => d.clipUid)
      .attr("text-anchor", "middle")
      .attr("word-break", "break-all")
      .attr("class", "label")
      .style("font-size", d => {
        if (d === root) return "1em";
        const width = x(d.x1) - x(d.x0), height = y(d.y1) - y(d.y0);
        let size =  Math.max(Math.min(width/5, height/2, Math.sqrt((width*width + height*height))/20), 9);
        if(size > 17)
          size=17;
        return size;
      })
      .attr("pointer-events", "none")
      .style("overflow","hidden")
      .attr("transform", d=> d === root ? null : 
        `translate(${(x(d.x1) - x(d.x0))/2}, ${(y(d.y1) - y(d.y0))/2})`)
      .selectAll("tspan")
      .data(d => {
        //return d.data.name.split(/(?=[A-Z][^A-Z])/g);
        return d.data.name.split(" ");
      })
      .join("tspan")
      .attr("x", 3)
      .attr(
        "y",
        (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + (i - nodes.length/2) * 0.9}em`
      )
      .attr("fill", "#fff")
      .text((d) => d);

    const allLabels = d3.selectAll(".label").nodes();

    d3.selectAll(".label").style("display", (d, idx) => {
      const { width: widthLocal, height: heightLocal } = allLabels[idx].getBoundingClientRect();
      const parentWidth = (d.x1 - d.x0) * width / (d.parent.x1 - d.parent.x0);
      const parentHeight = (d.y1 -d.y0) * height / (d.parent.y1 - d.parent.y0) ;
  
      if (widthLocal > parentWidth || heightLocal > parentHeight) return "none";
      return "";
    });

    group.call(position, root);
  };

    
  // When zooming in, draw the new nodes on top, and fade them in.
  const zoomin = (d) => {
    x.domain([d.x0, d.x1]);
    y.domain([d.y0, d.y1]);

    const group0 = groupG.attr("pointer-events", "none");
    const group1 = groupG = svg.append("g").call(render, d);  

    svg.transition()
      .duration(750)
      .call(t => group0.transition(t).remove()
        .call(position, d.parent))
      .call(t => group1.transition(t)
        .attrTween("opacity", () => d3.interpolate(0, 1))
        .call(position, d));
    currentRoot = d;
    if(currentRoot && currentRoot.depth !=0){
      backButtonRef.current.style.display = "inherit";
    }
    else{
      backButtonRef.current.style.display = "none";
    }
  };
    
  // When zooming out, draw the old nodes on top, and fade them out.
  const zoomout = (d) => {
    x.domain([d.parent.x0, d.parent.x1]);
    y.domain([d.parent.y0, d.parent.y1]);

    const group0 = groupG.attr("pointer-events", "none");
    const group1 = groupG = svg.insert("g", "*").call(render, d.parent);

    svg.transition()
      .duration(750)
      .call(t => group0.transition(t).remove()
        .attrTween("opacity", () => d3.interpolate(1, 0))
        .call(position, d))
      .call(t => group1.transition(t)
        .call(position, d.parent));

    currentRoot = d.parent;
    if(currentRoot && currentRoot.depth !=0){
      backButtonRef.current.style.display = "inherit";
    }
    else{
      backButtonRef.current.style.display = "none";
    }
  };


    
  const draw = () => {
    backButtonRef.current.style.display = "none";
    svg = d3.select(ref.current);
    svg.selectAll("*").remove();

    const tile = (node, x0, y0, x1, y1) => {
      d3.treemapBinary(node, 0, 0, width, height);
      for (const child of node.children) {
        child.x0 = x0 + child.x0 / width * (x1 - x0);
        child.x1 = x0 + child.x1 / width * (x1 - x0);
        child.y0 = y0 + child.y0 / height * (y1 - y0);
        child.y1 = y0 + child.y1 / height * (y1 - y0);
      }
    };

    const treemap = data => d3.treemap()
      .tile(tile)(d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value));

    groupG = svg.append("g").call(render, treemap(data.current));

    svg.attr("width", width).attr("height", height);

  };

  const back = ()=>{
    if(currentRoot && currentRoot.depth !=0){
      zoomout(currentRoot);
    }
  };

  return (
    <div ref={container} className="chart relative" style={{ width: "100%" }}>
      <svg ref={ref}>
      </svg>
      <button ref={backButtonRef} onClick={()=> back()} className={"absolute top-5 right-5 bg-textColorSecondary rounded-full shadow"+(props.data && props.data.children && props.data.children.length > 0 ? " " : " hidden")}><HiOutlineArrowSmUp size={30} /></button>
      <div className={"absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"+(props.data && props.data.children && props.data.children.length > 0 ? " hidden" : "")}>
        Informacion no disponible
      </div> 
    </div>  
  );

};

export default Treemap;