import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { Oval } from "react-loader-spinner";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

const SIGNUP_URL = "/signup";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sector, setSector] = useState("");
  const [organization, setOrganization] = useState("");
  const [position, setPosition] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [sectorError, setSectorError] = useState();
  const [organizationError, setOrganizationError] = useState();
  const [positionError, setPositionError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  const [registerProgress, setRegisterProgress] = useState(false);

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || "/";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFirstNameError();
    setLastNameError();
    setEmailError();
    setPhoneError();
    setSectorError();
    setOrganizationError();
    setPositionError();
    setPasswordError();
    setConfirmPasswordError();
    setErrorMessage();
    let isError = false;
    if (!sector || sector == "") {
      setSectorError("Please select sector");
      isError = true;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Password and confirm password does not match");
      isError = true;
    }
    if (!phone || phone.trim().length == 0) {
      setPhoneError("Please enter valid phone number");
      isError = true;
    }
    if (!isValidPhoneNumber(phone)) {
      setPhoneError("Please enter valid phone number");
      isError = true;
    }
    if (isError) {
      return;
    }
    setRegisterProgress(true);
    try {
      const response = await axios.post(SIGNUP_URL, {
        firstName: firstName.trim(),
        ...(lastName && lastName.trim().length > 0
          ? { lastName: lastName.trim() }
          : {}),
        email: email.trim(),
        password: password,
        telephone: phone,
        sector: sector,
        ...(organization && organization.trim().length > 0
          ? { organization: organization.trim() }
          : {}),
        ...(position && position.trim().length > 0
          ? { position: position.trim() }
          : {}),
      });
      setAuth({ user: response.data.data });
      navigate(from, { replace: true });
      setRegisterProgress(false);
    } catch (err) {
      if (!err?.response) {
        setErrorMessage(
          "No server response, something went wrong. Please try again later."
        );
      } else if (err.response.data?.errors) {
        let isErrorSet = false;
        for (let i = 0; i < err.response.data.errors.length; i++) {
          let error = err.response.data.errors[i];
          if (error.property === "firstName") {
            setFirstNameError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "lastName") {
            setLastNameError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "email") {
            setEmailError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "password") {
            setPasswordError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "telephone") {
            setPhoneError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "sector") {
            setSectorError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "organization") {
            setOrganizationError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if (error.property === "position") {
            setPositionError(Object.values(error.constraints).join());
            isErrorSet = true;
          }
        }
        if (!isErrorSet && err.response.data?.message) {
          setErrorMessage(err.response.data.message);
        }
      } else if (err.response.data?.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage(
          "Signup failed, something went wrong. Please try again later."
        );
      }
      setRegisterProgress(false);
    }
  };

  return (
    <form
      className="flex flex-col w-full pt-6 text-tiny gap-6 pb-20"
      onSubmit={handleSubmit}
    >
      <div className="w-full flex flex-row gap-5">
        <div className="flex-1">
          <div className="border w-full rounded bg-lightGray border-gray">
            <input
              type="fname"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              placeholder="Nombre"
              className="w-full border rounded bg-lightGray border-gray p-4"
              required
            />
          </div>
          {firstNameError && firstNameError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {firstNameError}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1">
          <div className="border w-full rounded bg-lightGray border-gray">
            <input
              type="lname"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              placeholder="Apellido"
              className="w-full border rounded bg-lightGray border-gray p-4"
            />
          </div>
          {lastNameError && lastNameError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">{lastNameError}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full">
        <div className="w-full border rounded bg-lightGray border-gray">
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Correo electrónico"
            className="w-full border rounded bg-lightGray border-gray p-4"
            required
          />
        </div>
        {emailError && emailError.trim().length > 0 ? (
          <p className="text-sm text-red mt-1 font-medium">{emailError}</p>
        ) : (
          <></>
        )}
      </div>
      <div className="w-full flex flex-row gap-5">
        <div className="flex-1">
          <div className="border w-full rounded bg-lightGray border-gray">
            <PhoneInput
              defaultCountry="CO"
              placeholder="Teléfono"
              value={phone}
              onChange={setPhone}
              className="w-full border rounded bg-lightGray border-gray p-4 pl-2"
            />
          </div>

          {/* <div className="border w-full rounded bg-lightGray border-gray">
            <input type="tel" value={phone} onChange={(e)=>{setPhone(e.value);}} placeholder="Teléfono (+57xxxxxxxxxx)" className="w-full border rounded bg-lightGray border-gray p-4" required/>
          </div> */}
          {phoneError && phoneError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">{phoneError}</p>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1 relative">
          <select
            value={sector}
            onChange={(e) => {
              setSector(e.target.value);
            }}
            className="border rounded bg-lightGray border-gray text-sm w-full p-4 appearance-none text-darkGray cursor-pointer"
            required
          >
            <option>Sector</option>
            <option value="ong">ONG</option>
            <option value="academia">Academia</option>
            <option value="estudiantes">Estudiantes</option>
            <option value="inversionistas">Inversionistas</option>
            <option value="empresario">Empresario</option>
            <option value="medios de comunicación">
              Medios de Comunicación
            </option>
            <option value="miembro probarranquilla">
              Miembro ProBarranquilla
            </option>
            <option value="gremios/asociación">Gremios/Asociación</option>
            <option value="ente gubernamental">Ente Gubernamental</option>
            {/* <option value="otros">Otros</option> */}
          </select>
          <BiChevronDown
            size={18}
            className="absolute right-4 top-4 text-darkGray"
          />
          {sectorError && sectorError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">{sectorError}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex flex-row gap-5">
        <div className="flex-1">
          <div className="border w-full rounded bg-lightGray border-gray">
            <input
              type="text"
              value={organization}
              onChange={(e) => {
                setOrganization(e.target.value);
              }}
              placeholder="Organización"
              className="w-full border rounded bg-lightGray border-gray p-4"
            />
          </div>
          {organizationError && organizationError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">
              {organizationError}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1">
          <div className="border w-full rounded bg-lightGray border-gray">
            <input
              type="text"
              value={position}
              onChange={(e) => {
                setPosition(e.target.value);
              }}
              placeholder="Cargo"
              className="w-full border rounded bg-lightGray border-gray p-4"
            />
          </div>
          {positionError && positionError.trim().length > 0 ? (
            <p className="text-sm text-red mt-1 font-medium">{positionError}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full">
        <div className="w-full border rounded bg-lightGray border-gray relative">
          <input
            type={passwordVisible ? "text" : "password"}
            autoComplete="off"
            placeholder="Contraseña"
            className="w-full border rounded bg-lightGray border-gray p-4"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className="cursor-pointer absolute right-4 top-4 text-darkGray"
            onClick={() => setPasswordVisible(!passwordVisible)}
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
          >
            {passwordVisible ? "Ocultar" : "Ver"}
          </span>
        </div>
        {passwordError && passwordError.trim().length > 0 ? (
          <p className="text-sm text-red mt-1 font-medium">{passwordError}</p>
        ) : (
          <></>
        )}
      </div>
      <div className="w-full">
        <div className="w-full border rounded bg-lightGray border-gray relative">
          <input
            type={passwordVisible ? "text" : "password"}
            autoComplete="off"
            placeholder="Confirmar Contraseña"
            className="w-full border rounded bg-lightGray border-gray p-4"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span
            className="cursor-pointer absolute right-4 top-4 text-darkGray"
            onClick={() => setPasswordVisible(!passwordVisible)}
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
          >
            {passwordVisible ? "Ocultar" : "Ver"}
          </span>
        </div>
        {confirmPasswordError && confirmPasswordError.trim().length > 0 ? (
          <p className="text-sm text-red mt-1 font-medium">
            {confirmPasswordError}
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className="mt-3">
        <>
          {errorMessage && errorMessage.trim().length > 0 ? (
            <p className="text-tiny text-red font-medium">{errorMessage}</p>
          ) : (
            <></>
          )}
        </>
        {registerProgress ? (
          <Oval
            height={35}
            width={35}
            color="#005AA9"
            wrapperClass="w-fit ml-6"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#B3B9D5"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        ) : (
          <button className="bg-primary text-textColorSecondary w-fit mt-4 rounded pl-6 pr-6 pt-3 pb-3">
            Registrarse
          </button>
        )}
      </div>
      <p className="text-xs">
        Al continuar, aceptas las{" "}
        <a
          href="https://probarranquilla.org/politicas-tratamiento"
          className="text-primary"
        >
          Condiciones de uso y el Aviso de privacidad
        </a>{" "}
        de ProBarranquilla.
      </p>
    </form>
  );
};

export default Register;
