import React, { useState } from "react";
import { Oval } from  "react-loader-spinner";
import axios from "../../../api/axiosAuth";
import {  toast } from "react-toastify";

const AuthorCreateModal = (props) => {

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");

  const [nameError, setNameError] = useState();

  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async () => {
    setNameError();
    let isError = false;
    if(!name || name.trim() == ""){
      setNameError("Please enter name");
      isError = true;
    }
    if(isError){
      return;
    }
    setLoading(true);
    try{
      const response = await axios.post("/repository/author",{
        name: name.trim(),
      });
      toast.success(response.data.message);
      props.modalClose && props.modalClose(true);
    }
    catch(err) {
      if(!err?.response){
        setErrorMessage("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.errors) {
        let isErrorSet = false;
        for(let i = 0;i<err.response.data.errors.length;i++){
          let error = err.response.data.errors[i];
          if(error.property === "name"){
            setNameError(Object.values(error.constraints).join());
            isErrorSet = true;
          }
        }
        if(!isErrorSet && err.response.data?.message){
          setErrorMessage(err.response.data.message);
        }
      } else if(err.response.data?.message){
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage("Author create failed, something went wrong. Please try again later.");
      }
      setLoading(false);
    }
  };
  
  return (
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Create Autor</h3>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input value={name} onChange={(e)=>{setName(e.target.value);}} type="text" className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="Nombre" />
          {
            nameError && nameError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{nameError}</p>
              :
              <></>
          }
        </div>
      </div>
      {
        errorMessage && errorMessage.trim().length > 0 ? 
          <p className="text-tiny text-red font-medium mt-3">{errorMessage}</p>
          :
          <></>
      }
      <div className="border-gray pt-2 flex justify-end mt-5">
        {
          loading ? 
            <Oval
              height={35}
              width={35}
              color="#005AA9"
              wrapperClass="w-fit mr-6"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#B3B9D5"
              strokeWidth={4}
              strokeWidthSecondary={4} />
            :
            <div className="flex gap-3">
              <button className="text-tiny bg-gray px-3 py-1 rounded shadow" onClick={()=>{props.modalClose && props.modalClose();}}>Close</button>
              <button className="text-tiny bg-primary text-textColorSecondary px-3 py-1 rounded shadow" onClick={()=>{handleSubmit();}}>Create</button>
            </div>
        }
        
      </div>
    </div>
  );
};
export default AuthorCreateModal;