import React from "react";
import { NavLink } from "react-router-dom";
import { useMenuContext } from "../context/MenuProvider";

const MENU = [
  {
    keyName: "desempeño_economico",
    label: "Desempeño económico",
    to: "/charts/desempeno_economico",
    icon: "/images/svg/desempeno_economico.svg",
  },
  {
    to: "/charts/costos_de_instalacion_y_operacion",
    icon: "/images/svg/costos_de_instalacion_y_operacion.svg",
    label: "Costos de instalación y operación",
    keyName: "costos_de_instalacion_y_operacion",
  },
  {
    to: "/charts/conectividad_y_acceso_a_mercados",
    icon: "/images/svg/conectividad_y_acceso_a_mercados.svg",
    label: "Conectividad y acceso a mercados",
    keyName: "conectividad_y_acceso_a_mercados",
  },
  {
    to: "/charts/tejido_empresarial_y_desempeno_sectorial",
    icon: "/images/svg/tejido_empresarial_y_desempeno_sectorial.svg",
    label: "Tejido empresarial",
    keyName: "tejido_empresarial_y_desempeno_sectorial",
  },
  {
    to: "/charts/entorno_de_negocios_y_competitividad",
    icon: "/images/svg/entorno_de_negocios_y_competitividad.svg",
    label: "Turismo",
    keyName: "entorno_de_negocios_y_competitividad",
  },
  {
    to: "/charts/gestion_de_atraccion_y_retencion_de_inversiones",
    icon: "/images/svg/gestion_de_atraccion_y_retencion_de_inversiones.svg",
    label: "Gestión de atracción y retención de inversiones",
    keyName: "gestion_de_atraccion_y_retencion_de_inversiones",
  },
  {
    to: "/charts/talento_humano",
    icon: "/images/svg/talento_humano.svg",
    label: "Talento humano",
    keyName: "talento_humano",
  },
  {
    to: "/charts/indicadores_sociales",
    icon: "/images/svg/indicador_social.svg",
    label: "Indicadores sociales",
    keyName: "indicadores_sociales",
  },
];

const Menu = () => {
  const { menus } = useMenuContext();

  return (
    <div className="w-fit shrink-0 bg-lightGray h-full pt-4 pl-1 pr-1 md:pt-10 md:pl-3 md:pr-3 md:w-64 overflow-y-scroll no_scroll">
      <ul className="flex flex-col gap-1 text-tiny text-primary font-medium pb-5">
        {MENU.filter(
          (menu) =>
            menus?.findIndex(
              (cat) => cat.keyName === menu.keyName && cat.status === "active"
            ) > -1
        ).map((menu) => (
          <li key={`menu-${menu.keyName}`}>
            <NavLink
              to={menu.to}
              className={({ isActive }) => [
                "flex flex-row gap-1 p-2 md:p-4 items-center rounded-xl hover:bg-primary hover:bg-opacity-5 ",
                isActive
                  ? " bg-primary text-textColorSecondary hover:bg-opacity-100"
                  : "",
              ]}
            >
              {({ isActive }) => (
                <>
                  <img
                    alt="icon"
                    className="h-7"
                    src={menu.icon}
                    style={{
                      filter: isActive ? "brightness(0) invert(1)" : "",
                    }}
                  />
                  <span className="hidden md:block cursor-pointer">
                    {menu.label}
                  </span>
                </>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
