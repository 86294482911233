import React from 'react'

const LocationContext = React.createContext(null)

export const useLocationContext = () => React.useContext(LocationContext)

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = React.useState({})

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation({
          latitude,
          longitude,
        });
      }, (error) => {
        console.error(error);
      });
    } else {
      console.error('La geolocalización no es compatible con este navegador.');
    }
  };

  React.useEffect(() => {
    getLocation()
  }, [])

  return <LocationContext.Provider value={{ location }}>{children}</LocationContext.Provider>
}