
import React from "react";
import { NavLink, Outlet, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Auth = () => {

  const { auth } = useAuth();
  return (
    <>
      {
        auth?.user ?
          <>
            {
              auth.user.emailVerified ? 
                <Navigate to="/maps" replace/>
                :
                <Navigate to="/auth/email_verify" replace/>
            }
          </> 
          :
          <div className="flex flex-1 relative">
            <img alt="" src="/images/Log in.jpg" className="w-[53.2%] object-cover hidden lg:block fixed" style={{ height: "calc(100vh - 4.1rem)"  }}/>
            <div className="w-[53.2%] hidden lg:block"></div>
            <div className="flex flex-1 items-center pt-20 flex-col h-full">
              <div className="w-full h-fit max-w-md pl-5 pr-5 md:pl-0 md:pr-0">
                <div className="w-fit border-b border-gray text-lightPrimary h-fit flex gap-5 text-tiny relative">
                  <NavLink to="login" className={({isActive}) => "cursor-pointer pt-3 pb-3 hover:bg-lightGray pl-1 pr-1 " + ( isActive ? "border-b-2 border-primary text-primary" : "" )}>Iniciar sesión</NavLink>
                  <NavLink to="register" className={({isActive}) => "cursor-pointer pt-3 pb-3 hover:bg-lightGray pl-1 pr-1 " + ( isActive ? "border-b-2 border-primary text-primary" : "" )}>Crear Cuenta</NavLink>
                </div>
                <Outlet />
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default Auth;