
import React,{ useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Oval } from "react-loader-spinner";
import axios from "../../../api/axiosAuth";
import CategoryCreateModal from "./CategoryCreateModal";
import CategoryUpdateModal from "./CategoryUpdateModal";
import useModal from "../../../hooks/useModal";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";

const Categories = () => {

  const columns = [
    {
      name: "Nombre",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Acción",
      cell:(row)=>(<div className="flex gap-2">
        <button className="bg-primary p-2 rounded" onClick={()=>{
          setModalView(<CategoryUpdateModal data={row} modalClose={(refresh)=>{
            setModalVisibility(false);
            setModalCancelable(true);
            if(refresh)
              fetchData(1, perPage,sortBy,sortDirection);
          }} />);
          setModalVisibility(true);
          setModalCancelable(false);
        }}><BsPencil size={17} color={"#ffffff"}/></button>
        <button className="bg-secondary p-2 rounded" onClick={()=>{
          setModalView(<DeleteConfirmationModal deleteUrl={"repository/category/"+row.id} modalClose={(refresh)=>{
            setModalVisibility(false);
            setModalCancelable(true);
            if(refresh)
              fetchData(1, perPage,sortBy,sortDirection);
          }}
          />);
          setModalVisibility(true);
          setModalCancelable(false);
        }}><RiDeleteBin6Line size={17} color={"#ffffff"}/></button>
      </div>),
      sortable: false,
    },
  ];

  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [sortBy, setSortBy] = useState();
  const [sortDirection, setSortDirection] = useState();

  const { setModalView ,setModalVisibility, setModalCancelable } = useModal();

  const fetchData = async (page, per_page, sort_by, sort_direction) => {
    setTableLoading(true);
    axios.get("/repository/category",{
      params: {
        pagination: true,
        page: page,
        limit: per_page,
        ...(sort_by && { sortBy: sort_by }),
        ...(sort_direction && { sortOrder: sort_direction }),
      }
    }).then((data)=>{
      data = data?.data;
      setTableLoading(false);
      setTableData(data.data);
      setTotalRows(data.paginationDetails.totalDocs);
      //setTotalRows(result.total);
    }).catch(()=>{
      setTableLoading(false);
      //setError(err);
    });
  };

  useEffect(() => {
    fetchData(1,perPage,sortBy,sortDirection);
    return () => {};
  }, []);

  const handleSort = async (column, sort_direction) => {
    /// reach out to some API and get new data using or sortField and sortDirection
    // e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}
    setSortBy(column);
    setSortDirection(sort_direction);
    fetchData(1,perPage,column,sort_direction);
  };

  const handlePageChange = page => {
    fetchData(page, perPage,sortBy,sortDirection);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPerPage(newPerPage);
    fetchData(1, newPerPage,sortBy,sortDirection);
  };

  return (
    <div className="flex flex-1 relative pt-3 border-collapse">
      <div className="border border-gray w-full rounded">
        <div className="w-full border-b border-gray p-5 flex justify-between flex-wrap">
          <h3 className="text-tiny font-semibold text-black">Categoria tematica</h3>
          <div className="self-end flex gap-3">
            <button onClick={()=>{
              setModalView(<CategoryCreateModal modalClose={(refresh)=>{
                setModalVisibility(false);
                setModalCancelable(true);
                if(refresh)
                  fetchData(1, perPage,sortBy,sortDirection);
              }} />);
              setModalVisibility(true);
              setModalCancelable(false);
            }} className="text-sm bg-primary text-textColorSecondary px-3 py-2 rounded">
              Crear Categoria
            </button>
          </div>
          
        </div>
        <DataTable
          className=""
          columns={columns}
          data={tableData}
          striped
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          persistTableHead={true}
          paginationRowsPerPageOptions={[10,25,50,75,100]}
          responsive
          progressComponent={<div className="h-36 flex justify-center items-center"><Oval height={40} color="#005AA9" secondaryColor="#B3B9D5"/></div>}
          progressPending={tableLoading}
          onSort={handleSort}
          customStyles={{ rows: { style:{ backgroundColor: "#FFFFFF" }, stripedStyle: { ":nth-child(odd)": { backgroundColor: "#F7F7F7" }} } }}
        />
      </div>
      
    </div>
  );
};

export default Categories;