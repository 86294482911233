import React, { useEffect, useRef } from "react";

// import StyledChart from "../../components/StyledChart";
// import LogoList from "../../components/LogoList";

let viz;

const InstitutoDeIdiomas = () => {
  const exportaRef = useRef();

  useEffect(() => {
    if (viz) {
      viz.dispose();
    }
    const vizUrl =
      "https://public.tableau.com/views/OfertadeIdiomas/Ofertadeidiomas?:language=es-ES&amp;:display_count=n&amp;:origin=viz_share_link";
    viz = new window.tableau.Viz(exportaRef.current, vizUrl);
  }, []);

  return (
    <div className="w-full pt-5 flex flex-col">
      <div className="w-full h-fit">
        <div ref={exportaRef} style={{ width: "100%", margin: "auto" }}></div>
      </div>
    </div>
    // <div className="w-full pt-5 flex flex-col gap-5">
    //   <StyledChart
    //     serverId="institutos-de-idiomas"
    //     chartType="bar"
    //     title="Directorio de Institutos de ldiomas"
    //     hintTitle="Directorio de Institutos de ldiomas"
    //     hintDescription="Listado de institutos de idiomas en Barranquilla y el departamento del Atlántico por el idioma ofertado."
    //     footerText=""
    //     yearEnabled={false}
    //     dropdown1Key="modalidad"
    //     dropdown1Items={[
    //       {value: "Virtual", label: "Virtual"},
    //       {value: "Remota / Presencial", label: "Remota / Presencial"}
    //     ]}
    //     dropdown2Key="idioma"
    //     dropdown2Items={[
    //       {value: "Inglés", label: "Inglés"}
    //     ]}
    //     transformData={transformData}
    //     legendPosition="bottom"
    //     legendHorizontalAlign="center"
    //     dataLabelsEnabled={false}
    //     strokeWidth={0}
    //     xAxisLabelVisible={true}
    //     yAxisLabelVisible={true}
    //     YAxisBorderShow={true}
    //     CustomChart={(props)=>(
    //       <LogoList tooltipNameKey="institutoIdiomas" data={props.data} />
    //     )}
    //   />
    // </div>
  );
};

const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: [],
  };
  data = data?.data?.data;
  if (data && data.length > 0) {
    dataObj.series = data;
  }
  return dataObj;
};

export default InstitutoDeIdiomas;
