import React,{ useRef } from "react";

import StyledChart from "../../components/StyledChart";

const CrecimientoDelPb = () => {

  const chartRef1 = useRef();
  
  return (
    <div className="w-full pt-5 flex flex-col gap-5">
      <StyledChart
        chartRef={chartRef1}
        serverId="crecimiento-economico-sectorial-departamentos-2012-2020"
        dropdown1Key="grupo"
        dropdown1Items={[
          {value: "Principales economías", label: "Principales economías"},
          {value: "Región Caribe", label: "Región Caribe"}
        ]}
        dropdown2Key="sector"
        dropdown2Items={[
          {value: "Producto Interno Bruto", label: "Producto Interno Bruto"},
          {value: "Agricultura, ganadería, caza, silvicultura y pesca", label: "Agricultura, ganadería, caza, silvicultura y pesca"},
          {value: "Explotación de minas y canteras", label: "Explotación de minas y canteras"},
          {value: "Industrias manufactureras", label: "Industrias manufactureras"},
          {value: "Suministro de electricidad, gas, vapor y aire acondicionado; distribución de agua; evacuación y tratamiento de aguas residuales, gestión de desechos y actividades de saneamiento ambiental", label: "Suministro de electricidad, gas, vapor y aire acondicionado"},
          {value: "Construcción", label: "Construcción"},
          {value: "Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas; transporte y almacenamiento; alojamiento y servicios de comida", label: "Comercio al por mayor y al por menor; reparación de vehículos automotores"},
          {value: "Información y comunicaciones", label: "Información y comunicaciones"},
          {value: "Actividades financieras y de seguros", label: "Actividades financieras y de seguros"},
          {value: "Actividades inmobiliarias", label: "Actividades inmobiliarias"},
          {value: "Actividades profesionales, científicas y técnicas; actividades de servicios administrativos y de apoyo", label: "Actividades profesionales, científicas y técnicas; actividades de servicios administrativos y de apoyo"},
          {value: "Administración pública y defensa; planes de seguridad social de afiliación obligatoria; educación; actividades de atención de la salud humana y de servicios sociales", label: "Administración pública y defensa; planes de seguridad social de afiliación obligatoria"},
          {value: "Actividades artísticas, de entretenimiento y recreación y otras actividades de servicios; actividades de los hogares individuales en calidad de empleadores; actividades no diferenciadas de los hogares individuales como productores de bienes y servicios para uso propio", label: "Actividades artísticas, de entretenimiento y recreación"}
        ]}
        chartType="line"
        title="Variación del PIB por sectores: Departamentos"
        hintTitle="Variación del PIB por sectores: Departamentos"
        hintDescription="Análisis de la variación del PIB real en los principales departamentos de Colombia, desglosado por sectores económicos para identificar las tendencias y cambios en cada sector."
        footerText="Fuente: DANE - GEIH"
        yearEnabled={false}
        transformData={transformData}
        legendPosition="right"
        legendHorizontalAlign="center"
        dataLabelsEnabled={false}
        chartClassName="max-w-6xl px-0 md:px-3 pt-3"
        height={450}
        sortBy="departamento"
        sortOrder="asc"
        strokeWidth={3}
        xAxisLabelVisible={true}
        yAxisLabelVisible={true}
        yAxisLabelFormatter={(val)=>{ return ( val && typeof val !== "string" && !isNaN(val) ? Intl.NumberFormat("es-CO", { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(val/100) : val); }}
        YAxisBorderShow={true}
        legendWidth={180}
        chartColors={["#27388D","#F5DFA1","#F5B6A0", "#97B3E5","#ACC7F6","#ADCDDE","#758DFF","#FACAAA","#8094F5", "#B8CAE9","#8AABE3"]}
        legendEnable={true}/>
    </div>
  );
};


const transformData = (data) => {
  const dataObj = {
    series: [],
    categories: []
  };
  data = data?.data?.data;
  if(data && data.length > 0) {
    let categories = [];
    let series = {};
    for(let i=0;i<data.length;i++){
      if(!categories.includes(data[i].ano)){
        categories.push(data[i].ano);
      }
      if(series[data[i].departamento]){
        series[data[i].departamento].push(data[i].varReal);
      }
      else{
        series[data[i].departamento] = [ data[i].varReal ];
      }
    }
    let seriesData = [];
    let seriesKeys = Object.keys(series);
    for(let i=0;i<seriesKeys.length;i++){
      seriesData[i] = {
        name: seriesKeys[i],
        data: series[seriesKeys[i]]
      };
    }
    if(categories.length > 0){
      dataObj.categories = categories;
    }
    dataObj.series = seriesData;
  }
  return dataObj;
};

export default CrecimientoDelPb;